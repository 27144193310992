<template>
<div class="card flex flex-wrap p-fluid">
  <div class="formgrid grid">
    <div class="field col">
        <label > Item: </label>
        <InputText v-model="itemName" class="w-full" @change="bindData"/>
    </div>
    <div class="field col">
        <label >Arrd Qty: </label>
        <InputNumber v-model="availQty" class="w-full" readonly/>
    </div>
    <div class="field col">
        <label >HSN: </label>
        <!-- <InputNumber v-model="itemHsn" class="w-full" :useGrouping="false" @input="bindData" /> -->
        <Dropdown :options="hsnArr" filter editable optionValue="hsn" optionLabel="hsn" v-model="itemHsn" class="surface-overlay w-full "/>
    </div>
    <div class="field col" v-if="partNoStatus">
        <label >Pt/Sr No.: </label>
        <InputText v-model="partNo" class="w-full" @change="bindData"/>
    </div>
    <div class="field col" v-if="partNoStatus">
        <label >Mdl No.: </label>
        <InputText v-model="modelNo" class="w-full" @change="bindData"/>
    </div>
    <div class="field col" v-if="!partNoStatus">
        <label >Batch: </label>
        <InputText v-model="batchNo" class="w-full" @change="bindData"/>
    </div>
    <div class="field col" v-if="!partNoStatus">
        <label >Mfg: </label>
        <Calendar v-model="itemMfg" class="w-full" @change="bindData"/>
    </div>
    <div class="field col" v-if="!partNoStatus">
        <label >Exp: </label>
        <Calendar v-model="itemExp" class="w-full" @change="bindData"/>
    </div>
    <div class="field col">
        <label >Packing: </label>
        <InputText v-model="itemPacking" class="w-full" @change="bindData"/>
    </div>
    <!-- <div class="field col">
        <label >Packages: </label>
        <InputNumber v-model="itemNop" class="w-full" readonly/>
    </div> -->
    <div class="field col">
        <label >Recd Qty: </label>
        <InputNumber v-model="itemPackQty" class="w-full" @input="updateQty($event), bindData()"/>
    </div>
    <!-- <div class="field col">
        <label >Total Qty: </label>
        <InputNumber v-model="itemTotalQty" class="w-full" readonly/>
    </div> -->
  
    <div class="field col">
        <label >Rate: </label>
        <InputNumber v-model="itemRate" class="w-full" :minFractionDigits="2" :maxFractionDigits="4" @input="updateRate($event), bindData()"/>
    </div>
    <div class="field col">
        <label >Amount: </label>
        <InputNumber v-model="itemAmt" class="w-full" :minFractionDigits="2" :maxFractionDigits="4" readonly/>
    </div>
    <div class="field col">
        <label >Disc(%): </label>
        <InputNumber v-model="itemDiscPerc" class="w-full" :minFractionDigits="2" :maxFractionDigits="4" @input="updateDisc($event), bindData()"/>
    </div>
    <div class="field col">
        <label >Disc: </label>
        <InputNumber v-model="itemDisc" class="w-full" :minFractionDigits="2" :maxFractionDigits="4" readonly/>
    </div>
    <div class="field col">
        <label >Gst(%): </label>
        <InputNumber v-model="itemGstPerc" class="w-full" :minFractionDigits="2" :maxFractionDigits="4" @input="updateGst($event), bindData()" />
    </div>
    <div class="field col">
        <label >Gst: </label>
        <InputNumber v-model="itemGst" class="w-full" :minFractionDigits="2" :maxFractionDigits="4" readonly/>
    </div>
    <div class="field col">
        <label >Total: </label>
        <InputNumber v-model="subTotal" class="w-full" :minFractionDigits="2" :maxFractionDigits="4" readonly/>
    </div>
    <!-- <div class="field col">
        <label >Test Report: </label>
        <Checkbox v-model="testReportStatus" :binary="true" name="testReport" value="Yes" />
    </div> -->
    <!-- <div class="field col">
        <label >Action</label>
        <div>
        <Button icon="pi pi-times" size="small" severity="danger" text raised @click="$emit('delete')" v-tooltip.top="'Delete item'"/>
        </div>
    </div> -->

  </div>
 
</div>
</template>

<script>
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
// import Button from 'primevue/button'
import Calendar from 'primevue/calendar'
import { fetchHsnList } from '@/services/apiService'
import Dropdown from 'primevue/dropdown'
// import Checkbox from 'primevue/checkbox'

export default {
    
    components:{
        InputText,
        InputNumber,
        // Button,
        Calendar,
        Dropdown
        // Checkbox,
    },

    props:{
        item:Array,
        index:Number,
    },

    emits: ['delete','bind-item'],

    data(){
        return{
            
            // slNo:this.index+1,
            itemName:this.item.name,
            itemHsn:null,
            itemPacking:this.item.packing,
            // itemNop:this.item.nop,
            itemPackQty:0,
            // itemTotalQty:this.item.totalQty,
            itemGstPerc:this.item.gstPerc,
            itemGst:0,
            itemDiscPerc:0,
            itemDisc:0,
            partNoStatus: this.item.category=='Equipment' || this.item.category=='EQUIPMENT' ? true : false,
            partNo:null,
            modelNo:null,
            batchNo:null,
            itemRate:this.item.rate,
            itemAmt:0,
            availQty:this.item.arrivedQty,
            itemMfg:null,
            itemExp:null,              
            subTotal:0,  
            testReportStatus:false,   
            mfg:null,
            exp:null,  
            hsnArr:[], 
        }
    },
    methods:{
        bindData(){
            
            if(this.itemMfg){
                this.mfg = this.itemMfg.getFullYear()+'-'+(this.itemMfg.getMonth()+1)+'-'+this.itemMfg.getDate();
            }
            if(this.itemExp){
                this.exp = this.itemExp.getFullYear()+'-'+(this.itemExp.getMonth()+1)+'-'+this.itemExp.getDate();
                // exp = this.itemExp.getDate()+'-'+(this.itemExp.getMonth()+1)+'-'+this.itemExp.getFullYear();
            }
       const itemData = {
            id:this.item.id,
            itemId:this.item.itemId,
            invItemName:this.itemName,
            invItemAvailQty:this.availQty,
            // invItemQty:this.item.quantity,
            invItemHsn:this.itemHsn,
            invItemPrtNo:this.partNo,
            invItemModelNo:this.modelNo,
            invItemBatchNo:this.batchNo,
            invItemMfg:this.mfg,
            invItemExp:this.exp,  
            invItemPckg:this.itemPacking,
            // invItemNop:this.itemNop,
            invItemPckQty:this.itemPackQty,
            // invItemTotalQty:this.itemTotalQty,
            invItemRate:this.itemRate,
            invItemAmt:this.itemAmt,
            invItemGstPerc:this.itemGstPerc,
            invItemGst:Math.round(this.itemGst * 10000)/10000,
            invItemDiscPerc:this.itemDiscPerc,
            invItemDisc:this.itemDisc,
            itemSubTotal:Math.round(this.subTotal * 10000)/10000,
        }
        this.$emit('bind-item', itemData, this.index);
      },
      updateQty(event){
        // this.itemAmt = this.itemRate * event.value;
        this.itemPackQty = event.value;
        this.updateValue();
      },
      updateRate(event){
        this.itemRate = event.value;
        this.updateValue();
      },
      updateGst(event){
        this.itemGstPerc = event.value;
        this.updateValue();
      },
      updateDisc(event){
        this.itemDiscPerc = event.value;
        this.updateValue();
      },
      updateValue(){

        this.itemAmt = this.itemRate * this.itemPackQty;

        this.itemDisc = this.itemDiscPerc * (this.itemAmt/100);

        this.itemAmt -= this.itemDisc;

        this.itemGst = this.itemGstPerc * (this.itemAmt/100);
        
        this.subTotal = this.itemAmt + this.itemGst
      },
      getHsnList(){
        fetchHsnList().then((res)=>{
            this.hsnArr = res.data;
        })
    },
    },
    mounted(){
        this.getHsnList();
    }
    
}
</script>

<style>

</style>