<template>
  
    <div class="sm:col-12 md:col-12 lg:col-12">
        <div class="surface-card shadow-2 p-3 border-round-lg mt-2 ml-2">
            <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl ml-2">
                    Filter Options
                </div>
            </div>
            <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl w-full">
                  <div class="col">
                    <InputText class="w-full" v-model="filterAttrib1"/>
                  </div>
                  <div class="col">
                    <InputText class="w-full" v-model="filterAttrib1"/>
                  </div>
                  <div class="col">
                    <InputText class="w-full" v-model="filterAttrib1"/>
                  </div>
                  <div class="col">
                    <Button label="Apply Filter" raised text severity="success" class="w-full" size="small" v-model="filterAttrib1"/>
                  </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sm:col-12 md:col-12 lg:col-12">
        <div class="surface-card shadow-2 p-3 border-round-lg mt-2 ml-2">
            <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl ml-2">
                    Bills Report
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'

export default {
    components:{
        InputText,
        Button,
    }
}
</script>

<style>

</style>