<template>
<!-- style="margin-top:10%; margin-bottom:10%;" -->
  <div class="surface-ground flex align-content-center justify-content-center overflow-hidden h-screen" >
          <div class="flex align-items-center justify-content-center" >
          
            <div class="w-full" style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, var(--primary-color) 10%, rgba(33, 150, 243, 0) 100%)">
                <div class="w-full surface-card py-8 px-5 sm:px-8" style="border-radius: 53px">
                    <div class="text-center mb-5">
                        <img :src="logoUrl" alt="AMS logo" height="70" class="mb-3" />
                        <div class="text-900 text-3xl font-medium mb-3">Arengh Medical Supplier</div>
                        <span class="text-600 font-medium"> <h3>You have logged out. <br> Click <a href="/">here</a> to login again. </h3></span>
                    </div>

                </div>
            </div>
        </div>
   </div>
</template>

<script>

export default {
    data(){
      return{
        logoUrl : '/assets/images/logo.png',
      }
    }
}
</script>

<style>

</style>