<template>
  <Navbar/>
  <SideBar/>
<div class="card main-content">
        <div class="progress-spinner" v-if="spinnerLoadingStatus">
            <ProgressSpinner></ProgressSpinner>
        </div>
        <div class="surface-ground">
            <Card>
                
                <template #content>

                    <DataTable :value="filteredCancelDcs" sortMode="multiple" :size="small" :loading="tableLoading" v-model:filters="filters" tableStyle="min-width: 50rem" v-model:expandedRows="expandedRows" dataKey="id" @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}">

                     <template #header >
                      <div class="flex justify-content-between flex-wrap">
                          <div class="flex align-items-center justify-content-center text-xl">
                              Cancelled Delivery Challans
                          </div>
                          <div class="flex align-items-center justify-content-center">
                              <!-- <InputText v-model="filters['global'].value" placeholder="Keyword Search" /> -->
                              <InputText v-model="filterQuery" placeholder="Keyword Search" size="small"/>
                              <Button class="ml-2" icon="pi pi-filter" severity="success" size="small" @click="globalFilter" v-tooltip.top="'Filter'"/>
                              <Button class="ml-2" icon="pi pi-filter-slash" size="small" outlined severity="success" @click="clearFilter" v-tooltip.top="'Clear Filter'"/>
                          </div>
                      </div>
                     </template>

                        <Column expander style="width: 5rem"/>                        
                        <Column field="dcNo" header="DC No." />
                        <Column field="dcDate" header="DC Date" />
                        <Column field="dcDate" header="DC Date" />
                        <Column field="dcDate" header="DC Date" />
                        <Column field="dept.name" header="Dept"/>
                        <Column field="deptPo" header="Dept PO"/>                    
                        <Column field="location.name" header="Location"/>
                        <Column header="Status">
                        <template #body="">
                            <Tag value="Cancelled" severity="danger" />
                        </template>
                        </Column>
                        <Column field="cancelRemark" header="Remarks" />

                        <template #expansion="slotProps">
                            <DataTable :value="slotProps.data.dcItems">
                                <Column field="name" header="Item"/>
                                <Column field="hsn" header="HSN"/>
                                <Column field="packing" header="Packing"/>
                                <Column field="qty" header="Quantity"/>
                                <Column field="modelNo" header="Model"/>
                                <Column field="partNo" header="Part No."/>
                                <Column field="batch_no" header="Batch"/>
                                <Column field="mfgDate" header="Mfg/Exp Date">
                                    <template #body="slotProps">
                                        {{ slotProps.data.mfgDate }}/{{slotProps.data.expDate}}
                                    </template>
                                </Column>
                            </DataTable>
                        </template>

                    </DataTable>

                </template>
            </Card>
        </div>
</div>

</template>

<script>
import ProgressSpinner from 'primevue/progressspinner'
import Navbar from '../header/headerNavbar.vue'
import Card from 'primevue/card'
import DataTable from 'primevue/datatable'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import { getDeliveryChallan } from '@/services/apiService'
import Column from 'primevue/column'
import Tag from 'primevue/tag'
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
    components:{
        ProgressSpinner,
        Navbar,
        SideBar,
        Card,
        DataTable,
        InputText,
        Button,
        Column,
        Tag,
    },


    data(){
        return{
            spinnerLoadingStatus:false,
            cancelledDcs:[],
            expandedRows:[],
            filteredCancelDcs:[],
            filterQuery:null,
        }
    },

    methods:{
        getCancelledDcs(){
            const query = '?cancel[eq]=1';
            this.spinnerLoadingStatus = true;
            getDeliveryChallan(query).then((res)=>{
                this.spinnerLoadingStatus = false;
                this.cancelledDcs = res.data;
                this.filteredCancelDcs = res.data;
            }).catch((error)=>{
                this.showErrorToast(error.respone.data);
                this.spinnerLoadingStatus = false;
            })
        },
        globalFilter(){
            
            this.filteredCancelDcs = [];
            if(this.filterQuery!=null){
                this.cancelledDcs.forEach(e => {     
                    if (e.dcNo.includes(this.filterQuery) || e.deptPo.includes(this.filterQuery) || e.dept.name.includes(this.filterQuery) || e.location.name.includes(this.filterQuery)) { 
                      this.filteredCancelDcs.push(e); 
                    }
                    else{
                        e.dcItems.every(el => { 
                            if(el.name.includes(this.filterQuery) ){
                                this.filteredCancelDcs.push(e);
                                return false;
                            }
                            return true;
                        });
                    }
                });
            }
        },
        clearFilter(){
            this.filteredCancelDcs = this.cancelledDcs;
            this.filterQuery = null;
        },
        showErrorToast(error){
          this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
        },
    },
    mounted(){
        this.getCancelledDcs();
    }
}
</script>

<style>

</style>