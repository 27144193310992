<template>
  <table class="white-bg " >
    <OemPoHeader/>
    <tbody >
        <div class="content">
            <tr>
                <td ><label for="">Order No.: {{orderNo}}</label></td>
                <td ><label for="">Date: {{orderDate}}</label></td>
            </tr>
            <tr ><td style="padding-top: 10px;" colspan="2"><label for="">To,</label></td></tr>
            <tr >
                <td colspan="2">
                    <label for="">
                        {{manufacturerName}}
                    </label>
                </td>
            </tr>
            <tr>
                <td colspan="2">
                    <label for="">
                        {{manufacturerAdd}}
                    </label>
                </td>
            </tr>
            <tr class="py-2">
                <td style="padding-top: 10px;" colspan="2">
                    <label for="">
                        Kind Attn.:Concerned person
                    </label>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 10px;" colspan="2">
                    <label for="">
                        Sub: Purchase Order
                    </label>
                </td>
            </tr>
            <tr>
                <td style="padding-top: 10px;" colspan="2">
                    <label for="">
                    Sir,<br>
                With reference to Subject mentioned above we would like to place order for the Items mentioned <label v-if="!itemAnnexure">below</label> <label v-if="itemAnnexure"><strong> in Annexure I</strong></label>. Please <strong>Read the Terms & Conditions thoroughly.</strong>
                    </label>
                </td>
            </tr>

            <tr>
                <td colspan="2">
                    <table class="my-1 border-design" style="width:100%">
                                    <label v-if="!itemAnnexure" for="">
                                        <tr class="table-header-grey">
                                            <th style="width:5%">Sl No.</th>
                                            <th>Name of Item</th>
                                            <th>Quantity</th>
                                            <th>Rate</th>
                                            <th>Gst</th>
                                        </tr>
                                    
                                        <OemItems v-for="(item, index) in items" :key="item" :index="index" :no="index+1" :item="item"/>
                                    </label>
                                    
                    </table>
                </td>
            </tr>

            <tr>
                <td colspan="2">
                    <div class="py-3"><strong>Terms and Conditions:-</strong></div>
                    <TermsAndConditiions v-for="(item, index) in termsConditionsArr" :key="item.id" :index="index" :item="item" />
                    <div><strong>{{lastSlNo}})&nbsp;&nbsp;Delivery Address:&nbsp;&nbsp;</strong> 
                        <label v-if="!annexureStatus">
                            <table class="mx-7 my-1 border-design" style="width:90%">
                                    <tr class="table-header-grey">
                                        <th>Sl No.</th>
                                        <th>Name of Item</th>
                                        <th>District</th>
                                        <th>Location</th>
                                        <th>Quantity</th>
                                    </tr>
                                    <NonAnnex v-for="(location, index) in locationArr" :key="location" :slNo="index+1" :deliveryLocation="location"/>
                            </table>
                        </label>  
                        <label v-if="annexureStatus"><strong>As per Annexure {{deliveryAnnexNo}}</strong></label>
                    </div>
                </td>
            </tr>

            <tr>
                <td colspan="2">
                    <div class="flex align-items-center justify-content-center">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Kindly acknowledge and share the Performa invoice for advance payments within 24 hours of receipt of this Order.
                    </div>
                </td>
            </tr>

            <tr>
                <td >
                    <div class="py-3 px-5">
                        <Image src="/assets/images/transparent_ams_sign.png" alt="AMS" width="180"/>
                    </div>
                </td>
            </tr>

            <!-- more no. of items are placed in annexure -->
            <tr v-if="itemAnnexure">
                <td colspan="2">
                    <div class="page-breaker">
                        <table class="border-design" style="width:100%">
                            <tr>
                                <th colspan="4">Annexure {{itemAnnexNo}}</th>
                            </tr>
                            <tr class="table-header-grey">
                                <th>Sl No.</th>
                                <th>Name of Item</th>
                                <th>Quantity</th>
                                <th>Rate</th>
                                <th>Gst</th>
                            </tr>
                            <OemItems v-for="(item, index) in items" :key="item" :index="index" :no="index+1" :item="item"/>
                        </table>
                    </div>
                    
                </td>
            </tr>


            <!--  -->
            <tr v-if="annexureStatus">
                <td colspan="2">
                    <div class="page-breaker">
                        <table class="border-design" style="width:100%">
                            <tr>
                                <th colspan="4">Annexure {{deliveryAnnexNo}}</th>
                            </tr>
                            <tr class="table-header-grey">
                                <th>Name of Item</th>
                                <th>District</th>
                                <th>Location</th>
                                <th class="minorCol">Quantity</th>
                            </tr>
                            <Annexure v-for="(location, index) in locationArr" :key="location" :index="index" :deliveryLocation="location"/>
                        </table>
                    </div>
                    
                </td>
            </tr>
            
        </div>
    </tbody>
    <OemPoFooter/>
</table>

</template>

<script>
import Image from 'primevue/image';
import { useOemPoStore } from '../../stores/oemPo'
import TermsAndConditiions from './termsConditions.vue'
import Annexure from './annexureList.vue'
import NonAnnex from './nonAnnexureList.vue'
import OemItems from './oemItems.vue'
import OemPoHeader from './poPdfHeader.vue'
import OemPoFooter from './poPdfFooter.vue'

 export default{
    components:{ TermsAndConditiions, Annexure, NonAnnex, OemItems, OemPoHeader, OemPoFooter, Image },
    data(){
        return {
            count:0,
            orderNo:null,
            orderDate:null,
            manufacturerName:null,
            manufacturerAdd:null,
            items:[],
            termsConditions:null,
            termsArr:[],
            termsConditionsArr:[],
            termsNo:0,
            lastSlNo:null,
            annexureStatus:false,
            itemAnnexure:false,
            deliveryAnnexNo:'I',
            itemAnnexNo:'I',
            deliveryLocations:[],
            locationArr:null,
            innerLevel:0,
            outerLevel:0,
            innerMax:0,
        }
    },
    methods:{
        getOemPoData(){
            const store = useOemPoStore();

            this.orderNo = store.data.po_no;
            this.orderDate = store.data.po_date;
            this.manufacturerName = store.data.manufacturer.name;
            this.manufacturerAdd = store.data.manufacturer.address;
            this.items = store.data.oem_po_details;

            if(this.items.length>10){
                this.itemAnnexure = true;
                this.deliveryAnnexNo = 'II';
            }
            this.termsConditions = store.data.terms_and_contions;

            this.getTermsConditions(this.termsConditions);
            this.getDeliveryLocations(this.items);

        },
        getTermsConditions(data){
            this.termsArr = data.split(/\r?\n/);
            this.termsArr.forEach(element => {
                
                this.termsConditionsArr.push({
                    slNo:++this.termsNo,
                    content:element,
                });
            });
            this.lastSlNo = this.termsNo + 1;
        },
        getDeliveryLocations(data){
            data.forEach((element, key) => {

                this.deliveryLocations[key] = {
                    'item_name':element.item_name,
                    'quantity':element.quantity,
                    'location':element.location,
                }
            });

            this.deliveryLocations.forEach((element) => {
                this.outerLevel++;
                element.location.forEach(() => {
                        this.innerLevel++;
                });
                if(this.innerLevel > this.innerMax)
                    this.innerMax = this.innerLevel;
                this.innerLevel = 0;
            });

                if(this.innerMax>1 || (this.innerMax=1 && this.outerLevel>3)){
                    this.annexureStatus=true;
                }
                this.locationArr = this.deliveryLocations;

        }
    },
    mounted(){
        this.getOemPoData();   
    },
    updated(){
        window.print();
    }
 }
</script>

<style scoped>
.white-bg{
    background-color: white;
}

.border-design{
    border: 0.5px solid #7E7E7E;
    border-collapse: collapse;
}

.content {
    position:relative;
    margin-bottom:20%;
    width: 100%;
    height:auto;
    font-size: 0.7em;
}

.table-header-grey{
    background-color: grey;
}

.footer{
    position: fixed;
    bottom: 0;
    height:20px;
    padding-top: 10%;
}

.page-breaker{
    page-break-before: always;
}

th{
    border: 0.5px solid #7E7E7E;
    border-collapse: collapse;
}

tr { page-break-inside: avoid }
</style>