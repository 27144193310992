<template>        
       <tr> 
        <td style="width:4%"> <strong>{{no}})</strong></td>
        <td for=""><strong>{{contentText}}</strong></td>
        </tr>
    
</template>

<script>

export default {
    props:{
       item:Object,
    },
    data(){
        return{
            no:this.item.slNo,
            contentText:this.item.content,
        }
    },
}
</script>

<style>

</style>