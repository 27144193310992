<template>
<Toast/>
<Navbar/>
<SideBar/>
  <div class="card main-content">
    <div class="progress-spinner" v-if="spinnerLoadingStatus">
            <ProgressSpinner></ProgressSpinner>
        </div>
    <div class="surface-ground ">
      <Card>
        <template #content>
          <DataTable :value="stores"  :size="small" :loading="loadingStatus" v-model:filters="filters" tableStyle="min-width: 50rem" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                  currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id"
                  @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" >

              <template #header >
                  <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                      <span class="text-xl text-900 font-bold">Stores</span>
                      <div class="flex justify-content-end">
                          <Button label="New Store" size="small" icon="pi pi-plus" @click="newStoreDialogVisibility=true"/>
                      </div>
                  </div>
              </template>

              <Column field="location" header="Name"/>
              <Column field="address" header="Address"/>
              <Column field="contactPerson" header="Contact"/>
              <Column field="contactNo" header="Contact No."/>
              <Column header="Status">
                <template #body="slotProps">
                    <Tag class="px-4" :value="getStatus(slotProps.data)" :severity="getSeverity(slotProps.data)" /> <br>
                </template>
              </Column>
              <Column header="Action">
                <template #body="slotProps">
                    <Button class="px-3 py-2" label="Update" severity="info" size="small" @click="updateStore(slotProps.data)"/>
                </template>
              </Column>
          </DataTable>

        <!-- new store dialog start -->
        <Dialog :visible="newStoreDialogVisibility" modal :closable="false" header="Store Details" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
            <div class="formgrid grid mt-2">
                <div class="field col">
                    <label>Name</label>
                    <InputText v-model="storeName" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
                </div>
                <div class="field col">
                    <label>Address</label>
                    <InputText v-model="storeAddress" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
                </div>
            </div>

            <Divider align="left" type="solid">
                <b>Contact Person:</b>
            </Divider>
            <div class="formgrid grid">
                <div class="field col">
                    <label for="firstname2">Name</label>
                    <InputText v-model="storeContactPerson" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
                </div>
                <div class="field col">
                    <label for="lastname2">Contact</label>
                    <InputMask v-model="storeContactNo" mask="9999999999" placeholder="99xxxxxxxx" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
                </div>
            </div>


            <template #footer>
                <div class="flex flex-row-reverse flex-wrap">
                    <div class="flex align-items-center justify-content-center ">
                        <Button class="flex align-items-center justify-content-center " size="small" label="Close" severity="danger" @click="hideNewStoreDialog"></Button>
                    </div>
                    <div class="flex align-items-center justify-content-center">
                        <Button v-if="!updateBtn" class="flex align-items-center justify-content-center " size="small" label="Save" icon="pi pi-save" severity="success" @click="handleSave"/>
                        <Button v-if="updateBtn" class="flex align-items-center justify-content-center " size="small" label="Update" icon="pi pi-save" severity="success" @click="updateStoreDetails"/>
                    </div>
                </div>
            </template>
        </Dialog>
        <!-- new store dialog end -->

        </template>

      </Card>
    </div>
  </div>
</template>

<script>
import Toast from 'primevue/toast'
import Navbar from '../header/headerNavbar.vue'
import { getStores, createStore, updateStore } from '@/services/apiService'
import DataTable from 'primevue/datatable'
import Card from 'primevue/card'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import Divider from 'primevue/divider'
import InputMask from 'primevue/inputmask'
import Dialog from 'primevue/dialog'
import Column from 'primevue/column'
import Tag from 'primevue/tag'
import ProgressSpinner from 'primevue/progressspinner'
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
  components:{
    Toast,
        Navbar,
        SideBar,
        DataTable,
        Card,
        Button,
        InputText,
        Divider,
        InputMask,
        Dialog,
        Column,
        Tag,
        ProgressSpinner,
    },
    data(){
      return {
        expandedRows:[],
        stores:[],
        loadingStatus:false,
        newStoreDialogVisibility:false,
        storeName:null,
        storeAddress:null,
        storeContactPerson:null,
        storeContactNo:null,
        storeId:null,
        updateBtn:false,
        spinnerLoadingStatus:false,
      }
    },
    methods:{
      getStoreList(){
        this.spinnerLoadingStatus = true;
        getStores().then((res)=>{
          this.stores = res.data;
          this.spinnerLoadingStatus = false;
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
          this.spinnerLoadingStatus = false;
        });
      },
      hideNewStoreDialog(){
        this.newStoreDialogVisibility = false;
        this.storeName = null;
        this.storeAddress = null;
        this.storeContactPerson = null;
        this.storeContactNo = null;
      },
      handleSave(){
        if(this.storeName==null || this.storeAddress==null){
          return this.showErrorToast({'message':'* marked fields are required!'});
        }

        const data = {
          'name':this.storeName,
          'address':this.storeAddress,
          'contactPerson':this.storeContactPerson,
          'contactNo':this.storeContactNo,
        }

        createStore(data).then((res)=>{
            this.hideNewStoreDialog();
            this.showSuccessToast(res.data);
            this.getStoreList();
          }).catch((error)=>{
            this.hideNewStoreDialog();
            this.showErrorToast(error.response.data);
          });
      },
      updateStore(data){
        this.updateBtn = true;
        this.storeId = data.uuid;
        this.storeName = data.location;
        this.storeAddress = data.address;
        this.storeContactPerson = data.contactPerson;
        this.storeContactNo = data.contactNo;
        this.newStoreDialogVisibility = true;
      },
      updateStoreDetails(){
        const data = {
          'name':this.storeName,
          'address':this.storeAddress,
          'contactPerson':this.storeContactPerson,
          'contactNo':this.storeContactNo,
        }
        updateStore(data, this.storeId).then((res)=>{
          this.updateBtn = false;
          this.showSuccessToast(res.data);
          this.hideNewStoreDialog();
          this.getStoreList();
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
        });
      },
      getStatus(store){
            switch (store.status) {
                case '0':
                    return 'Closed'
                case '1':
                    return 'Active';
                default:
                    break;
            }
      },
      getSeverity(store){
            switch (store.status) {
                case '0':
                    return 'danger'
                case '1':
                    return 'success'
                default:
                    break;
            }
      },
      showErrorToast(error){
        this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
      },
      showSuccessToast(data){
        this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
      },
    },
    mounted(){
      this.getStoreList();
    }
}
</script>

<style scoped>


.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}


</style>