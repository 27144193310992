// import { createApp, watch } from 'vue'
import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config'
import 'primevue/resources/themes/lara-light-blue/theme.css'
import '../node_modules/primeflex/primeflex.css'
import ToastService from 'primevue/toastservice'
import Router from './router'
import 'primeicons/primeicons.css'
import { createPinia } from 'pinia'
import Tooltip from 'primevue/tooltip';
import VueCookies from 'vue-cookies';
// import DialogService from 'primevue/dialogservice';
import ConfirmationService from 'primevue/confirmationservice';
import Ripple from 'primevue/ripple'
import StyleClass from 'primevue/styleclass'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret)

const pinia = createPinia();

const app = createApp(App);

app.use(PrimeVue, { ripple: true  });

app.use(ToastService);
app.use(PrimeVue);
app.use(Router);
app.use(pinia);
app.use(VueCookies);
app.use(ConfirmationService);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);

app.mount("#app");
app.component('font-awesome-icon', FontAwesomeIcon)
// createApp(App).use(ToastService).use(PrimeVue).use(Router).use(pinia).directive('tooltip', Tooltip).use(VueCookies).use(ConfirmationService).mount('#app');

// watch(
//     pinia.state,
//     (state)=>{
//         localStorage.setItem("oemPo", JSON.stringify(state.oemPo));
//     },
//     { deep:true }
// );




