   
  <!-- menu -->
  
<template>
        
        <!-- <Menubar :model="items" class="surface-0 w-full"> -->
        <div class="card flex"> 
            <Menubar :model="items" class="fixed-menu">
                <template #start>
                    <a class="inline-flex align-items-center gap-1 " @click="this.$router.push('/dashboard')">
                        <img alt="user header" src="/assets/images/_dashboard_logo.png" height="35px;" class="px-5 mt-2 mb-0 pb-0"/>
                    </a>
                </template>
            
                <template #end>
                    <div class="flex align-items-center gap-2 ">  
                            <Avatar type="button" image="/assets/images/logo.png" shape="circle" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu"/>
                            <Menu ref="menu" id="overlay_menu" :model="sideitems" :popup="true"  />
                    </div>
                </template>
            </Menubar>

        </div>

</template>

<script>
import Menubar from 'primevue/menubar';
import Avatar from 'primevue/avatar';
import Menu from 'primevue/menu';
import { logout } from '@/services/apiService';

export default {

  components: { 
        Menubar, 
        Avatar, 
        Menu, 
    },
    data() {
        return {
            items2: [
                // {
                //     label: 'Dashboard',
                //     icon: 'pi pi-home',
                //     command: ()=>{
                //                 this.$router.push('/dashboard')
                //     }
                // },
                {
                    label:'Department',
                    items:[
                            {
                                label: 'Departments List',
                                command: ()=>{
                                            this.$router.push('/departments')
                                }
                            },
                            {
                                label: 'Roc',
                                items: [
                                    {
                                        label: 'New',
                                        icon: 'pi pi-plus',
                                        command: ()=>{
                                            this.$router.push('/add-roc')
                                        }
                                    },
                                    {
                                        label: 'List all',
                                        icon: 'pi pi-server',
                                        command: ()=>{
                                            this.$router.push('/roc-list')
                                        }
                                    },
                                ]
                            },
                            {
                                label: 'Department PO',
                                items: [
                                    {
                                        label: 'New',
                                        icon: 'pi pi-plus',
                                        command: ()=>{
                                            this.$router.push('/add-dept-po')
                                        }
                                    },
                                    {
                                        label: 'List all',
                                        icon: 'pi pi-server',
                                        command: ()=>{
                                            this.$router.push('/dept-po-list')
                                        }
                                    },
                                ]
                            },
                        ]
                },
                {
                    label:'OEM',
                    items:[
                            {
                                label:'OEMs List',
                                command: ()=>{
                                    this.$router.push('/manufacturer-list')
                                }
                            },
                            {
                                label: 'OEM PO',
                                items: [
                                    {
                                        label: 'New',
                                        icon: 'pi pi-plus',
                                        command: ()=>{
                                            this.$router.push('add-oem-po')
                                        }
                                    },
                                    {
                                        label: 'Draft',
                                        icon: 'pi pi-file-edit',
                                        command: ()=>{
                                            this.$router.push('oem-po-drafts')
                                        }
                                    },
                                    {
                                        label: 'Approved',
                                        icon: 'pi pi-check-square',
                                        command: ()=>{
                                            this.$router.push('oem-po-list')
                                        }
                                    },
                                    {
                                        label: 'Cancelled',
                                        icon: 'pi pi-file-excel',
                                        command: ()=>{
                                            this.$router.push('oem-po-cancelled')
                                        }
                                    },
                                ]
                            },
                    ]
                },
                {
                    label:'Delivery Challan',
                    // command:()=>{
                    //                         this.$router.push('/delivery-challan')
                    //                     }
                    items: [
                                    {
                                        label: 'List All',
                                        command:()=>{
                                            this.$router.push('/delivery-challan')
                                        }
                                    },
                                    {
                                        label: 'Bulk Item Delivery Challan',
                                        command:()=>{
                                            this.$router.push('/delivery-challan-bulk')
                                        }
                                    },
                                    {
                                        label: 'Cancelled Challans',
                                        command:()=>{
                                            this.$router.push('/cancelled-delivery-challan')
                                        }
                                    },
                                ]
                },
                {
                    label:'Bill',
                    items: [
                                {
                                    label: 'New',
                                    icon: 'pi pi-plus',
                                    command:()=>{
                                        this.$router.push('/add-new-bill')
                                    }
                                },
                                {
                                    label: 'Draft',
                                    icon: 'pi pi-file-edit',
                                    command:()=>{
                                        this.$router.push('/bill-list-draft')
                                    }
                                },
                                {
                                    label: 'Approved',
                                    icon: 'pi pi-check-square',
                                    command:()=>{
                                        this.$router.push('/bill-list')
                                    }
                                },
                                {
                                    label: 'Cancelled',
                                    icon: 'pi pi-file-excel',
                                    command:()=>{
                                        this.$router.push('/bill-list-cancelled')
                                    }
                                },
                            ]
                },
                {
                    label:'Stock',
                    items: [
                                {
                                    label: 'Stock Arrival',
                                    command:()=>{
                                            this.$router.push('/stock-arrival')
                                    }        
                                },
                                {
                                    label: 'Invoices',
                                    command:()=>{
                                            this.$router.push('/invoice-details')
                                    }        
                                },
                                {
                                    label: 'Store Inventory',
                                    command:()=>{
                                            this.$router.push('/inventory')
                                    }        
                                },
                                {
                                    label: 'Stores',
                                    command:()=>{
                                            this.$router.push('/store-details')
                                    }        
                                },
                        ]
                },
                {
                    label: 'Others',
                    items: [
                                    {
                                        label: 'Terms & Conditions',
                                        command:()=>{
                                            this.$router.push('/terms-and-conditions')
                                        }
                                    },
                                    {
                                        label: 'Categories',
                                        command:()=>{
                                            this.$router.push('/categories')
                                        }
                                    },
                                ]
                },
                {
                    label: 'SideBarTwo',
                    command: ()=>{
                         this.$router.push('/sidebar-two')
                    }
                }
                // {
                //     label: 'Pincode Test',
                //     command: ()=>{
                //          this.$router.push('/pincode')
                //     }
                // }

            ],
            sideitems:[
               {
                label: 'Profile',
                icon:'pi pi-user'
               },
               {
                label:'Logout',
                icon: 'pi pi-sign-out',
                command: () => {
                        logout().then((res)=>{
                            localStorage.clear();
                            this.logoutToast(res.data);
                            this.$router.push('/logout');
                        }).catch((error)=>this.showErrorToast(error.response.data)); 
                }
               }
            ],
        };
    },

    methods:{
        toggle(event){
            this.$refs.menu.toggle(event);
        },
        logoutToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
        },
        showErrorToast(error){
               this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 5000 });
        },
    },

};
</script>

<style scoped>

.fixed-menu {
    position: fixed;
    height: 53px;
    top: 0;
    z-index: 999;
    /* margin-right: 4rem; */
    /* width: calc(100% - 210px); */
    /* width: calc(100vw - 226px); */
    /* width: calc(100vw - 226px); */
    width: 99vw;
    /* margin-bottom: 10em; */
    margin-bottom: 50px;
    /* margin-left: 210px; */
    /* padding-right: 2rem; */
}

.p-menubar {
    background-color: white;
}


</style>