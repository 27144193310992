<template>
  
    <div class="sm:col-12 md:col-12 lg:col-12">
        <div class="surface-card shadow-2 p-3 border-round-lg mt-2 ml-2">
            <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl ml-2">
                    Filter Options
                </div>
            </div>
            <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center w-full">
                  <div class="col">
                    <label class="mt-2">Installation:</label>
                    <Dropdown class="w-full mt-2" v-model="filterAttrib1" placeholder="Select an option" :options="equipInstallationStatus" optionLabel="label" optionValue="id" showClear/>
                  </div>
                  <div class="col">
                    <label class="mt-2">Servicing:</label>
                    <Dropdown class="w-full mt-2" v-model="servicingStatus" placeholder="Select an option" :options="servicingStatusOptions" optionLabel="label" optionValue="id" showClear/>
                  </div>
                  <div class="col">
                    <Button label="Apply Filter" raised text severity="success" class="w-full mt-4" size="small" v-model="filterAttrib1"/>
                  </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sm:col-12 md:col-12 lg:col-12">
        <div class="surface-card shadow-2 p-3 border-round-lg mt-2 ml-2">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl ml-2">
                    Equipments Report
                </div>
                <div class="flex align-items-center justify-content-center text-xl mr-2 mb-2">
                    <Button label="Download report" raised  size="small" style="background-color:#B46076; border-color:#B46076" />
                </div>
            </div>

            <div class="flex flex-wrap">
                <div class="flex align-items-center justify-content-center w-full">
                    <div class="col">
                        <DataTable :value="equipmentArr" size="small" showGridlines :loading="tableLoading" v-model:filters="filters" tableStyle="min-width: 50rem" dataKey="id" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" scrollable scrollHeight="500px">
                            <Column header="Equipment"></Column>
                            <Column header="Manufacturer"></Column>
                            <Column header="Model No"></Column>
                            <Column header="Part/Sr No."></Column>
                            <Column header="Installation date"></Column>
                            <Column header="AMS Representative"></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
// import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
// import Calendar from 'primevue/calendar'

export default {
    components:{
        // InputText,
        Button,
        Dropdown,
        // Calendar,
        DataTable,
        Column,
    },
    data(){
        return{
            filterAttrib1:null,
            equipInstallationStatus:[
                {label:'Pending', id:1},
                {label:'Completed', id:2},
            ],
            servicingStatus:null,
            servicingStatusOptions:[
                {label:'Initiated', id:1},
                {label:'Not Initiated', id:2},
            ],
            equipmentArr:[],
        }
    }
}
</script>

<style>

</style>