<template>
<Navbar/>
    <Card>
        <template #title> PINCODE test </template>
        <template #content>
            <p class="m-0">
                <label for="">Pincode</label>
                <InputText v-model="pincode" />
                <Button label="Check" @click="fetchAddress"/>
            </p>

            <label for=""> <h2> {{district}}</h2></label>
        </template>
    </Card>                             
</template>

<script>
import InputText from 'primevue/inputtext'
import Card from 'primevue/card'
import { getPincodeDetails } from '@/services/apiService'
import Button from 'primevue/button'
import Navbar from '../components/header/headerNavbar.vue'
export default {
    components:{
        InputText,
        Card,
        Button,
        Navbar
    },

    data(){
        return{
            pincode:null,
            district:'Default',
        }
    },

    methods:{
        fetchAddress(){
            getPincodeDetails(this.pincode).then((res)=>{
                console.log('PINCODE API response');
                console.log(res.data);

                console.log('district');
                console.log(res.data[0].district);
                this.district = res.data[0].district;
            }).catch((error)=>console.log(error));
        }
    },
    
}
</script>

<style>

</style>