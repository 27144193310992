<template>
<Navbar/>
<SideBar/>
  
  <div class="card main-content">
    <!-- <div class="progress-spinner" v-if="loadingStatus">
        <ProgressSpinner></ProgressSpinner>
    </div> -->
    <div class="surface-ground ">
        <Card>
            <template #content>

                <DataTable :value="departments" :size="small" :loading="loadingStatus" tableStyle="min-width: 50rem" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]"
                paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                currentPageReportTemplate="{first} to {last} of {totalRecords}" dataKey="id"
                @rowExpand="onRowExpand" @rowCollapse="onRowCollapse">
            <template #header >
                <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                    <span class="text-xl text-900 font-bold">Departments</span>
                    <Button class="px-2 py-2" severity="info" size="small" icon="pi pi-plus" label="New DEPT" outlined raised @click="showDialog"/>
                </div>
            </template>
            
            <!-- <Column expander style="width: 5rem"/> -->
            <Column field="name" header="Dept"></Column>
            <Column field="shortCode" header="Short Code"></Column>
            <Column field="address" header="Address" style="width:10%"></Column>
            <Column field="code" header="State Code"></Column>
            <Column field="phone" header="Phone"></Column>
            <Column field="email" header="Email"></Column>
            <Column field="state" header="State"></Column>
            <Column  header="Update">
                <template #body="slotProps">
                    <Button class="px-2 py-2" severity="success" icon="pi pi-refresh" label="Update" size="small" outlined raised @click="showUpdateDialog(slotProps)"/>

                </template>
            </Column>
            
            <template #footer> In total there are {{ departments ? departments.length : 0 }} DEPTs. </template>
        </DataTable>

        <!-- new dept dialog -->
    <Dialog :visible="dialogVisible" modal :closable="false" :header="deptDialogHeader" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

        <div class="formgrid grid mt-2">
            <div class="field col">
                <label for="firstname2">Name:</label>
                <InputText v-model="deptName" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
            <div class="field col">
                <label for="firstname2">Address:</label>
                <InputText v-model="deptAddress" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
            
        </div>

         <div class="formgrid grid mt-2">
            <div class="field col">
                <label for="lastname2">Email:</label>
                <InputText v-model="deptEmail" type="email" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
                <InlineMessage v-if="emailError===true">Invalid Email</InlineMessage>
            </div>
            <div class="field col">
                <label for="lastname2">Contact:</label>
                <InputMask v-model="deptContact"  mask="9999999999" placeholder="99xxxxxxxx" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
            
        </div>

        <div class="formgrid grid mt-2">
            <div class="field col">
                <label for="lastname2">State:</label>
                <Dropdown size="small" v-model="deptState" editable :options="indianStates" optionValue="name" optionLabel="name" placeholder="Select State" class="w-full"/>
            </div>
            <div class="field col">
                <label for="lastname2">Dept Short Code:</label>
                <InputText v-model="deptShortCode" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
            <div class="field col">
                <label for="lastname2">State Code:</label>
                <InputText v-model="deptStateCode" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
        </div>

        <Divider align="left" type="solid">
            <b>Head Authority:</b>
        </Divider>

        <div class="formgrid grid mt-2">
            <div class="field col">
                <label >Designation:</label>
                <InputText v-model="headDesignation" type="text" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
            
        </div>

        <template #footer>
            <div class="flex flex-row-reverse flex-wrap">
                <div class="flex align-items-center justify-content-center ">
                    <Button class="flex align-items-center justify-content-center " size="small" label="Close" severity="danger" @click="hideDialog"></Button>
                </div>
                <div v-if="!updateDeptStatus" class="flex align-items-center justify-content-center">
                    <Button class="flex align-items-center justify-content-center " size="small" label="Save" icon="pi pi-save" severity="success" @click="handleSave"/>
                </div>
                
                <div v-if="updateDeptStatus" class="flex align-items-center justify-content-center">
                    <Button class="flex align-items-center justify-content-center " size="small" label="Update" icon="pi pi-refresh" severity="success" @click="updateDept"/>
                </div>
            </div>
        </template>
    </Dialog>
  <!-- new dept dialog end -->

            </template>
        </Card>

    </div>
    
  </div>

 

</template>

<script>
import { createDept, getDepartments, updateDepartment } from '@/services/apiService';
import { useIndianStateStore } from '../../stores/indianStates'
import Navbar from '../header/headerNavbar.vue';
import Card from 'primevue/card';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import InputMask from 'primevue/inputmask';
import InlineMessage from 'primevue/inlinemessage';
import Dropdown from 'primevue/dropdown';
import Divider from 'primevue/divider';
// import ProgressSpinner from 'primevue/progressspinner';
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
    components:{
        Navbar,
        SideBar,
        Card,
        DataTable,
        Column,
        Button,
        Dialog,
        InputText,
        InputMask,
        InlineMessage,
        Dropdown,
        Divider,
        // ProgressSpinner,
    },

    data(){
        return{
            loadingStatus:false,
            departments:[],
            dialogVisible:false,
            emailError:false,
            deptName:null,
            deptEmail:null,
            deptAddress:null,
            deptContact:null,
            deptState:null,
            indianStates:null,
            deptDialogHeader:null,
            updateDeptStatus:false,
            deptShortCode:null,
            deptId:null,
            deptStateCode:null,
            headDesignation:null,
        }
    },
    methods:{
        getDeptsList(){
            this.loadingStatus = true;
            getDepartments().then((res)=> 
                {
                    this.loadingStatus = false;
                    this.departments = res.data.reverse();
                }
                ).catch((error)=>{
                    this.loadingStatus = false;
                    this.showErrorToast(error.response.data);
                });
        },
        showDialog(){
            this.updateDeptStatus = false;
            this.deptDialogHeader = 'New Department';
            this.dialogVisible = true;
        },
        showUpdateDialog(data){
            this.deptId = data.data.id;
            this.deptName = data.data.name;
            this.deptAddress = data.data.address;
            this.deptEmail = data.data.email;
            this.deptContact = data.data.phone;
            this.deptState = data.data.state;
            this.deptShortCode = data.data.shortCode;
            this.deptStateCode = data.data.code;
            this.headDesignation = data.data.head;

            this.updateDeptStatus = true;
            this.deptDialogHeader = 'Update Department';
            this.dialogVisible = true;
        },
        hideDialog(){
            this.dialogVisible = false;
            this.deptId = null;
            this.deptName = null;
            this.deptAddress = null;
            this.deptEmail = null;
            this.deptContact = null;
            this.deptState = null;
            this.deptShortCode = null;
            this.deptStateCode = null;
            this.headDesignation = null;
        },
        validateEmail(email) {
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
                this.emailError = false
            } else {
                this.emailError = true
            }
        },
        getIndianStates(){
            const store = useIndianStateStore();
            this.indianStates = store.fetchStates;
        },
        handleSave(){

            if(this.deptName==null || this.deptAddress==null || this.deptContact==null || this.deptEmail==null || this.deptState==null){
                return this.showErrorToast({'message': 'All fields must be filled!'})
            }
            const data = {
                'name':this.deptName,
                'address':this.deptAddress,
                'phone':this.deptContact,
                'email':this.deptEmail,
                'state':this.deptState,
                'shortCode':this.deptShortCode,
                'stateCode':this.deptStateCode,
                'head':this.headDesignation,
            };

            this.loadingStatus = true;
            createDept(data).then((res)=>{
                this.showSuccessToast(res.data);
                this.hideDialog();
                this.getDeptsList();
                this.loadingStatus = false;
            }).catch((error)=>{
                this.showErrorToast(error.response.data)
                this.loadingStatus = false;
            });     
        },
        updateDept(){
            const data = {
                'name':this.deptName,
                'address':this.deptAddress,
                'email':this.deptEmail,
                'phone':this.deptContact,
                'state':this.deptState,
                'shortCode':this.deptShortCode,
                'stateCode':this.deptStateCode,
                'head':this.headDesignation,
            }

            this.loadingStatus = true;
            updateDepartment(data, this.deptId).then((res)=>{
                
                this.hideDialog();
                this.showSuccessToast(res.data);
                this.getDeptsList();
                this.loadingStatus = false;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.loadingStatus = false;
            });
            
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
        },   
        showErrorToast(error){
                this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
        },
    },
    watch:{
        deptEmail(value){
            this.deptEmail = value;
            this.validateEmail(value);
        }
    },

    mounted(){
        this.getDeptsList();
        this.getIndianStates();
    }
}
</script>

<style scoped>

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}

</style>