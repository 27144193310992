<template>
  <Navbar/>
  <SideBar />

<div class="card main-content">
        <div class="progress-spinner" v-if="spinnerLoadingStatus">
            <ProgressSpinner></ProgressSpinner>
        </div>
        <div class="surface-200">

            <div class="formgrid grid">
                <div class="field col-fixed w-6">
                    <div class="field">
                        <Card class="vertical-full-screen">
                            <template #title>
                                <div class="flex justify-content-between flex-wrap">
                                    <div class="flex align-items-center justify-content-center text-xl">
                                        Delivery Challan Details
                                    </div>
                                    <div class="flex align-items-center justify-content-center text-xl">
                                        <Button label="Generate DC" size="small" severity="success" class="px-4 py-2 mr-2" @click="handleGenerateDc"/>
                                    </div>
                                </div>
                            </template>
                            <template #content>

                                <div class="formgrid grid">
                                    <div class="field col">
                                        <label ><strong>Locations:</strong></label>
                                        <Dropdown class="w-full" size="small" v-model="selectedLocation" :options="filteredLocations" filter optionLabel="name" placeholder="Select Location" @change="filterLocationItem"/>
                                    </div>
                                    <div class="field col">
                                        <label ><strong>Date:</strong></label>
                                        <Calendar showIcon v-model="dcDate" class="w-full"/>
                                    </div>
                                </div>

                                 <div class="formgrid grid">
                                    <div class="field col">
                                        <label ><strong>Department:</strong></label>
                                        <Dropdown v-model="dcDept" filter :options="departments" size="small" class="w-full" optionValue="id" optionLabel="name" placeholder="- - Select Dept - -"/>
                                    </div>
                                    <div class="field col">
                                        <label ><strong>Department PO:</strong></label>
                                        <label class="w-full text-indigo-500 mt-2"><em> <strong>{{deptPoNo}}</strong></em></label>
                                    </div>
                                    
                                 </div>

                                 <!-- <div class="flex justify-content-end flex-wrap">
                                    <Button label="Reset" severity="warning" class="px-4 py-2 mb-2" />
                                </div> -->

                                <hr>

                                <DataTable v-model:filters="filters" v-model:selection="selectedItem" :value="filteredItems" tableStyle="min-width: 90%" scrollable scrollHeight="210px" paginator :rows="50" :rowsPerPageOptions="[3, 5, 10, 20, 50]"
                                        paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" dataKey="id" @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" @row-select="addItemsDc" @row-unselect="removeItemDc">

                                    <template #header >
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex align-items-center justify-content-center text-lg">
                                                Items Pending Delivery
                                            </div>
                                            <div class="flex align-items-center justify-content-center">
                                                <Button label="Reset" size="small" severity="warning" class="px-4 py-2 mr-2" @click="getItemsList"/>
                                                <InputText v-model="filters['global'].value" size="small" placeholder="Keyword Search" />
                                            </div>
                                        </div>
                                    </template>
                                    
                                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                                    <Column field="name" header="Item" />
                                    <Column field="quantity" header="Qty" />
                                    <Column field="hsn" header="HSN" />
                                    <Column field="deptPoNo" header="PO No." />                                    
                                    <Column field="batchNo" header="Batch"/>
                                    <Column header="Mfg/Exp" >
                                    <template #body="slotProps">
                                        {{slotProps.data.mfgDate}}/{{slotProps.data.expDate}}
                                    </template>
                                    </Column>
                                    <Column field="modelNo" header="Part/Model">
                                    <template #body="slotProps">
                                        {{slotProps.data.partNo}}/{{slotProps.data.modelNo}}
                                    </template>
                                    </Column>
                                    <Column header="Deliver To">
                                        <template #body="slotProps">
                                                <Button label="Show" size="small" icon="pi pi-external-link" @click="showLocations(slotProps.data)" />
                                        </template>
                                    </Column>

                                </DataTable>

                                <Dialog :visible="dialogVisibility" modal :closable="false" :header="dialogHeader" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                                            
                                    <DataTable :value="itemDeliveryLocations"  tableStyle="min-width: 30rem">
                                        <Column field="location" header="Location"></Column>
                                        <Column field="quantity" header="Quantity"></Column>
                                        <Column field="deliveredQty" header="Delivered Qty"></Column>
                                        
                                    </DataTable>
                                    <div class="flex justify-content-end flex-wrap">
                                    <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" severity="danger" @click="dialogVisibility=false"></Button>
                                    </div>
                                </Dialog>
                            </template>
                 
                        </Card>
                    </div>
                </div>
                <div class="field col">
                    <div class="field ">
                        <Card class="vertical-full-screen">
                            <template #title>
                                <div class="flex justify-content-between flex-wrap">
                                    <div class="flex align-items-center justify-content-center text-xl">
                                        Delivery Challan Items
                                    </div>
                                </div>
                            </template>
                            <template #content>
                                
                                    <!-- <div class="card flex flex-wrap p-fluid">
                                        <div v-for="(item, index) in dcItems" :key="index" class="grid">
                                            
                                        </div>
                                    </div> -->
                                    <span v-if="dcItems.length == 0">Please select items from the pending list to be added to Delivery Challan.</span>
                                    <DcItem v-for="(item, index) in dcItems" :key="index" :item="item" @bind-item="bindData"/>


                            </template>
                            <!-- <template #footer>
                                <div class="flex justify-content-end flex-wrap">
                                    <Button label="Reset" severity="warning" class="mt-8 px-4 py-2" />
                                </div>
                            </template> -->
                        </Card>
                    </div>
                </div>
                
            </div>

        </div>
</div>

</template>

<script>
import ProgressSpinner from 'primevue/progressspinner'
import Navbar from '../header/headerNavbar.vue'
import Card from 'primevue/card'
import Dropdown from 'primevue/dropdown'
import { deliveryChallan, getDeliveryLocations, getDepartments, getItemsPendingDelivery } from '@/services/apiService'
import Calendar from 'primevue/calendar'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import InputText from 'primevue/inputtext'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import DcItem from '../deliveryChallan/deliveryChallanItem.vue'
import Dialog from 'primevue/dialog'
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
    
    components:{
        Navbar,
        SideBar,
        ProgressSpinner,
        Card,
        Dropdown,
        Calendar,
        Button,
        DataTable,
        InputText,
        Column,
        DcItem,
        Dialog,
    },

    data(){
        return{
            spinnerLoadingStatus:false,
            selectedLocation:null,
            deliveryLocations:[],
            dcDate:null,
            pendingItems:[],
            filteredItems:[],
            filteredLocations:[],
            departments:[],
            dcDept:null,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: { value: null, matchMode: FilterMatchMode.IN },
                batchNo: { value: null, matchMode: FilterMatchMode.IN },
                modelNo: { value: null, matchMode: FilterMatchMode.IN },
            },
            deptPoNo:'Add items to Delivery Challan',
            dcNo:'Add items to Delivery Challan',
            selectedItem:[],
            dcItems:[],
            filterFlag:0,
            dialogHeader:null,
            dialogVisibility:null,
            itemDeliveryLocations:[],
            deptPo:null,
            locations2:[],
            poFilteredItem:[],
        }
    },
    methods:{
        getItemsList(){
            this.pendingItems = [];
            this.filteredItems = [];
            this.poFilteredItem = [];
            this.dcItems = [];
            this.selectedItem = [];
            this.deptPoNo = 'Add items to Delivery Challan';
            this.dcDate = null;
            this.filterFlag = 0;
        this.spinnerLoadingStatus = true;
            getItemsPendingDelivery().then((res)=>{
                    getDepartments().then((res2)=>{
                        this.departments = res2.data;
                    }).catch((error)=>{
                        this.showErrorToast(error.response.data);
                    })
                this.pendingItems = res.data;
                this.filteredItems = res.data;
                this.getLocations();
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            })
        },
        getLocations(){
            
            getDeliveryLocations().then((res)=>{
                this.deliveryLocations = res.data;
                this.filteredLocations = res.data;
                
                this.spinnerLoadingStatus = false;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            });
        },
        showLocations(data){
            this.dialogHeader = data.name;
            this.dialogVisibility = true;
            this.itemDeliveryLocations = data.location;
        },
        addItemsDc(event){
            
            const data = event.data;

            // filtering items and location based on item selection
            if(this.filterFlag==0){
                this.filteredItems = [];
                this.filteredLocations = [];

                // filter items based on dept po
                
                this.pendingItems.forEach(e => {
                    
                    if(data.deptPoNo == e.deptPoNo){
                    
                        this.filteredItems.push(e);

                        // filter delivery locations
                        e.location.forEach(el => {

                            this.filteredLocations.push({
                                 'id':el.id,
                                 'locationId':el.locationId,
                                 'name':el.location,
                                 'name2':el.location+' ('+el.quantity+')',
                            });
                        });

                    }
                });

                this.locations2 = this.filteredLocations;
                this.filteredLocations = [];
            
                this.locations2.every(e => {

                    if(this.filteredLocations.length == 0){
                        this.filteredLocations.push(e);
                    }
                    else{
                        let flag = 0;
                        this.filteredLocations.every(el => {
                            if(e.locationId == el.locationId){
                                flag = 1;
                                return false;
                            }
                            return true;
                        });
                        if(flag==0){
                            this.filteredLocations.push(e);
                        }
                    }

                    return true;
                });
                
                this.poFilteredItem = [];
                this.poFilteredItem = this.filteredItems;
                
                this.deptPoNo = data.deptPoNo;
                this.deptPo = data.deptPo;
                this.filterFlag = 1;
            }

                this.dcItems.push({
                    ...data,
                    availableQty:data.quantity,
                    avalQty:data.quantity,
                    totalQty:data.quantity,
                });

        },
        filterLocationItem(){

            this.filteredItems = [];
            if(this.poFilteredItem.length === 0){
                this.pendingItems.forEach(e => {
                    e.location.every(lo => {                        
                        if(lo.locationId == this.selectedLocation.locationId){
                            this.filteredItems.push(e);
                            return false;
                        }
                        return true;
                    });
                });
            }
            else{
                this.poFilteredItem.forEach(e => {
                    e.location.every(lo => {                        
                        if(lo.locationId == this.selectedLocation.locationId){
                            this.filteredItems.push(e);
                            return false;
                        }
                        return true;
                    });
                });
            }
        },
        bindData(itemId, itemData){
            const item = this.dcItems.find((e) => e.id == itemId);
            item.packing = itemData.itemUom;
            item.batchNo = itemData.itemBatch;
            item.partNo = itemData.itemPart;
            item.modelNo =itemData.itemModel;
            // item.quantity = itemData.itemQuantity;
            item.availableQty = itemData.itemQuantity;
            item.avalQty = itemData.itemAvailQty;
        },
        handleGenerateDc(){

            if(this.selectedLocation==null || this.dcDate==null || this.dcDept==null || this.dcItems.length == 0 || this.deptPo==null){
                return this.showErrorToast({'message':'Delivery Location/Date/Department/PO No. is missing!'});
            }

            let flag = 0;
            this.dcItems.every(e => {
                if(e.availableQty == 0){
                    flag = 1;
                    return false;
                }
                return true;
            });

            if(flag == 1){
                return this.showErrorToast({'message':'Challan quantity is invalid (Zero) for an item! Please check.'});
            }

            const data = {
                'dept':this.dcDept,
                'dcDate':this.dcDate.getFullYear()+'-'+(this.dcDate.getMonth()+1)+'-'+this.dcDate.getDate(),
                'deliveryLocation':this.selectedLocation.locationId,
                'deptPo':this.deptPo.uuid,
                // 'deptPoNo':this.deptPoNo,
                'dcItems':this.dcItems,
            }
          
            this.spinnerLoadingStatus = true;
            deliveryChallan(data).then((res)=>{
                this.showSuccessToast(res.data);
                this.spinnerLoadingStatus = false;
                this.$router.push('/delivery-challan');
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            })
        },
        removeItemDc(event){
            const index = this.dcItems.findIndex((e)=>e.id ==event.data.id);
            this.dcItems.splice(index, 1);
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 5000 });
        },   
        showErrorToast(error){
               this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 5000 });
        },
    },
    mounted(){
        // this.getLocations();
        this.getItemsList();
    }

}
</script>

<style>

.vertical-full-screen{
    height: 88vh;
}

.first-col-vertical-full-screen{
    height: 42vh;
    width: 80;
}

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}


</style>