<template>        
       <div> 
        <label for=""> <strong>{{no}}) &nbsp;</strong></label>
        <label for=""><strong>{{contentText}}</strong></label>
        </div>
    
</template>

<script>

export default {
    props:{
       item:Object,
    },
    data(){
        return{
            no:this.item.slNo,
            contentText:this.item.content,
        }
    },
}
</script>

<style>

</style>