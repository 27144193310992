<template>
  <tr class="yaxis-padding">
    <td class="item-center">{{slNo}}</td>
    <td class="item-center">{{name}}</td>
    <td v-if="addlColStatus" class="item-center">{{addlInfoCol}}</td>
    <td class="item-center">{{itemPacking}}</td>
    <td class="item-center">{{nop}}</td>
    <td class="item-center">&#8377; {{itemRate}}/-</td>
    <td class="item-center">{{gst}}</td>
  </tr>
</template>

<script>
export default {

    props:{ 
        item:Object,
        no:Number,
        addlColStatus:Boolean,
    },

    data(){
        return{
            slNo:this.no,
            name:this.item.item_name, 
            qty:(this.item.quantity).toLocaleString('en-IN'),
            itemRate:null,
            itemPacking:this.item.packing,
            gst:this.item.gst,
            nop:this.item.nop,
            addlInfoCol:this.item.add_on_data,
        }
    },

    created(){
      this.itemRate = (this.item.rate.toFixed(2)).split(".");
      this.itemRate = this.itemRate[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,")+(this.itemRate[1] ? ("."+this.itemRate[1]): "");
      
    }
}
</script>

<style scoped>
th, td {
  border: 0.5px solid #000000;
  border-collapse: collapse;
}

.item-center {
    text-align: center;
}

.yaxis-padding{
  height: 2em;
  vertical-align: middle;
}

</style>