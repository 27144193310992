<template>
<Navbar/>
<SideBar/>

<div class="card main-content">
  <div class="progress-spinner" v-if="spinnerLoadingStatus">
      <ProgressSpinner></ProgressSpinner>
  </div>
  <div>
          <div class="formgrid grid">
                <div class="field col-5 ">
                  <div class="field">
                    <!-- <Card style="height:40vh;"> -->
                    <Card >
                      <template #title>
                        <div class="text-xl">
                          Bill Details
                        </div>
                      </template>
                      <template #content>
                        
                          <div class="formgrid grid">
                              <div class="field col">
                                  <label ><strong>Department PO:</strong></label>
                                  <!-- <Chip v-if="blockUi==true" label="Select Department PO to unblock Delivery Challans" icon="pi pi-eye-slash"/> -->
                                  <Dropdown class="w-full" size="small" v-model="selectedDeptPo" :options="departmentPos" filter optionLabel="po_no" placeholder="Select PO" @change="filterDeliveryChallan"/>
                              </div>
                              <div class="field col">
                                  <label ><strong>Bill Date:</strong></label>
                                  <Calendar class="w-full" showIcon v-model="billDate" size="small"/>
                              </div>
                          </div>

                          <div class="formgrid grid">
                              <div class="field col">
                                <label ><strong>Department:</strong></label>
                                  <Dropdown size="small" v-model="selectedDept" :options="departments" filter optionLabel="name" placeholder="Select Departmenet" />
                              </div>
                              <div class="field col ">
                                <label ><strong>Bill No:</strong></label>
                                <InputText v-model="billNo" size="small" class="w-full"/>
                              </div>
                          </div>

                           <div class="flex justify-content-end flex-wrap pt-2 px-4">
                                <div class="formgrid grid">
                                    <div class="field">
                                            <Button label="Generate Bill" icon="pi pi-save" size="small" severity="success" class="py-2 px-2 " v-tooltip.top="'Click here to generate bill'" @click="handleSaveBill"/>          
                                    </div>
                                </div>
                            </div>
                      </template>

                    </Card>
                  </div>
                  <div class="field">
                    <!-- style="height:48vh;" -->
                    <Card >
                      <!-- <template #title>
                        <div class="text-xl">
                          Delivery Challans
                        </div>
                      </template> -->
                      <template #content>
                        
                        <!-- <BlockUI :blocked="blockUi"> -->
                        <DataTable  v-model:selection="selectedDcs" v-model:filters="filters" :value="filteredDcList" size="small" tableStyle="min-width: 90%" scrollable scrollHeight="21vh" paginator :rows="50" :rowsPerPageOptions="[3, 5, 10, 20, 50]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" dataKey="id" @row-select="addBillItem" @row-unselect="removeBillItem" @row-select-all="addAllItems" @row-unselect-all="removeAllItems">

                                    <template #header >
                                        <div class="flex justify-content-between flex-wrap">
                                            <div class="flex align-items-center justify-content-center text-lg">
                                                Challans Pending Billing
                                            </div>
                                            <div class="flex align-items-center justify-content-center">
                                                <Button label="Reset" size="small" severity="warning" class="px-4 py-2 mr-2" @click="clearFilter" v-tooltip.top="'Resets all fields'"/>
                                                <InputText v-model="filters['global'].value" size="small" placeholder="Keyword Search" />
                                            </div>
                                        </div>
                                    </template>

                                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                                    <Column field="dcNo" header="DC No.">
                                    </Column>
                                    <Column field="dcDate" header="DC Date">
                                    </Column>
                                    <!-- <Column field="dept.name" header="Dept"/> -->
                                    <Column field="deptPo" header="Dept PO"/>
                                    <!-- <Column field="location.name" header="Location"/> -->
                        </DataTable>
                        <!-- </BlockUI> -->
                      </template>
                    </Card>
                  </div>
                </div>
                <div class="field col">
                  <div class="field">
                    <Card style="height:85vh;">
                      <template #title>
                        <div class="text-xl">
                          Bill Items
                        </div>
                          <hr>
                      </template>
                      <template #content>
                        <div class="card mt-1">
                          <ScrollPanel style="width:100%; height:45vh" :pt="{
                              wrapper: {
                                  style: { 'border-right': '10px solid var(--surface-ground)' }
                              },
                              bary: 'hover:bg-primary-400 bg-primary-300 opacity-100'
                          }" >
                            <label v-if="billItems.length==0">Please select Delivery challan to add items to bill.</label>
                            <BillItem v-for="(item, index) in billItems" :key="index" :item="item" @bind-item="bindData" />
                          </ScrollPanel>
                        </div>
                      </template>
                      <template #footer>
                        <hr>
                        <div class="flex justify-content-between flex-wrap mt-1">
                            <div class="align-items-center justify-content-center text-lg">
                              <div class="field">
                                <strong>
                                  Bill Value
                                </strong>
                              </div>
                              <div class="field text-base">

                                <!-- <div class="field text-base"> 
                                </div> -->

                                <strong>Sub Total (Ex-GST):<em class="text-orange-500">  
                                  <!-- <InputNumber v-model="totalExGst" variant="filled" size="small" readonly /> -->
                                  {{formatCurrency(totalExGst)}}
                                  </em></strong>
                                <br>
                                <strong>Total GST: &emsp; &emsp;&emsp;&ensp;&ensp;&nbsp;<em class="text-orange-500"> {{formatCurrency(totalBillGst)}}</em></strong>
                                

                              </div>
                            </div>

                            <div class="align-items-center justify-content-center text-lg ">
                              <div class="field ">                                
                                  <strong>
                                    Department PO
                                  </strong>
                              </div>
                              <div class="field text-base">                                
                                  <strong>PO Value: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<em class="text-indigo-500"> {{formatCurrency(deptPoValue)}}</em></strong>
                                  <br>
                                  <strong>Grand Total: &nbsp;<em class="text-green-500"> {{formatCurrency(grandTotal)}}</em></strong>
                                  <br> 
                                  <strong>Previous Bill:&nbsp;<em class="text-indigo-500"> {{formatCurrency(prevBillTotal)}} </em> </strong> 
                                  <br>
                                  <strong>Balance:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<em class="text-red-500"> {{formatCurrency(deptBalance)}} </em> </strong>                             
                              </div>
                            </div> 

                            <div class="align-items-center justify-content-center text-lg ">
                              <div class="field pr-8">  
                              </div>
                              <div class="field text-base">
                                <!-- <strong>Due: &emsp;&emsp;&emsp;&ensp;&nbsp;<em class="text-red-500"> {{formatCurrency(dueAmt)}}</em></strong> -->
                                <br>
                                <label > <strong> Adjust:</strong> </label><InputNumber v-model="adjustmentValue" variant="filled" @input="updateAdjustValue($event)" minFractionDigits="2" maxFractionDigits="5" class="mt-1 pl-2"/>
                              </div>
                              
                            </div>                           
                        </div>
                    </template>
                    </Card>
                  </div>
                </div>
          </div>
  </div>
</div>
</template>

<script>
import Card from 'primevue/card'
import Navbar from '../header/headerNavbar.vue'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import DataTable from 'primevue/datatable'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import { createBill, getDeliveryChallan, getDepartments, getDeptPOList, fetchPrevBill } from '@/services/apiService'
import { FilterMatchMode } from 'primevue/api'
import ProgressSpinner from 'primevue/progressspinner'
import Column from 'primevue/column'
import BillItem from '../bill/billItems.vue';
import ScrollPanel from 'primevue/scrollpanel'
import InputNumber from 'primevue/inputnumber'
// import BlockUI from 'primevue/blockui'
// import Chip from 'primevue/chip'
// import Panel from 'primevue/panel'
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
  
  components:{
    Navbar,
        Card,
        Dropdown,
        Calendar,
        DataTable,
        Button,
        InputText,
        ProgressSpinner,
        Column,
        BillItem,
        ScrollPanel,
        // Panel,
        InputNumber,
        SideBar,
        // BlockUI,
        // Chip,
    },

    data(){
      return{
        departmentPos:[],
        selectedDeptPo:null,
        billDate:null,
        selectedDcs:[],
        dcList:[],
        filteredDcList:[],
        spinnerLoadingStatus:false,
        filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                dcNo: { value: null, matchMode: FilterMatchMode.IN },
                deptPo: { value: null, matchMode: FilterMatchMode.IN },
            },
        billItems:[],
        deptPoValue:0,
        totalBillGst:0,
        totalExGst:0,
        adjustmentValue:0,
        grandTotal:0,
        dueAmt:0,
        selectedDcList:[],
        dcListToShow:null,
        selectedDept:null,
        departments:[],
        blockUi:true,
        totalIgst:0,
        prevBills:[],
        prevBillTotal:0,
        deptBalance:0,
        billNo:null,
      }
    },
    methods:{
      getDeptPo(){
        this.spinnerLoadingStatus = true;
        const query = '?billStatus[neq]=1';
        getDeptPOList(query).then((res)=>{
          this.departmentPos = res.data;
          this.getDeptList();
          this.getDcList();
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
          this.spinnerLoadingStatus = false;
        })
      },
      getDeptList(){
        getDepartments().then((res)=>{
          this.departments = res.data;
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
        })
      },
      getDcList(){
        const query = '?billStatus[neq]=1';
        getDeliveryChallan(query).then((res)=>{
          // this.dcList = res.data;
          // this.filteredDcList = res.data;
          res.data.forEach(e => {
            if(e.recdStatus == 1 && e.billStatus == '0'){
              this.dcList.push(e);
              this.filteredDcList.push(e);
            }
          });
          
          this.spinnerLoadingStatus = false;
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
          this.spinnerLoadingStatus = false;
        });
      },
      filterDeliveryChallan(){
        this.spinnerLoadingStatus = true;
        this.filteredDcList = [];
        this.selectedDcs = [];
        this.billItems = [];
        this.deptPoValue = 0;
        this.totalExGst = 0;
        this.totalBillGst = 0;
        this.dueAmt = 0;
        this.grandTotal = 0;
        this.deptBalance = 0;
        this.prevBillTotal = 0;
        
        this.dcList.every(e => {
          if(e.deptPoId == this.selectedDeptPo.id){
            this.filteredDcList.push(e);
          }
          return true;
        });

        this.prevBillTotal = 0;

        this.spinnerLoadingStatus = true;
        fetchPrevBill({id:this.selectedDeptPo.id}).then((res)=>{

          this.prevBills = res.data.data;
            if(this.prevBills.length > 0){
              this.prevBills.forEach(e => {
                this.prevBillTotal += e.grand_total;
              });
            }

          // this.deptBalance = this.deptPoValue - this.prevBillTotal;
          this.udpateGrandTotal();
          this.spinnerLoadingStatus = false;
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
          this.spinnerLoadingStatus = false;
        }) 
        

        this.blockUi = false;
        this.deptPoValue = this.selectedDeptPo.po_value;
        this.spinnerLoadingStatus = false;        
      },
      clearFilter(){
        this.filteredDcList = [];
        this.filteredDcList = this.dcList;
        this.selectedDept = null;
        this.selectedDeptPo = null;
        this.selectedDcs = [];
        this.billItems = [];
        this.deptPoValue = 0;
        this.totalExGst = 0;
        this.totalBillGst = 0;
        this.grandTotal = 0;
        this.adjustmentValue = 0;
        this.dueAmt = 0;
        this.billDate = null;
        this.blockUi = true;
        this.deptBalance = 0;
        this.prevBillTotal = 0;
      },
      addBillItem(event){

        event.data.dcItems.forEach(e => {
            this.billItems.push({
              ...e,
              subTotal:0,
              gstAmt:0,
              });
        });

        this.selectedDcList = [];
        this.selectedDcs.forEach((e) => {
          this.selectedDcList.push({
            id:e.id,
            // no:e.dcNo,
            // date:e.dcDate,
          });

        });
      },
      addAllItems(){
        
        this.selectedDcList = [];

        this.filteredDcList.forEach((e) => {
            e.dcItems.forEach(l => {
              this.billItems.push({
                ...l,
                subTotal:0,
                gstAmt:0,
              });
            });

            this.selectedDcList.push({
              id:e.id,
              // no:e.dcNo,
              // date:e.dcDate,
            });

        });
      },
      bindData(itemId, itemData){
        const item = this.billItems.find((e) => e.id == itemId);
        item.qty = itemData.qty;
        item.subTotal = itemData.subTotal;
        item.gstAmt = itemData.gstAmt;
        this.getTotalValue();
      },
      getTotalValue(){
        this.totalBillGst= 0;
        this.totalExGst = 0;
        this.billItems.forEach(e => {
          this.totalBillGst += e.gstAmt;
          this.totalExGst += e.subTotal;
        });
        this.udpateGrandTotal();
      },
      updateAdjustValue(event){
        this.adjustmentValue = event.value;
        this.udpateGrandTotal();
      },
      udpateGrandTotal(){
        this.grandTotal = 0;
        this.grandTotal = this.totalExGst + this.totalBillGst + this.adjustmentValue;
        this.deptBalance = this.deptPoValue - (this.prevBillTotal + this.grandTotal)
        this.dueAmt = this.deptPoValue - this.grandTotal;
      },
      removeBillItem(event){
        event.data.dcItems.forEach(e => {
          const index = this.billItems.findIndex((el)=>el.id ==e.id);
                this.totalExGst -= this.billItems[index].subTotal;
                this.totalBillGst -= this.billItems[index].gstAmt;
                this.billItems.splice(index, 1);
        });
        this.udpateGrandTotal();
      },
      removeAllItems(){
        this.billItems = [];
        this.selectedDcs = [];
        this.clearFilter();
      },
      handleSaveBill(){

        if(this.billDate == null){
          return this.showErrorToast({'message':'Please choose billing date.'});
        }
        if(this.billItems.length == 0){
          return this.showErrorToast({'message':'Please select challan to add items to bill.'});
        }
        if(this.selectedDept == null){
          return this.showErrorToast({'message':'Please choose department to bill against.'});
        }

        const data = {
          'billNo':this.billNo,
          'deptPo':this.selectedDeptPo.id,
          'dept':this.selectedDept.id,
          'billDate':this.billDate.getFullYear()+'-'+(this.billDate.getMonth()+1)+'-'+this.billDate.getDate(),
          'challans':this.selectedDcList,
          'billItems':this.billItems,
          'exGstTotal':this.totalExGst,
          'totalGst':this.totalBillGst,
          'totalIgst':this.totalIgst,
          'grandTotal':this.grandTotal,
          'adjustment':this.adjustmentValue,  
          'adjRemark':null,     
        }

        this.spinnerLoadingStatus = true;
        createBill(data).then((res)=>{
          this.showSuccessToast(res.data);
          this.spinnerLoadingStatus = false;
          this.$router.push('/bill-list');
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
          this.spinnerLoadingStatus= false;
        });

      },
      formatCurrency(value) {
            return value.toLocaleString('en-IN');
      },
      showSuccessToast(data){
          this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 5000 });
      },   
      showErrorToast(error){
              this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 5000 });
      },
    },
    mounted(){
      this.getDeptPo();
      // this.getDcList();
    }

}
</script>

<style >

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}

</style>