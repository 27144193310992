<template>
<Navbar/>
<SideBar/>

<div class="card main-content">
        <div class="progress-spinner" v-if="spinnerLoadingStatus">
            <ProgressSpinner></ProgressSpinner>
        </div>
        <div class="surface-200">
          <Card>
            <template #title>
                    <div class="flex justify-content-between flex-wrap">
                        <div class="flex align-items-center justify-content-center">
                            Store Stock
                        </div>
                        <div class="flex align-items-center justify-content-center">
                            <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                        </div>
                    </div>
                </template>
                <template #content>

                  <DataTable :value="stockArr" :size="small" :loading="tableLoading" v-model:filters="filters" tableStyle="min-width: 50rem" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                        currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id"
                        @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" >
                      
                          <Column header="Sl No." style="width:5%;">
                            <template #body="slotProps">
                                          {{ ++slotProps.index }}
                                      </template>
                          </Column>
                          <Column field="name" header="Item" />
                          <Column field="packing" header="Packing" />
                          <Column field="store" header="Store" />
                          <Column field="quantity" header="Quantity" />

                  </DataTable>
                </template>
          </Card>
        </div>
</div>
  
</template>

<script>
import { storeStock } from '@/services/apiService'
import Navbar from '../header/headerNavbar.vue'
import ProgressSpinner from 'primevue/progressspinner'
import Card from 'primevue/card'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import { FilterMatchMode } from 'primevue/api'
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
  components:{
    Navbar,
    SideBar,
      ProgressSpinner,
      Card,
    DataTable,
    Column,
    InputText,
    },

    data(){
      return{
        stockArr:[],
        spinnerLoadingStatus:false,
        expandedRows:[],
        filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                name: { value: null, matchMode: FilterMatchMode.IN },
            },
      }
    },

    methods:{
      getStoreStock(){
        this.spinnerLoadingStatus = true;
        storeStock().then((res)=>{
          this.stockArr = res.data;
        this.spinnerLoadingStatus = false;
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
        })
      },
      showErrorToast(error){
          this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
      },
      showSuccessToast(data){
          this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
      },
    },

    mounted(){
      this.getStoreStock();
    }
}
</script>

<style scoped>

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}

</style>