<template>
  <Navbar/>
  <SideBar/>

            <div class="card main-content">
                <div class="progress-spinner" v-if="spinnerLoadingStatus">
                        <ProgressSpinner></ProgressSpinner>
                    </div>
                <div class="surface-ground">
                <Card>
                    <template #content>
                    <DataTable :value="filteredOemPo" size="small" :loading="loadingStatus" v-model:filters="filters" tableStyle="min-width: 50rem" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                            currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id"
                            @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" >
                        <template #header >
                            <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                                <span class="text-xl text-900 font-bold">OEM PO <Tag value="APPROVED" severity="success" class="mx-3"/> </span>
                                <div class="flex justify-content-end">
                                    <!-- <i class="pi pi-search" /> -->
                                    <!-- <InputText v-model="filters['global'].value" placeholder="Keyword Search" /> -->
                                    <InputText v-model="filterQuery" placeholder="Keyword Search" size="small"/>
                                    <Button class="ml-2" icon="pi pi-filter" severity="success" size="small" @click="globalFilter" v-tooltip.top="'Filter'"/>
                                    <Button class="ml-2" icon="pi pi-filter-slash" size="small" outlined severity="success" @click="clearFilter" v-tooltip.top="'Clear Filter'"/>
                                </div>
                                
                            </div>
                        </template>

                            <Column expander style="width: 5rem"/>
                            <Column field="po_no" header="PO No.">
                                <template #body="slotProps">
                                    <router-link @click="poDoc(slotProps.data)" to="/oem-po-pdf" >{{slotProps.data.po_no}}</router-link>
                                    <!-- <router-link @click="poDoc(slotProps.data)" >{{slotProps.data.po_no}}</router-link> -->
                                    <!-- <Button @click="poDoc(slotProps.data)" link>
                                        {{slotProps.data.po_no}}
                                    </Button> -->
                                </template>
                            </Column>
                            <Column field="dept_po_ref" header="PO Ref">
                                <template #body="slotProps">
                                    <label v-for="(po, index) in slotProps.data.dept_po_ref" :key="index">
                                        <div class="py-1">{{po}} </div>
                                    </label>
                                </template>
                            </Column>
                            <Column field="manufacturer.name" header="To"></Column>
                            <Column field="po_date" header="PO Date" style="width:10%"></Column>
                            <Column field="total_value" header="PO Value">
                                <template #body="slotProps">
                                    {{formatCurrency(slotProps.data.total_value)}}
                                </template>
                            </Column>
                            <Column header="Terms & Conditions">
                                <template #body="slotProps">
                                    <Inplace :closable="true" closeicon>
                                        <template #display>
                                            {{ 'Show' }}
                                        </template>
                                        <template #content>
                                            <p>{{ slotProps.data.terms_and_contions }}</p> 
                                        </template>

                                    </Inplace>
                                </template>
                            </Column>
                            <Column header="PI Status" style="width:12%">
                                <template #body="slotProps" >
                                    <Tag class="px-4" v-if="slotProps.data.pi_status=='1' || slotProps.data.pi_status==1" :value="getStatus(slotProps.data)" :severity="getSeverity(slotProps.data)" /> <br>
                                    <Button class="mt-1 py-1 px-4" v-if="slotProps.data.pi_status!=1" size="small" label="Update PI"  severity="help" raised @click="showConfirmDialog(slotProps.data)"/>

                                </template>
                            </Column>
                            <Column header="Order Status">
                                <template #body="slotProps">
                                    <Tag  class="px-4" :value="getOrderStatus(slotProps.data)" :severity="getOrderSeverity(slotProps.data)" /> <br>
                                    <label v-if="slotProps.data.order_status==1" style="font-size:0.8em;">By <strong>{{slotProps.data.confirmed_by.user}}</strong></label>
                                    <div v-if="slotProps.data.pi_status!=0 && slotProps.data.order_status!=1">
                                        <Button class="px-3 py-1 mt-1" label="Confirm" severity="success" size="small" @click="showConfirmOrderDialog(slotProps.data)"/>
                                    </div>
                                    
                                </template>
                            </Column>
                            <!-- <Column header="Remarks" >
                                <template #body="slotProps">
                                    
                                    <div class="flex">
                                        <Tag v-if="slotProps.data.pi_status==0" value="PI Pending" severity="warning"/>
                                        <Button v-if="slotProps.data.remarks.length>0" size="small" class="mx-1 px-3 py-2" label="Show" severity="success" @click="showRemarks(slotProps.data.remarks)"/>
                                        <label for="" v-if="slotProps.data.pi_status==1">

                                        <Button v-if="slotProps.data.order_status==0" class="mx-1 px-3 py-2" label="Add" severity="info" size="small" @click="showRemarksDialog(slotProps.data)"/>
                                        
                                    </label>
                                    </div>                    
                                </template>
                            </Column> -->

                            <Column header="Payment Status">
                                <template #body="slotProps">
                                    <Tag v-if="slotProps.data.order_status==0" value="Order not confirmed" severity="warning"/>
                                    <!-- <Button v-if="slotProps.data.order_status!=0 && slotProps.data.payment_status!=1" class="mx-1 px-3 py-1" label="Make Payment" size="small" @click="showPaymentDialog($event, slotProps.data)"/> -->
                                    <Button v-if="slotProps.data.order_status!=0 && slotProps.data.payment_status!=1" class="mx-1 px-3 py-1" label="Make Payment" size="small" @click="showNewPyamentDialog(slotProps.data)"/>

                                    

                                    <Button v-if="slotProps.data.payment_status==1" size="small" class="mx-1 px-3 py-2" label="Payments" severity="success" @click="showPaymentsList(slotProps.data)"/>

                                        <OverlayPanel class="overlay-panel-bg" ref="op" dismissable>
                                                <h3>New Payment</h3>
                                                <div class="field grid">
                                                    <label class="col-fixed" style="width:100px">PO No:</label>
                                                    <div class="col text-indigo-500 font-medium">
                                                        <label><strong>{{oemPoNo}}</strong></label>
                                                    </div>
                                                </div>

                                                <div class="field grid">
                                                    <label class="col-fixed" style="width:100px">PO Value:</label>
                                                    <div class="col text-green-600 font-medium">
                                                        <label><strong>&#x20B9; {{oemPoValue}}</strong></label>
                                                    </div>
                                                </div>

                                                <div class="field grid">
                                                    <label class="col-fixed" style="width:100px">Due:</label>
                                                    <div class="col text-red-500 font-medium">
                                                        <label><strong>&#x20B9; {{poDueAmt}}</strong></label>
                                                    </div>
                                                </div>
                                                <div class="field grid" >
                                                    <label class="col-fixed" style="width:100px">Paid:</label>
                                                    <div>
                                                        <label class="text-green-600" v-for="(item, index) in poData.payments" :key="index">&#x20B9;{{item.amount}} &nbsp;&nbsp;&nbsp;&nbsp; {{item.date}}<br></label>
                                                    </div>
                                                </div>

                                                <div class="field grid">
                                                    <label class="col-fixed" style="width:100px">Amount:</label>
                                                    <div class="col">
                                                        <InputNumber v-model="toPayAmt" class="w-full" @input="updatePoDue($event)" :minFractionDigits="2" :maxFractionDigits="3"/>
                                                    </div>
                                                </div>

                                                <div class="field grid">
                                                    <label class="col-fixed" style="width:100px">Date:</label>
                                                    <div class="col">
                                                        <Calendar v-model="paymentDate" class="w-full" dateFormat="dd/mm/yy"/>
                                                    </div>
                                                </div>

                                                <div class="flex justify-content-end flex-wrap">
                                                    <Button label="Save" icon="pi pi-save" severity="success" size="small" class="mr-2" @click="savePayment"/>
                                                </div>
                                            <div></div>
                                        </OverlayPanel>

                                </template>
                            </Column>

                            <Column header="Approved">
                                <template #body="slotProps">
                                    <label v-if="slotProps.data.approved_by != null" style="font-size:0.8em;">By <strong>{{slotProps.data.approved_by.user}}</strong></label>
                                </template>
                            </Column>
                            <template #expansion="slotProps">
                                <div class="p-3">
                                    
                                    <div class="flex flex-wrap align-items-center justify-content-between ">
                                        <span>
                                            <h3>
                                            OEM PO items: 
                                            </h3>
                                        </span> 
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search" />
                                            <InputText v-model="filter2['global'].value" placeholder="Keyword Search" size="small"/>
                                        </span>
                                    </div>

                                    <DataTable :value="slotProps.data.oem_po_details" size="small" v-model:filters="filter2">
                                        <Column field="item_name" header="Item"></Column>
                                        <Column field="add_on_data" header="Cat/Part-No">
                                            <template #body="slotProps">
                                                {{slotProps.data.add_on_data}}
                                            </template>
                                        </Column>
                                        <Column field="packing" header="UOM"></Column>
                                        <Column field="nop" header="No. of Pkgs"></Column>
                                        <Column field="pckSize" header="Pkg Size"></Column>
                                        <Column field="quantity" header="Quantity"></Column>
                                        <Column field="rate" header="Rate">
                                            <template #body="slotProps">
                                                {{ formatCurrency(slotProps.data.rate) }}
                                            </template>
                                        </Column>
                                        <Column field="gst" header="Gst"></Column>
                                        <Column field="sub_total" header="Sub Total" >
                                            <template #body="slotProps">
                                                {{ formatCurrency(slotProps.data.sub_total) }}
                                            </template>
                                        </Column>
                                        <Column header="Delivery To">
                                            <template #body="slotProps">
                                                <span v-if="slotProps.data.location_status==1">
                                                    <Button label="Show" size="small" icon="pi pi-external-link" @click="showLocations(slotProps.data)" />
                                                </span>
                                            </template>
                                        </Column>
                                        <Column header="Status">
                                            <template #body="slotProps">
                                                <div v-if="slotProps.data.status.length>0">
                                                    <Button  class="py-2 px-2" severity="success" icon="pi pi-window-maximize" label="Show" @click="trialStatusCheck(slotProps.data.status, slotProps)"/>
                                                </div>
                                                <div v-if="slotProps.data.status.length==0">
                                                    <h4>N/A</h4>
                                                </div>
                                            </template>
                                        </Column>
                                        <Column v-if="slotProps.data.complete==1" header="Arrived/Pending">
                                            <template #body="slotProps">
                                                <span>
                                                    <label class="text-green-500"> {{getArrivedQty(slotProps.data.arrived)}}</label>/ <label class="text-red-500"> {{getPendingQty(slotProps.data.quantity, slotProps.data.arrived)}}</label> 
                                                </span>
                                            </template>
                                        </Column>   
                                        <Column v-if="slotProps.data.order_status!=0 && slotProps.data.complete==1" header="Dispatch">
                                            <template #body="slotProps">

                                                <span v-if="slotProps.data.dispatch_status==0 && slotProps.data.confirmed==1">
                                                    <div class="flex align-items-center">
                                                        <img :alt="Dispatch" src="/assets/icons/delivery-truck.png" style="width: 22px" />
                                                        <Button class="pl-2" link label="Dispatch" @click="showDispatchDialog(slotProps)"/>
                                                    </div>
                                                </span > 
                                                <span v-if="slotProps.data.dispatch_status==1">
                                                    <Tag value="Fully dispatched" severity="success"/>
                                                </span>
                                            </template>
                                        </Column>
                                        
                                    </DataTable>

                                    <!-- Delivery location dilog -->
                                    <Dialog :visible="dialogVisibility" modal :closable="false" :header="dialogHeader" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                                                        
                                        <DataTable :value="itemDeliveryLocations"  tableStyle="min-width: 30rem">
                                            <Column field="location" header="Location"></Column>
                                            <Column field="quantity" header="Quantity"></Column>
                                        </DataTable>
                                        <div class="flex justify-content-end flex-wrap">
                                        <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" severity="danger" @click="hideDialog"></Button>
                                        </div>
                                    </Dialog>
                                    <!-- end delivery location dilog -->

                                    <!-- show item status dialog start-->
                                    <Dialog :visible="itemStatusListDialog" header="Status details" modal :closable="false" :style="{ width: '40rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                                        <div class="field grid">
                                            <label class="col-fixed" style="width:100px"><strong>Status:</strong></label>
                                            <div class="col text-green-600">
                                                <strong>{{itemSetStatus}}</strong>
                                            </div>
                                        </div>
                                        <div class="field grid">
                                            <label class="col-fixed" style="width:100px"><strong>Date:</strong></label>
                                            <div class="col">
                                                <strong>{{itemSetStatusDate}}</strong>
                                            </div>
                                        </div>
                                        <div class="field grid">
                                            <label class="col-fixed" style="width:100px"><strong>Remarks:</strong></label>
                                            <div class="col">
                                                {{itemSetStatusRemarks}}
                                            </div>
                                        </div>
                                        <template #footer>
                                            <div class="flex justify-content-end flex-wrap">
                                                <Button class="mx-1 px-2 py-2" size="small" label="Close" severity="danger"  @click="itemStatusListDialog=false"></Button>
                                            </div>
                                        </template>
                                    </Dialog>
                                    <!-- show item status dialog end -->
                                    
                                    <!-- stock dialog start -->
                                    <Dialog :visible="itemStockDialogVisibility" :header="stockArrivalHead" modal :closable="false" position="top" :style="{ width: '40rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

                                        <div class="formgrid grid">
                                            <div class="field col">
                                                <strong class="w-full">Total Quantity</strong>
                                                <InputNumber class="w-full" v-model="stockyQty" readonly/>
                                            </div>
                                            <div class="field col">
                                                <strong class="w-full">Pending</strong>
                                                <InputNumber class="w-full" v-model="stockPending" readonly/>
                                            </div>
                                        </div>

                                        <div class="formgrid grid">
                                            <div class="field col">
                                                <strong class="w-full">Received</strong>
                                                <InputNumber class="w-full" v-model="stockRecd" @input="updatePendingStock($event)" readonly/>
                                            </div>
                                            <div class="field col">
                                                <strong class="w-full">Date</strong>
                                                <Calendar class="w-full" v-model="stockArrivedDate" dateFormat="dd/mm/yy"/>
                                            </div>
                                        </div>

                                        <div class="formgrid grid">
                                            <div class="field col">
                                                <strong class="w-full">Received at</strong>
                                                <Dropdown class="w-full" v-model="selectedStore" optionLabel="location" optionValue="id" :options="storeArr"/>
                                            </div>
                                        </div>

                                        <template #footer>
                                            <div class="flex justify-content-end flex-wrap">
                                                <Button class="mx-1 px-2 py-2" size="small" label="Confirm" severity="success"  @click="confirmStockArrival"></Button>
                                                <Button class="mx-1 px-2 py-2" size="small" label="Close" severity="danger"  @click="hideStockDialog"></Button>
                                            </div>
                                        </template>
                                    </Dialog>
                                    <!-- stock dialog end -->

                                    <!-- item dispatch status update dialog start -->
                                    <Dialog :visible="itemDispatchDialogVisibility" id="itemStatusDialog" header="Dispatch" modal :closable="false" :style="{ width: '40rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">


                                        <div class="formgrid grid">
                                            <div class="field col">
                                                <strong class="w-full">Total Quantity</strong>
                                                <InputNumber class="w-full" v-model="stockyQty" readonly/>
                                            </div>
                                            <div class="field col">
                                                <strong class="w-full">Pending Dispatch</strong>
                                                <InputNumber class="w-full" v-model="stockPendingDispatch" readonly/>
                                            </div>
                                        </div>

                                        <div class="formgrid grid">
                                            <div class="field col">
                                                <strong class="w-full">Dispatched</strong>
                                                <InputNumber class="w-full" v-model="stockDispatched" @input="updateDispatchStock($event)" />
                                            </div>
                                            <div class="field col">
                                                <strong class="w-full">Date</strong>
                                                <Calendar class="w-full" v-model="stockDispatcDate" dateFormat="dd/mm/yy"/>
                                            </div>
                                        </div>

                                        <div class="field">
                                            <label ><strong>Remarks:</strong></label>
                                            <Textarea v-model="itemDispatchRemark" class="w-full" rows="5"/>
                                        </div>
                                        
                                        <template #footer>
                                            <div class="flex justify-content-end flex-wrap">
                                                <Button class="mx-1 px-2 py-2" size="small" label="Save" severity="success"  @click="updateDispatchStatus"></Button>
                                                <Button class="mx-1 px-2 py-2" size="small" label="Cancel" severity="danger"  @click="hideDispatchDialog"></Button>
                                            </div>
                                        </template>
                                    </Dialog>
                                    <!-- item status update dialog end -->

                                    <!-- start dispatch status dialog -->
                                    <Dialog :visible="dispatchDialogVisibility" modal :closable="false" :header="dispatchStatusHeader" position="top" :style="{ width: '40rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

                                                <Panel v-for="(item, index) in dispatchStatusArr" :key="index" class="mb-2">
                                                    <template #header>
                                                        <div class="flex flex-wrap align-items-center justify-content-between gap-2 m-0">
                                                            <span class="font-bold">
                                                                <img :alt="itemStatus[item.status-1].name" :src="`/assets/icons/${itemStatus[item.status-1].icon}`" style="width: 22px" />
                                                                {{item.statusLabel}}
                                                            </span>
                                                            
                                                        </div>
                                                    </template>
                                                    <template #icons>
                                                        
                                                        <span class="font-bold">
                                                            <Button v-if="item.status==1" severity="help" raised class="px-2 py-1" v-tooltip.top="'Change status to In-Transit'" @click="showInTransitPanel($event, item)"> {{itemStatus[item.status].name}} <img class="pl-2" :alt="itemStatus[item.status].name" :src="`/assets/icons/${itemStatus[item.status].icon}`" style="width: 22px" /></Button>
                                                            <Button v-if="item.status==2" severity="success" raised class="px-2 py-1" v-tooltip.top="'Receive Stock'" @click="showRecdPanel(item)"> {{itemStatus[item.status].name}} <img class="pl-2" :alt="itemStatus[item.status].name" :src="`/assets/icons/${itemStatus[item.status].icon}`" style="width: 22px" /></Button>
                                                        </span>
                                                    </template>

                                                        <div class="mb-2">
                                                            <strong>Date: <label class="text-indigo-600"> {{item.date}} </label></strong><br>
                                                        </div>
                                                        <div class="mb-2">
                                                            <strong>Quantity: <label class="text-green-500"> {{item.quantity}} </label></strong><br>
                                                        </div>
                                                        <div>
                                                            <strong>Remarks:</strong> {{item.remarks}} 
                                                        </div>

                                                    <!-- <template #footer>
                                                        <div class="flex flex-wrap justify-content-end gap-3"> -->
                                                        <!-- <div class="flex flex-wrap align-items-center justify-content-between gap-3"> -->
                                                            <!-- <div class="flex align-items-center gap-2">
                                                                <strong>Total Quantity:</strong>{{itemTotalQty}}
                                                            </div> -->
                                                            <!-- <span class="p-text-secondary"><strong>Date:</strong> {{item.date}}</span>
                                                        </div>
                                                    </template> -->


                                                </Panel>

                                                    <OverlayPanel ref="op2" dismissable>
                                                        <div class="field grid">
                                                            <label class="col-fixed" style="width:100px">Date:</label>
                                                            <div class="col font-medium">
                                                                <Calendar v-model="inTransitDate" dateFormat="dd/mm/yy" showIcon />
                                                            </div>
                                                        </div>
                                                        <div class="field grid">
                                                            <label class="col-fixed" style="width:100px">Remarks:</label>
                                                            <div class="col font-medium">
                                                                <Textarea class="w-full" v-model="inTransitRemark" rows="3"/>
                                                            </div>
                                                        </div>
                                                        <div class="flex justify-content-end flex-wrap">
                                                            <Button label="Confirm" icon="pi pi-save" severity="success" size="small" @click="saveInTransit"/>
                                                        </div>
                                                    </OverlayPanel>

                                        <template #footer>
                                            <div class="flex justify-content-between flex-wrap">
                                                <label > Total Quantity: <strong>{{itemTotalQty}} </strong></label>
                                                <span><label > Total Dispatched: </label><label :class="qtyClass"><strong>{{totalDispatchedQty}}</strong></label></span>
                                                <Button class="mx-1 px-2 py-2" size="small" label="Close" severity="danger"  @click="dispatchDialogVisibility=false"></Button>
                                            </div>
                                        </template>
                                    </Dialog>

                                    <!-- end dispatch status dialog -->
                                </div>
                            </template>                                    
                            <template #footer> In total there are {{ oemPos ? oemPos.length : 0 }} POs. </template>

                        <!-- </template> -->
                        
                    </DataTable>

                                    <!-- remark list dialog start-->
                                    <Dialog :visible="remarkDialogVisible" modal :closable="false" header="Remarks" :style="{ width: '40rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                                                        
                                        <DataTable :value="remarkArr"  tableStyle="min-width: 30rem">
                                            <Column field="date" header="Date" style="width:25%"></Column>
                                            <Column field="remarks" header="Remark"></Column>
                                        </DataTable>
                                        <div class="flex justify-content-end flex-wrap">
                                        <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" severity="danger" @click="hideRemarksList"></Button>
                                        </div>
                                    </Dialog>
                                    <!-- remark list dialog end -->

                                    <!-- PI receival status confirmation -->
                                <Dialog :visible="confirmDialog" id="confirmDialog" modal header="PI Receival Confirmation" :closable="false" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" >

                                    <DataTable :value="poItems"  tableStyle="min-width: 30rem" size="small" v-model:selection="selectedPoItem">
                                        <Column selectionMode="multiple" headerStyle="width: 3rem" ></Column>
                                        <Column field="item_name" header="Item" style="width:25%"></Column>
                                        <Column field="packing" header="Packing"></Column>
                                        <Column field="nop" header="NOPs"></Column>
                                        <Column field="pckSize" header="Pck Size"></Column>
                                        <Column field="quantity" header="Quantity"></Column>
                                        <Column field="rate" header="Rate"></Column>
                                        <Column field="gst" header="GST(%)"></Column>
                                        <Column field="sub_total" header="Sub Total"></Column>                       
                                        
                                    </DataTable>

                                    <template #footer>
                                        <div class="flex justify-content-end flex-wrap">
                                            <Button class="mx-1 px-2 py-2" size="small" label="Confirm" severity="success"  @click="confirmPiStatus"></Button>
                                            <Button class="mx-1 px-2 py-2" size="small" label="Cancel" severity="danger"  @click="hideConfirmDialog"></Button>
                                        </div>
                                    </template>
                                </Dialog>
                                <!-- PI receival status end -->

                            <!-- remark list dialog start-->
                            <Dialog :visible="confirmOrderDialog" modal :closable="false" :style="{ width: '60rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

                                <DataTable :value="poItems"  tableStyle="min-width: 30rem" size="small" v-model:selection="selectedPoItem" v-model:filters="filter3">
                                    <template #header >
                                        <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                                            <div>
                                                <span class="text-xl text-900 font-bold">Confirm Items Order</span>

                                            </div>
                                            <div class="flex justify-content-end">
                                                <Calendar class="mx-2 w-full px-2" v-model="confirmationDate" dateFormat="dd/mm/yy" showIcon inputId="buttondisplay" size="small" placeholder="Confirmation Date"/>  
                                                <InputText v-model="filter3['global'].value" placeholder="Keyword Search" size="small"/>
                                            </div>
                                            
                                        </div>
                                    </template>

                                    <Column selectionMode="multiple" headerStyle="width: 3rem" ></Column>
                                    <Column field="item_name" header="Item" style="width:25%"></Column>
                                    <Column field="packing" header="Packing"></Column>
                                    <Column field="nop" header="NOPs"></Column>
                                    <Column field="pckSize" header="Pck Size"></Column>
                                    <Column field="quantity" header="Quantity"></Column>
                                    <Column field="rate" header="Rate"></Column>
                                    <Column field="gst" header="GST(%)"></Column>
                                    <Column field="sub_total" header="Sub Total"></Column>                       
                                    
                                </DataTable>


                                <!-- <div class="flex">
                                    <label for=""><strong> Confirmed on date:</strong></label>               
                                    <Calendar class="mx-2 w-full px-2" v-model="confirmationDate" dateFormat="dd/mm/yy" showIcon inputId="buttondisplay" />                                
                                </div> -->
                                    <div class="flex justify-content-end flex-wrap">
                                    <Button class="flex align-items-center justify-content-center my-1 mx-1" size="small" label="Confirm" severity="success" @click="confirmOrder"></Button>
                                <Button class="flex align-items-center justify-content-center my-1" size="small" label="Cancel" severity="danger" @click="hideConfirmOrderDialog"></Button>
                                </div>
                            </Dialog>
                            <!-- remark list dialog end -->

                            <!-- payments list dialog start -->
                            <Dialog :visible="paymentsDialogVisibility" modal :closable="false" :header="paymentListHeader" :style="{ width: '40rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

                                <DataTable :value="paymentData"  tableStyle="min-width: 30rem">
                                    <Column header="Amount">
                                        <template #body="slotProps">
                                            &#x20B9;{{slotProps.data.amount}}
                                        </template>
                                    </Column>
                                    <Column field="date" header="Paid on"></Column>
                                </DataTable>
                                <template #footer>
                                        <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                                            <span>
                                                <strong>PO Value: &nbsp;&#x20B9;<label class="text-green-600">{{oemPoValue}}</label></strong>
                                            </span>
                                            <span>
                                                <Button class="mx-1 px-2 py-2" size="small" label="Close" severity="danger"  @click="hidePaymentsList"></Button>
                                            </span>
                                        </div>
                                    </template>
                            </Dialog>
                            <!-- payments list dialog end -->

                            <!-- add remarks dialog start -->
                            <Dialog :visible="remarksDialog" modal :closable="false" header="Add Remarks" :style="{ width: '35rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

                                <PiRemarks :oemPo="oemPoData" :oemPoId="oemPoData.id" @bind-item="bindData"/>   
                                    
                                <div class="flex justify-content-end flex-wrap">
                                    <Button class="flex align-items-center justify-content-center my-2 mx-1" size="small" label="Save" severity="success" @click="saveRemarks"></Button>
                                <Button class="flex align-items-center justify-content-center my-2" size="small" label="Close" severity="danger" @click="hideRemarksDialog"></Button>
                                </div>
                            </Dialog>
                            <!-- add remarks dialog end -->

                            <!-- item wise payment dialog -->
                            <Dialog :visible="newPaymentDialogVisibility" id="newPaymentDialog" modal header="PO Payment" :closable="false" :style="{ width: '60rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" >


                                    <div class="formgrid grid">
                                        <div class="field col">
                                            <div class="field grid">
                                                <!-- <div class="col-4">
                                                    <label for="firstname3" >PO Value:</label>
                                                </div>
                                                <div class="col-8">
                                                    <Chip :label="oemPoValue" /> 
                                                </div> -->

                                                <div class="col-4">
                                                    <label for="firstname3" ><strong>PO Value:</strong></label>
                                                </div>
                                                <div class="col">
                                                    <Chip :label="oemPoValue" >
                                                        {{formatCurrency(oemPoValue)}}
                                                    </Chip> 
                                                </div>
                                            </div>
                                            <div class="field grid">

                                                <div class="col-4">
                                                    <label for="firstname3" ><strong>Items Value:</strong></label>
                                                </div>
                                                <div class="col">
                                                    <Chip :label="selectedItemsValue" >
                                                        {{formatCurrency(selectedItemsValue)}}
                                                    </Chip> 
                                                </div>
                                            </div>
                                            <div class="field grid">

                                                <div class="col-4">
                                                    <label for="firstname3" ><strong>Due:</strong></label>
                                                </div>
                                                <div class="col">
                                                    <Chip :label="poDueAmt" >
                                                        {{formatCurrency(poDueAmt)}}
                                                    </Chip>
                                                </div>
                                            </div>
                                        </div>

                                        <Divider layout="vertical" />
                                        <div class="field col">
                                            <div class="field grid">
                                                <div class="col-3">
                                                    <label for="firstname3" ><strong>Date:</strong></label>
                                                </div>
                                                <div class="col">
                                                    <Calendar v-model="paymentDate" class="w-full" dateFormat="dd/mm/yy" placeholder="Select payment date"/>    
                                                </div>
                                            </div>
                                            <div class="field grid">
                                                <div class="col-3">
                                                    <label for="firstname3" ><strong>Amount:</strong></label>
                                                </div>
                                                <div class="col">
                                                    <InputNumber v-model="toPayAmt" class="w-full" @input="updateDueAmt($event)" :minFractionDigits="2" :maxFractionDigits="3" locale="en-IN"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <DataTable :value="poItems"  tableStyle="min-width: 30rem" size="small" v-model:selection="selectedPoItem" @row-select="updateAmount" @row-unselect="updateAmount" @row-select-all="updateAllSelectAmt" @row-unselect-all="updateUnselectAmt">
                                        <Column selectionMode="multiple" headerStyle="width: 3rem" ></Column>
                                        <Column field="item_name" header="Item" style="width:25%"></Column>
                                        <Column field="packing" header="Packing"></Column>
                                        <Column field="nop" header="NOPs"></Column>
                                        <Column field="pckSize" header="Pck Size"></Column>
                                        <Column field="quantity" header="Quantity"></Column>
                                        <Column field="rate" header="Rate"></Column>
                                        <Column field="gst" header="GST(%)"></Column>
                                        <Column field="paid_amount" header="Paid"></Column>
                                        <Column field="sub_total" header="Sub Total"></Column>                       
                                        <Column header="Total+GST">
                                        <template #body="slotProps">
                                            {{slotProps.data.sub_total + ((slotProps.data.sub_total/100)*slotProps.data.gst)}}
                                        </template>
                                        </Column>                       
                                        
                                    </DataTable>

                                    <template #footer>
                                        <div class="flex justify-content-end flex-wrap">
                                            <Button class="mx-1 px-2 py-2" size="small" label="Save" severity="success" @click="savePayment"></Button>
                                            <Button class="mx-1 px-2 py-2" size="small" label="Cancel" severity="danger"  @click="hideNewPyamentDialog"></Button>
                                        </div>
                                    </template>
                                </Dialog>
                            <!-- item wise payment dialog end -->

                            <!-- po pdf dialog -->
                                 <Dialog :visible="poPdfDialog" modal :closable="false" header="PO Document" :style="{ width: '60rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"> 

                                        <Card>
                                            <template #content>
                                                <OemPoDoc />
                                            </template>
                                        </Card>

                                        
                                    <div class="flex justify-content-end flex-wrap">                                        
                                        <Button class="flex align-items-center justify-content-center my-2" size="small" label="Close" severity="danger" @click="poPdfDialog=false"></Button>
                                    </div>
                                </Dialog>
                            <!-- po pdf dialog end -->

                    </template>
            </Card>
                </div>
            </div>
      
</template>

<script>
import Card from 'primevue/card'
import Navbar from '../header/headerNavbar.vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { getOemPoList, saveRemark, updatePIStatus, confirmOemPo, savePoPayment, oemItemStatusUpdate, updateStoreStock, getStores, updateOemItemDispatchStatus, updateInTransit } from '@/services/apiService'
import Tag from 'primevue/tag'
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import { useOemPoStore } from '../../stores/oemPo'
import Inplace from 'primevue/inplace'
import InputText from 'primevue/inputtext'
import { FilterMatchMode } from 'primevue/api'
import PiRemarks from '../oem/piRemarks.vue'
import Calendar from 'primevue/calendar'
import ProgressSpinner from 'primevue/progressspinner'
import OverlayPanel from 'primevue/overlaypanel'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import Textarea from 'primevue/textarea'
import Panel from 'primevue/panel'
import Chip from 'primevue/chip'
import Divider from 'primevue/divider'
import SideBar from '../sidebar/sideBarTwo.vue'
import OemPoDoc from '../oem/oemPoDoc.vue'

export default {
    
    components:{
        Card,
        Navbar,
        SideBar,
        DataTable,
        Column,
        Tag,
        Button,
        Dialog,
        Inplace,
        InputText,
        PiRemarks,
        Calendar,
        ProgressSpinner,
        OverlayPanel,
        InputNumber,
        Dropdown,
        Textarea,
            Panel,
            Chip,
        Divider,
        OemPoDoc,
},

data(){
    return{
        oemPos:null,
        filteredOemPo:[],
        loadingStatus:false,
        expandedRows:[],
        itemDeliveryLocations:[],
        dialogVisibility:false,
        dialogHeader:'Delivery location',
        oemPoData:null,
        targetTab:null,
        oemPoDocRoute:null,
         filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                po_no: { value: null, matchMode: FilterMatchMode.IN },
                'manufacturer.name':{ value: null, matchMode: FilterMatchMode.IN },
            },
            filter2: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                item_name: { value: null, matchMode: FilterMatchMode.IN },
                add_on_data: { value: null, matchMode: FilterMatchMode.IN },
            },
        filter3: {
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            item_name: { value: null, matchMode: FilterMatchMode.IN },
        },
        updateStatus:true,
        remarks:[],
        confirmDialog:false,
        poId:null,
        remarksDialog:false,
        remark:null,
        remarkDate:null,
        remarkDialogVisible:false,
        remarkArr:[],
        confirmOrderDialog:false,
        confirmationDate:null,
        confirmPoId:null,
        remarkPoId:null,
        addOnStatus:false,
        addOnHead:null,
        paymentsDialogVisibility:false,
        oemPoNo:null,
        oemPoValue:0,
        confirmLoadingStatus:false,
        confirmPoLoadingStatus:false,  
        paymentBy:null,  
        poDueAmt:0,  
        toPayAmt:0,  
        paymentDate:null,
        poData:null,
        paidAmt:[],
        dueAmount2:0,
        paymentData:[],
        paymentListHeader:null,
        selectedItemStatus:[],
        itemStatus:[
            { name:'Dispatched' , value:1, icon:'delivery-truck.png'},
            { name:'In-Transit' , value:2, icon:'in_transit.png'},
            { name:'Received' , value:3, icon:'received.png'},
        ],
        itemStatusDialogVisibility:false,
        itemIndex:null,
        itemName:null,
        selectedStatus:null,
        stockDispatcDate:null,
        itemDispatchRemark:null,
        itemIdStatus:null,
        itemArrivalStatus:false,
        itemStatusListDialog:false,
        itemSetStatusDate:null,
        itemSetStatus:null,
        itemSetStatusRemarks:null,
        itemStockDialogVisibility:false,
        stockArrivalHead:null,
        stockyQty:0,
        stockPending:0,
        stockRecd:0,
        stockArrived:0,
        stockArrivedDate:null,
        stockDue:0,
        selectedStore:null,
        deliveryLocations:[
            {name:'To Delivery location', id:1 },
            {name:'AMS Store, Bivar Road', id:2},
        ],
        itemStockId:null,
        rocItemId:null,
        nonRocItemId:null,
        storeArr:[],
        stockPendingDispatch:0,
        itemDispatchDialogVisibility:false,
        itemTransitDialogVisibility:false,
        dispatchedStock:0,
        dispatchStatusArr:[],
        dispatchDialogVisibility:false,
        dispatchStatusHeader:null,
        itemTotalQty:0,
        poItem:null,
        inTransitDate:null,
        itemData:null,
        inTransitRemark:null,
        totalDispatchedQty:0,
        qtyClass:null,
        itemStatusId:null,
        spinnerLoadingStatus:false,
        filterQuery:null,
        poItems:[],
        selectedPoItem:[],
        newPaymentDialogVisibility:false,
        selectedItemsValue:0,
        poPdfDialog:false,
    }
},

methods:{
    getOemPoList(){
        this.loadingStatus = true;
        const query = '?isApproved[eq]=1';
        getOemPoList(query).then((res)=>{
                    this.oemPos = res.data;
                    this.filteredOemPo = res.data;
                    this.loadingStatus = false;
            }).catch((error)=>this.showErrorToast(error.response.data));
    },
    formatCurrency(value) {
            return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
    },
    getStatus(po){
            switch (po.pi_status) {
                case 0:
                    return 'Pending'
                case 1:
                    return 'Received';
                default:
                    break;
            }
            if(po.pi_status==0)
                this.updateStatus = true;
        },
    getOrderStatus(po){
        switch (po.order_status) {
                case 0:
                    return 'Pending'
                case 1:
                    return 'Confirmed';
                case -1:
                    return 'Confirmed Partially'
                default:
                    break;
        }
    },
    getSeverity(po){
            switch (po.pi_status) {
                case 0:
                    return 'warning'
                case 1:
                    return 'success'
                default:
                    break;
            }
    },
    getOrderSeverity(po){
         switch (po.order_status) {
                case 0:
                    return 'warning'
                case 1:
                    return 'success'
                case -1:
                    return 'warning'
                default:
                    break;
            }
    },
    bindData(itemdata){
        this.remark = itemdata.remarks;
        this.remarkDate = itemdata.date;
    },
    confirmPiStatus(){

            if(this.selectedPoItem.length == 0){
                return this.showErrorToast({'message':'None of the items checked for PI confirmation'});
            }

        const data = {
            'id':this.poId,
            'selectedPiItems':this.selectedPoItem,
        }

        this.spinnerLoadingStatus = true;
        this.confirmDialog = false;
        updatePIStatus(data).then((res)=>{
            this.confirmDialog = false;
            this.showSuccessToast(res.data);
            this.spinnerLoadingStatus = false;
            this.getOemPoList();
        }).catch((errors)=>{
            this.showErrorToast(errors.response.data);
            this.spinnerLoadingStatus = false;
        });
        this.selectedPoItem = [];
    },
    showLocations(data){
        this.dialogHeader = data.item_name;
        this.dialogVisibility = true;
        this.itemDeliveryLocations = data.location;
    },
    hideDialog(){
        this.dialogVisibility = false;
    },
    showConfirmDialog(data){
        
        this.poId = null,
        this.poId = data.id;

        this.poItems = [];

        data.oem_po_details.forEach(e => {
            if(e.pi_status == 0){
                this.poItems.push(e);
            }
        });
        this.confirmDialog = true;
    },
    hideConfirmDialog(){
        this.confirmDialog = false;
        this.selectedPoItem = [];
    },
    showRemarksDialog(data){
        this.oemPoData = data;
        this.remarksDialog = true;
        this.remarkPoId = data.id;
    },
    showRemarks(data){
        this.remarkDialogVisible = true;
        this.remarkArr = data;
    }, 
    hideRemarksList(){
        this.remarkDialogVisible = false;
    }, 
    hideRemarksDialog(){
        this.remarksDialog = false;
    },
    confirmOrder(){

        if(this.confirmationDate==null){
            return this.showErrorToast({'message':'Please select confirmation date!'});
        }

        if(this.selectedPoItem.length == 0){
            return this.showErrorToast({'message':'Please select at least one item to be confirmed!'});
        }

        const data = {
            'id':this.confirmPoId,
            'date': this.confirmationDate.getDate()+'-'+(this.confirmationDate.getMonth()+1)+'-'+this.confirmationDate.getFullYear(),
            'selectedPoItems':this.selectedPoItem,
        }
        this.spinnerLoadingStatus = true;
        this.confirmOrderDialog = false;
        this.confirmationDate = null;
        confirmOemPo(data).then((res)=>{
            this.showSuccessToast(res.data);
            this.spinnerLoadingStatus = false;
            this.hideConfirmOrderDialog();
            this.getOemPoList();
        }).catch((error)=>{
            this.showErrorToast(error.response.data);
            this.spinnerLoadingStatus = false;
        });
        this.selectedPoItem = [];
    },
    showConfirmOrderDialog(data){
        this.confirmOrderDialog = true;

        this.poItems = [];
        data.oem_po_details.forEach(e => {
            if(e.pi_status == 1 && e.confirmed == 0){
                this.poItems.push(e);
            }
        });

        this.confirmPoId = data.id;
    },
    hideConfirmOrderDialog(){
        this.confirmOrderDialog = false;
        this.confirmationDate = null;
        this.selectedPoItem = [];
    },
    saveRemarks(){
        if(this.remark==null || this.remarkDate==null || this.remarkPoId==null){
            return this.showErrorToast({'message':'Remark/Date field cannot be empty!'});
        }
        const data = {
            'poId':this.remarkPoId,
            'remarks':this.remark,
            'date':this.remarkDate,
        }
    
        saveRemark(data).then((res)=>{
            this.showSuccessToast(res.data);
            this.hideRemarksDialog();
            this.getOemPoList();
        }).catch((error)=>this.showErrorToast(error.response.data));
    },
    poDoc(data){
        const store = useOemPoStore();
        store.$patch({
            data:data,
        })

        // this.poPdfDialog = true;
    },
    showNewPyamentDialog(data){
        this.newPaymentDialogVisibility = true;
        
        this.poData = data;
        this.oemPoValue = data.total_value;
        // this.poItems = data.oem_po_details;
        this.poItems = [];
        data.oem_po_details.forEach(e => {
            
            if((e.confirmed == 1 || e.confirmed == '1') && ( (e.paid_amount - (e.sub_total + ((e.sub_total/100)*e.gst))) !=0 )){
            // if((e.confirmed == 1 || e.confirmed == '1') && ( e.payment_status != 'Full' || e.payment_status != 'FULL' )){
                
                // if((e.paid_amount - (e.sub_total + ((e.sub_total/100)*e.gst))) != 0){
                    this.poItems.push(e);
                // }
            }
        });
    },
    hideNewPyamentDialog(){
        this.newPaymentDialogVisibility = false;
        this.oemPoValue = 0;
        this.poItems = [];
        this.selectedPoItem = [];
        this.toPayAmt = 0;
        this.selectedItemsValue = 0;
        this.poDueAmt = 0;
    },
    updateAmount(){
        this.selectedItemsValue = 0;
        // if(this.selectedPoItem.length>0){
            this.selectedPoItem.forEach(e => {
               this.selectedItemsValue +=  ((e.sub_total + ((e.sub_total/100)*e.gst)) - e.paid_amount) ;
            });
        // }

        this.toPayAmt = this.selectedItemsValue;

        this.updateDueAmt({value:this.toPayAmt});
        
    },
    updateDueAmt(event){
        this.poDueAmt = 0;
        this.poDueAmt = this.selectedItemsValue.toFixed(2) - event.value;
    },
    updateAllSelectAmt(data){
        this.selectedItemsValue = 0;
            data.data.forEach(e => {
               this.selectedItemsValue +=  ((e.sub_total + ((e.sub_total/100)*e.gst)) - e.paid_amount);
            });

        this.toPayAmt = this.selectedItemsValue;
    
        this.updateDueAmt({value:this.toPayAmt});

    },
    updateUnselectAmt(){
        this.selectedPoItem = [];
        this.selectedItemsValue = 0;
        this.updateAmount();
    },
    showPaymentDialog(event, data){
        this.poData = data;
        this.oemPoNo = data.po_no;
        this.oemPoValue = data.total_value;
        this.poDueAmt = data.total_value;
        data.payments.forEach(e => {
            this.poDueAmt -= e.amount;
        });
        this.dueAmount2 = this.poDueAmt;
        this.toPayAmt = this.poDueAmt;
        this.paymentDate = null;
            this.$refs.op.toggle(event);
    },
    updatePoDue(event){
        this.poDueAmt = this.dueAmount2 - event.value;
    },
    showPaymentsList(data){
        this.paymentData = data.payments;
        this.oemPoNo = data.po_no; 
        this.oemPoValue = data.total_value;
    //   this.$refs.paymentsOp.toggle(event);
        this.paymentListHeader = 'Payment details: '+this.oemPoNo;

      this.paymentsDialogVisibility = true;
    },
    showItemStatus(itemStatus){
        this.itemSetStatusDate = itemStatus.date;
        this.itemSetStatus = this.itemStatus[itemStatus.status-1].name;
        this.itemSetStatusRemarks = itemStatus.remarks;
        this.itemStatusListDialog = true;
    },
    hideStockDialog(){
        this.itemStockDialogVisibility = false;
        this.selectedItemStatus = [];
        this.selectedStore = null;
        this.stockArrivedDate = null;
        this.stockRecd = 0;
    },
    hidePaymentsList(){
        this.paymentsDialogVisibility = false;
    },
    savePayment(){
        
        if(this.poDueAmt<0){
            return this.showErrorToast({'message':'Payment amount greater than Items Value!'});
        }
        if(this.toPayAmt<=0){
            return this.showErrorToast({'message':'Payment amount is invalid!'});
        }
        if(this.paymentDate==null){
            return this.showErrorToast({'message':'Please select date!'});
        }    
        if(this.selectedItemsValue==0 || this.selectedPoItem.length==0){
            return this.showErrorToast({'message':'Select atleast one(1) item for payment!'});
        }
  
        const data = {
            'id':this.poData.id,
            'amount':this.toPayAmt,
            'date': this.paymentDate.getDate()+'-'+(this.paymentDate.getMonth()+1)+'-'+this.paymentDate.getFullYear(),
            'selectedPoItem':this.selectedPoItem,
        };

            // this.$refs.op.hide();
    this.spinnerLoadingStatus = true;
    this.hideNewPyamentDialog();
    savePoPayment(data).then((res)=>{
            this.toPayAmt = 0;
            this.poDueAmt = 0;
            this.paymentDate = null;
            this.showSuccessToast(res.data);
            this.spinnerLoadingStatus = false;
            this.getOemPoList();
        }).catch((error)=> {
            this.showErrorToast(error.response.data);
            this.spinnerLoadingStatus = false;
        });
    },
    confirmStockArrival(){
        
        // if(this.stockRecd>(this.poItem.quantity - this.poItem.arrived) || this.stockRecd==0){
        if(this.stockRecd==0){
            return this.showErrorToast({'message':'Invalid stock quantity!'});
        }
        if(this.stockArrivedDate==null || this.selectedStore==null){
            return this.showErrorToast({'messsage':'All fields are required!'});
        }

        const data = {
            'itemTransitId':this.itemStatusId,
            'oemItemId':this.itemIdStatus,
            'arrivedStock':this.stockRecd,
            'date':this.stockArrivedDate.getFullYear()+'-'+(this.stockArrivedDate.getMonth()+1)+'-'+this.stockArrivedDate.getDate(),
            'storeOrLocation':this.selectedStore,
            'rocItemId':this.rocItemId,
            'nonRocItemId':this.nonRocItemId,
        };
            
            this.hideStockDialog();
            this.hideItemStatusDialog();

        this.spinnerLoadingStatus = true;
        updateStoreStock(data).then((res)=>{
            this.showSuccessToast(res.data);
            this.spinnerLoadingStatus = false;
            this.getOemPoList();
        }).catch((error)=>{
            this.showErrorToast(error.response.data);
            this.spinnerLoadingStatus = false;
        })
    },
    getArrivedQty(data){
        let qty=0;
        data.forEach(e => {
            qty += e.quantity;
        });
        return qty;
    },
    getPendingQty(totalQty, arrived){
        let qty=0;
        arrived.forEach(e => {
            qty += e.quantity;
        });
        return totalQty-qty;
    },
    showDispatchDialog(item){
        this.itemIndex = item.index;
        this.rocItemId = item.data.roc_item_id;
        this.nonRocItemId = item.data.non_roc_item_id;
       
        this.itemIdStatus = item.data.id;
        this.itemName = item.data.item_name;

        this.selectedStatus = this.itemStatus[0];
        this.stockyQty = item.data.quantity;
        
        if(this.selectedStatus.value==1){
            this.itemDispatchDialogVisibility = true;
            this.stockDue = this.stockyQty;
            if(item.data.dispatched.length>0){
                // this.dispatchedStock = data.data.dispatched;
                item.data.dispatched.forEach(e => {
                    this.dispatchedStock += e.quantity;
                });
                    this.stockPendingDispatch = this.stockyQty - this.dispatchedStock;
            }
            else{
                this.dispatchedStock = 0;
                this.stockPendingDispatch = this.stockyQty;
            }
            this.stockDue = this.stockPendingDispatch;
        }
    },
    updatePendingStock(event){
        this.stockPending = this.stockDue - event.value;
    },
    updateDispatchStock(event){
        this.stockPendingDispatch = this.stockDue - event.value;
    },
    hideDispatchDialog(){
        this.selectedItemStatus = [];
        this.itemDispatchDialogVisibility = false;
        this.dispatchedStock = 0;
        this.stockPendingDispatch = 0;
        this.stockDispatched = null;
        this.stockDispatcDate = null;
        this.itemDispatchRemark = null;
    },
    updateItemStatus(){

        if(this.statusDate==null){
            return this.showErrorToast({'message':'Date is required!'});
        }
        
        const data = {
            'oemItemId':this.itemIdStatus,
            'status':this.selectedStatus, 
            'date':this.statusDate.getFullYear()+'-'+(this.statusDate.getMonth()+1)+'-'+this.statusDate.getDate(),
            'remark':this.itemStatusRemark,
        }

        
        oemItemStatusUpdate(data).then((res)=>{
            this.hideItemStatusDialog();
            this.showSuccessToast(res.data);
            this.getOemPoList();
            this.statusDate = null;
            this.itemStatusRemark = null;
            
        }).catch((error)=>{
            this.showErrorToast(error.response.data);
        })
        
    },
    updateDispatchStatus(){
         if(this.stockDispatcDate==null){
            return this.showErrorToast({'message':'Date is required!'});
        } 
        
        // if(this.stockDispatched > this.stockPendingDispatch){
        //     return this.showErrorToast({'message':'Invalid quantity!'});
        // }

        const data = {
            'oemItemId':this.itemIdStatus,
            'dispatchedQty':this.stockDispatched,
            'date':this.stockDispatcDate.getFullYear()+'-'+(this.stockDispatcDate.getMonth()+1)+'-'+this.stockDispatcDate.getDate(),
            'remark':this.itemDispatchRemark,
        }

        this.hideDispatchDialog();

        this.spinnerLoadingStatus = true;
        
        updateOemItemDispatchStatus(data).then((res)=>{
            this.showSuccessToast(res.data);
            this.dispatchedStock = 0;
            this.stockPendingDispatch = 0;
            this.spinnerLoadingStatus = false;
            this.getOemPoList();
        }).catch((error)=>{
            this.showErrorToast(error.response.data);
            this.spinnerLoadingStatus = false;
        })
    },
    showDispatchStatus(data){
        this.poItem = data;
        this.dispatchStatusHeader = 'Dispatch Status: '+data.item_name;
        this.dispatchDialogVisibility = true;
        this.itemTotalQty = data.quantity;
        this.dispatchStatusArr = data.dispatched;
        this.dispatchStatusArr.reverse();
    },
    showInTransitPanel(event, item){
        this.itemData = item;
        this.$refs.op2.toggle(event);
    },
    showRecdPanel(item){
        
        this.itemStockDialogVisibility = true;
        this.stockArrivalHead = 'Stock arrival: '+this.poItem.item_name;
        this.itemStatusId = item.id;
        this.rocItemId = this.poItem.roc_item_id;
        this.nonRocItemId = this.poItem.non_roc_item_id;
        this.itemIdStatus = this.poItem.id;
        this.stockyQty =  this.poItem.quantity;
        this.stockPending = this.poItem.quantity - this.poItem.arrived;
        this.stockRecd = item.quantity;
        // this.stockPending -= this.stockRecd;

        getStores().then((res)=>{
                this.storeArr = res.data;
                this.poItem.location.forEach(e => {
                    e.location = e.location+' ('+e.quantity+')';
                    this.storeArr.unshift(e);
                });
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
            });

    },
    saveInTransit(){
        const data ={
            'itemStatusData':{
                'id':this.itemData.id,
                'quantity':this.itemData.quantity,
                'remarks':this.itemData.remarks,
            },
            'date':this.inTransitDate.getFullYear()+'-'+(this.inTransitDate.getMonth()+1)+'-'+this.inTransitDate.getDate(),
            'remarks':this.inTransitRemark,
            'oemItemId':this.poItem.id,
        };
        
        this.$refs.op2.hide();
        
        this.dispatchDialogVisibility = false;
        
        this.spinnerLoadingStatus = true;
        
        updateInTransit(data).then((res)=>{
            this.showSuccessToast(res.data);
            this.spinnerLoadingStatus = false;
            this.getOemPoList();
            this.poItem = null;
        }).catch((error)=>{
            this.showErrorToast(error.response.data);
            this.dispatchDialogVisibility = false;
        });
    },
    trialStatusCheck(data, item){
        this.poItem = item.data;
        this.itemTotalQty = item.data.quantity;
        this.dispatchStatusHeader = 'Item Status: '+item.data.item_name;
        this.dispatchDialogVisibility = true;
        this.dispatchStatusArr = data;
        this.totalDispatchedQty = 0;
        data.forEach(e => {
            this.totalDispatchedQty += e.quantity;
        });
        this.dispatchStatusArr.reverse();
        if(this.totalDispatchedQty > this.itemTotalQty){
            this.qtyClass = 'text-red-500';
        }
        if(this.totalDispatchedQty == this.itemTotalQty){
            this.qtyClass = 'text-green-500';
        }
        if(this.totalDispatchedQty < this.itemTotalQty){
            this.qtyClass = 'text-yellow-500';
        }
    },
    hideItemStatusDialog(){
        this.dispatchDialogVisibility = false;
        this.dispatchStatusArr = [];
        this.totalDispatchedQty = 0;
        this.dispatchStatusHeader = null;
        this.itemTotalQty = 0;
        this.poItem = null;
    },
    onRowExpand(){
        this.selectedItemStatus = [];
    },
    showErrorToast(error){
        this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
    },
    showSuccessToast(data){
        this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
    },
    globalFilter(){
            // this.filteredOemPo = this.deptPo;
            this.filteredOemPo = [];
            
            if(this.filterQuery!=null){
                this.oemPos.forEach(e => {
                    if (e.po_no.includes(this.filterQuery) || e.manufacturer.name.includes(this.filterQuery)) { 
                        this.filteredOemPo.push(e); 

                        // for filtering ref po
                        // e.dept_po_ref[0].includes(this.filterQuery) 
                    }
                    else{
                        e.oem_po_details.every(el => {
                            if(el.item_name.includes(this.filterQuery) || el.category.includes(this.filterQuery)){
                                this.filteredOemPo.push(e);
                                return false;
                            }
                            return true;
                        });
                    }
                });
            }
    },
    clearFilter(){
        this.filteredOemPo = this.oemPos;
        this.filterQuery = null;
    }
},

mounted(){
    this.getOemPoList();
}
}
</script>

<style scoped>

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}

/* table, tr, td {
    border: 1px solid rgb(166, 165, 165);
    border-collapse: collapse;;
} */  
table {
  border-collapse: collapse;
  width: 100%;
}

tr {
    padding: 8px;
  border-bottom: 1px solid #9c9a9a;
}

td {
    padding: 8px;
}

tr:nth-child(even) {
  background-color: #ebfcff;
} 

tr:hover {background-color: #D6EEEE;}

@media print {
  body {
    visibility: hidden;
  }
}


</style>