import { defineStore } from "pinia";

export const useIndianStateStore = defineStore('indianStates', {
    state:()=>({
        data:[
            {name:'Andaman and Nicobar Islands'},
            {name:'Andhra Pradesh'},
            {name:'Arunachal Pradesh'},
            {name:'Assam'},
            {name:'Bihar'},
            {name:'Chandigarh'},
            {name:'Chhattisgarh'},
            {name:'Dadra and Nagar Haveli'},
            {name:'Daman and Diu'},
            {name:'Delhi'},
            {name:'New Delhi'},
            {name:'Goa'},
            {name:'Gujarat'},
            {name:'Haryana'},
            {name:'Himachal Pradesh'},
            {name:'Jammu and Kashmir'},
            {name:'Jharkhand'},
            {name:'Karnataka'},
            {name:'Kerala'},
            {name:'Ladakh'},
            {name:'Lakshadweep'},
            {name:'Madhya Pradesh'},
            {name:'Maharashtra'},
            {name:'Manipur'},
            {name:'Meghalaya'},
            {name:'Mizoram'},
            {name:'Nagaland'},
            {name:'Mizoram'},
            {name:'Odisha'},
            {name:'Puducherry'},
            {name:'Punjab'},
            {name:'Rajasthan'},
            {name:'Sikkim'},
            {name:'Tamil Nadu'},
            {name:'Telangana'},
            {name:'Tripura'},
            {name:'Uttar Pradesh'},
            {name:'Uttarakhand'},
            {name:'West Bengal'},
        ]
    }),

    getters:{
        fetchStates(state){
            return state.data;
        }
    }
})