<template>
    <InputNumber class="pr-1" v-model="locationQuantity" type="integer" inputStyle="width:5em; height:2.5em" placeholder="Quantity" @input="bindData"/>
    <!-- <InputText class="pr-1" v-model="deliveryLocation" type="text" placeholder="Location" style="width:6em; height:2.5em" @change="bindData"/> -->

    <Dropdown class="pr-1" v-model="deliveryLocation" filter editable :options="locations" optionLabel="name" optionValue="id" placeholder="Select a location" style="width:9em; height:2.5em" @change="bindData"/>
    <Button class="ml-1 my-2" icon="pi pi-times" text raised style="width:1.5em; height:1em" severity="danger" @click="$emit('delete')" aria-label="Cancel" @change="bindData"/> <br>
</template>

<script>
import InputNumber from 'primevue/inputnumber'
// import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'

export default {

    props:{
        itemDetails:Object,
        quantity:Number,
        index:Number,
        dbLocations:Array
    },

    emits:['bind-item', 'delete'],
    components:{
        InputNumber,
        // InputText,
        Button,
        Dropdown
    },

    data(){
        return{
            locationQuantity:0,
            deliveryLocation:null,
            locations:[]
        }
    },
    methods:{
        bindData(){
            
            const itemdata = {
                itemId: this.itemDetails.id,
                locationQuantity:this.locationQuantity,
                deliveryLocation:this.deliveryLocation
            };
           
            this.$emit('bind-item', this.index, itemdata)
        },
    },
    mounted(){
        this.locationQuantity = this.quantity;
        this.locations = this.dbLocations;
    }

}
</script>

<style>

</style>