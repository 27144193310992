<template>
  <Navbar/>
  <SideBar/>
  <ConfirmDialog></ConfirmDialog>
    <div class="card main-content">
        <div class="progress-spinner" v-if="spinnerLoadingStatus">
            <ProgressSpinner></ProgressSpinner>
        </div>
        <div class="surface-ground ">
            <Card>
                <template #content>
                    <DataTable v-model:editingRows="editParentRow" editMode="row" :value="oemPos" size="small" :loading="loadingStatus" v-model:filters="filters" tableStyle="min-width: 50rem" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id" @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" @row-edit-save="onParentRowEditSave" :pt="{
                table: { style: 'min-width: 50rem' },
                column: {
                    bodycell: ({ state }) => ({
                        style:  state['d_editing']&&'padding-top: 0.6rem; padding-bottom: 0.6rem; padding-left: 0.3rem;'
                    })
                }
                }">
                        <template #header >
                            <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                                <span class="text-xl text-900 font-bold">OEM PO <Tag value="DRAFT" severity="warning" class="mx-3"/> </span>
                                <div class="flex justify-content-end">
                                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                                </div>
                            </div>
                        </template>
                            <Column expander style="width: 5rem"/>
                            <Column field="po_no" header="PO No.">
                                <template #body="slotProps">
                                    <router-link @click="poDoc(slotProps.data)" to="/oem-po-pdf" >{{slotProps.data.po_no}}</router-link>
                                </template>
                            </Column>
                            <Column field="dept_po_ref" header="PO Ref">
                                <template #body="slotProps">
                                    <label v-for="(po, index) in slotProps.data.dept_po_ref" :key="index">
                                        <div class="py-1">{{po}} </div>
                                    </label>
                                </template>
                            </Column>
                            <Column field="manufacturer.name" header="To"></Column>
                            <Column field="po_date" header="PO Date" style="width:10%">
                                <template #editor="{ data, field }">
                                    <Calendar v-model="data[field]" />
                                </template>
                            </Column>
                            <Column field="total_value" header="PO Value">
                                <template #body="slotProps">
                                    {{formatCurrency(slotProps.data.total_value)}}
                                </template>
                            </Column>
                            <Column header="Terms & Conditions">
                                <template #body="slotProps">
                                    <!-- <Inplace :closable="true" closeicon>
                                        <template #display>
                                            {{ 'Show' }}
                                        </template>
                                        <template #content>
                                            <p>{{ slotProps.data.terms_and_contions }}</p> 
                                        </template>

                                    </Inplace> -->
                                    <Textarea v-model="slotProps.data.terms_and_contions" :modelValue="slotProps.data.terms_and_contions" rows="3"/>
                                    <!-- {{slotProps.data.terms_and_contions}} -->
                                </template>
                                <!-- <template #editor="{ data, field }">
                                    <InputText v-model="data[field]" />
                                </template> -->
                            </Column>
                            <Column header="Action">
                                <template #body="slotProps">
                                   <Button label="Approve" class="py-2" severity="success" raised outlined @click="confirmDialog(slotProps.data.id)" v-tooltip.top="'Approves PO. Edit will not be allowed further'"/>
                                </template>
                            </Column>
                            <Column header="Update" :rowEditor="true" style="width: 7%; min-width: 4rem" bodyStyle="text-align:center"></Column>
                            <Column header="Delete">
                                <template #body="slotProps">
                                       <Button v-if="slotProps.index==0" icon="pi pi-trash" severity="danger" class="py-2" raised outlined @click="deleteParentRow(slotProps.data.id)" v-tooltip.left="'Delete the PO permanently. Same PO No. will be available'"/>
                                        
                                        <Button v-if="slotProps.index>0" label="Cancel" severity="danger" class="py-2" raised outlined @click="cancelPoDialog(slotProps.data.id)" v-tooltip.left="'Cancels the PO but will be available for reference.'"/>
                                </template>
                            </Column>
                            <template #expansion="slotProps">
                                <div class="p-3">
                                    
                                    <DataTable :value="slotProps.data.oem_po_details" size="small" v-model:editingRows="editChildRow" editMode="row" :pt="{
                table: { style: 'min-width: 50rem' },
                column: {
                    bodycell: ({ state }) => ({
                        style:  state['d_editing']&&'padding-top: 0.6rem; padding-bottom: 0.6rem;'
                    })
                }
                }" @row-edit-save="onChildRowEditSave($event, slotProps.data.id)" v-model:filters="filter2">

                <div class="flex flex-wrap align-items-center justify-content-between ">
                    <div>
                        <span>
                            <h3>
                            OEM PO items: 
                            </h3>
                        </span> 
                    </div>

                    <div>
                        <Button label="Add Item" size="small" raised outlined severity="help" class="mr-2" @click="setOemPoStore(slotProps.data)"/>
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText  v-model="filter2['global'].value" placeholder="Keyword Search" size="small"/>
                        </span>
                    </div>
                </div>
                                        <Column field="item_name" header="Item"></Column>
                                        <Column field="add_on_data" header="Cat/Part-No">
                                            <template #body="slotProps">
                                                {{slotProps.data.add_on_data}}
                                            </template>
                                            <template #editor="{ data, field }">
                                                <InputText v-model="data[field]" />
                                            </template>
                                        </Column>
                                        <Column field="packing" header="Packing"></Column>
                                        <Column field="quantity" header="Quantity"></Column>
                                        <Column field="rate" header="Rate">
                                            <template #body="slotProps">
                                                {{ formatCurrency(slotProps.data.rate) }}
                                            </template>
                                            <template #editor="{ data, field }">
                                                <InputNumber v-model="data[field]" :minFractionDigits="2" :maxFractionDigits="3"/>
                                            </template>
                                        </Column>
                                        <Column field="gst" header="Gst">
                                            <template #editor="{ data, field }">
                                                <InputNumber v-model="data[field]" />
                                            </template>
                                        </Column>
                                        <Column field="sub_total" header="Sub Total" >
                                            <template #body="slotProps">
                                                {{ formatCurrency(slotProps.data.sub_total) }}
                                            </template>
                                        </Column>
                                        <Column header="Delivery To">
                                            <template #body="slotProps">
                                                <span v-if="slotProps.data.location_status==1">
                                                    <Button label="Show" size="small" icon="pi pi-external-link" @click="showLocations(slotProps.data)" />
                                                </span>
                                            </template>
                                        </Column>  
                                        <Column header="Update" :rowEditor="true" style="width: 7%; min-width: 4rem" bodyStyle="text-align:center"></Column>                          
                                        <Column v-if="slotProps.data.oem_po_details.length>1" header="Delete">
                                            <template #body="slotProps">
                                                <Button icon="pi pi-trash" severity="danger" raised outlined @click="deleteChildRow(slotProps.data.id)"/>
                                            </template>
                                        </Column>
                                    </DataTable>

                                    <!-- Delivery location dilog -->
                                    <Dialog :visible="dialogVisibility" modal :closable="false" :header="dialogHeader" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                                                        
                                        <DataTable :value="itemDeliveryLocations"  tableStyle="min-width: 30rem">
                                            <Column field="location" header="Location"></Column>
                                            <Column field="quantity" header="Quantity"></Column>
                                        </DataTable>
                                        <div class="flex justify-content-end flex-wrap">
                                        <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" severity="danger" @click="dialogVisibility=false"></Button>
                                        </div>
                                    </Dialog>
                                    <!-- end delivery location dilog -->                        

                                </div>
                            </template>

                        
                    </DataTable>

                    <!-- approve po start -->
                    <Dialog :visible="approveDialogVisibility" modal :closable="false" header="Approve PO" :style="{ width: '30rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                        
                            <div class="flex">
                                <div class="flex align-items-center justify-content-center pr-3"><i class="pi pi-check-circle" style="font-size: 2.5rem"></i></div>
                                <div class="flex align-items-center justify-content-center ">
                                    <p>Once approved this PO can't be edited. <br> Are you sure you want to approve?</p>
                                </div>
                              
                            </div>

                        <div class="flex justify-content-end flex-wrap">
                            <Button class="my-1 mr-2 py-2" label="Approve" severity="success" @click="approvePo" />
                            <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" outlined severity="secondary" @click="approveDialogVisibility=false"></Button>
                        </div>
                    </Dialog>
                    <!-- approve po end -->

                    <!-- cancel po confirmation dialog -->
                    <Dialog :visible="cancelDialogVisibility" modal :closable="false" header="Cancel PO" :style="{ width: '40rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">

                        <div class="flex">
                                <div class="flex align-items-center justify-content-center pr-3"><i class="pi pi-exclamation-triangle" style="font-size: 2.5rem"></i></div>
                                <div class="flex align-items-center justify-content-center ">
                                    <p>All items ordered will be available for re-ordering <br> Once cancelled, the PO No. will no longer be available for use. <br> Are you sure you want to cancel?</p>
                                </div>
                            </div>                       

                        <div class="flex justify-content-end flex-wrap">
                            <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" severity="secondary" outlined @click="cancelDialogVisibility=false"></Button>
                            <Button class="my-1 ml-2 py-2" label="Cancel PO" severity="danger" @click="cancelPo" />
                        </div>
                    </Dialog>
                    <!-- cancel po confirmation dialog end-->
                </template>
            </Card>
        </div>
    </div>

</template>

<script>
import Card from 'primevue/card'
import Navbar from '../header/headerNavbar.vue'
import { getOemPoList, deleteOemPoItem, deleteOemPo, approveOemPo, cancelOemPo, updateOemPo, updateOemPoItem } from '@/services/apiService';
import { FilterMatchMode } from 'primevue/api'
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
// import Inplace from 'primevue/inplace';
import { useOemPoStore } from '@/stores/oemPo';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import InputNumber from 'primevue/inputnumber';
import ConfirmDialog from 'primevue/confirmdialog';
import Textarea from 'primevue/textarea';
import ProgressSpinner from 'primevue/progressspinner';
import Tag from 'primevue/tag';
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
    components:{
        Navbar,
        SideBar,
        Card,
        InputText,
        DataTable,
        Column,
        // Inplace,
        Button,
        Dialog,
        Calendar,
        InputNumber,
        ConfirmDialog,
        Textarea,
        ProgressSpinner,
        Tag,
    },
    data(){
        return{
            expandedRows:[],
            oemPos:[],
            loadingStatus:false,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                po_no: { value: null, matchMode: FilterMatchMode.IN },
                'manufacturer.name':{ value: null, matchMode: FilterMatchMode.IN },
            },
            filter2: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                item_name: { value: null, matchMode: FilterMatchMode.IN },
                add_on_data: { value: null, matchMode: FilterMatchMode.IN },
            },
            dialogHeader:null,
            dialogVisibility:false,
            itemDeliveryLocations:null,
            approveDialogVisibility:false,
            editParentRow:[],
            editChildRow:[],
            oemPoId:null,
            cancelDialogVisibility:false,
            spinnerLoadingStatus:false,
        }
    },
    methods:{
        getOemPoList(){
        const query = '?isDraft[eq]=1';
        this.loadingStatus = true;
            getOemPoList(query).then((res)=>{
                this.oemPos = res.data;
                this.loadingStatus = false;
            }).catch((error)=>this.showErrorToast(error.response.data));

        },
        showLocations(data){
            this.dialogHeader = data.item_name;
            this.dialogVisibility = true;
            this.itemDeliveryLocations = data.location;
        },
        poDoc(data){
            const store = useOemPoStore();

            store.$patch({
                data:data,
            })           
        },
        confirmDialog(poId){
            this.approveDialogVisibility = true;
            this.oemPoId = poId;
        },
        onParentRowEditSave(event){
        
            let newDate = null;
            if(isNaN(event.newData.po_date)){
                newDate = event.newData.po_date.split("-");
                newDate = newDate[2]+'-'+newDate[1]+'-'+newDate[0];
            }
            if(!isNaN(event.newData.po_date)){
               newDate = event.newData.po_date.getFullYear()+'-'+(event.newData.po_date.getMonth()+1)+'-'+event.newData.po_date.getDate();
            }
            
            const data = {
                'id':event.newData.id,
                'terms_and_conditions':event.newData.terms_and_contions,
                'po_date': newDate,
            }

            this.spinnerLoadingStatus = true;
            updateOemPo(data, event.newData.id).then((res)=>{
                this.showSuccessToast(res.data);
                this.spinnerLoadingStatus = false;
                this.getOemPoList();
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            });
        },
        onChildRowEditSave(event, poId){

            const data = {
                'poId':poId,
                'id':event.data.id,
                'oldRate':event.data.rate,
                'oldGst':event.data.gst,
                'oldSubTotal':event.data.sub_total,
                'quantity':event.data.quantity,
                'newRate':event.newData.rate,
                'newGst':event.newData.gst,
                'addOnHead':event.newData.add_on_head,
                'addOnData':event.newData.add_on_data,
            }

            this.spinnerLoadingStatus = true;
            updateOemPoItem(data).then((res)=>{
                this.showSuccessToast(res.data);
                this.spinnerLoadingStatus = false;
                this.getOemPoList();
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.spinnerLoadingStatus = false;
            });
        },
        deleteChildRow(data){

            this.$confirm.require({
                message: 'All items ordered will be available for re-ordering. Are you sure you want to delete PO Item?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined',
                acceptClass: 'p-button-danger',
                rejectLabel: 'Cancel',
                acceptLabel: 'Delete',
                accept: () => {
                    this.spinnerLoadingStatus = true;
                    deleteOemPoItem({id:data}).then((res)=>{
                        this.showSuccessToast(res.data);
                        this.spinnerLoadingStatus = false;
                        this.getOemPoList();
                    }).catch((error)=>this.showErrorToast(error.response.data));
                },
                reject: () => {}
            });

            
        },
        deleteParentRow(id) {
            this.$confirm.require({
                message: 'All items ordered will be available for re-ordering. Are you sure you want to delete PO?',
                header: 'Confirmation',
                icon: 'pi pi-exclamation-triangle',
                rejectClass: 'p-button-secondary p-button-outlined',
                acceptClass: 'p-button-danger',
                rejectLabel: 'Cancel',
                acceptLabel: 'Delete',
                accept: () => {
                    this.spinnerLoadingStatus = true;
                        deleteOemPo(id).then((res)=>{
                            this.showSuccessToast(res.data);
                            this.spinnerLoadingStatus = false;
                            this.getOemPoList();
                        }).catch((error)=>this.showErrorToast(error.response.data));
                },
                reject: () => {}
            });
        },
        approvePo(){
            this.approveDialogVisibility = false;
            this.spinnerLoadingStatus = true;
            approveOemPo({id:this.oemPoId}).then((res)=>{
                this.showSuccessToast(res.data);
                // this.approveDialogVisibility = false;
                this.spinnerLoadingStatus = false;
                this.getOemPoList();
            }).catch((error)=>{
                this.showErrorToast(error);
                this.spinnerLoadingStatus = false;
              });
        },
        cancelPoDialog(id){
            this.oemPoId = id;
            this.cancelDialogVisibility = true;
        },
        cancelPo(){
            this.spinnerLoadingStatus = true;
            cancelOemPo({id:this.oemPoId}).then((res)=>{
                this.showSuccessToast(res.data);
                this.cancelDialogVisibility = false;
                this.spinnerLoadingStatus = false;
                this.getOemPoList();
            }).catch((error)=>this.showErrorToast(error.response.data));
        },
        setOemPoStore(data){
    
            const store = useOemPoStore();
            store.$patch({
                data:data,
            });
            this.$router.push('add-oem-po');
        
        },
        formatCurrency(value) {
            return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
        },
    },
    mounted(){
        this.getOemPoList();
    }
}
</script>

<style scoped>

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}

</style>