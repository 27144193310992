<template>
    
    <div style="background:#FFFFFF;">
          
      
      <table  width="100%" style="font-size:12px; background-color: #FFFFFF; margin-top:15px;" >
          <thead >
                      <tr>
                          <td colspan="6" style="text-align: center; font-size: 18px; padding: 1% 0;">
                              <label ><strong>DELIVERY CHALLAN</strong></label>
                          </td>
                      </tr>
                      <tr>
                          <td rowspan="3" colspan="2" style="padding-left:1%; padding-bottom:3%;">
                              <label style="font-size:16px;"><strong>Arengh Medical Supplier</strong></label><br>
                              HAWAKHANA, TURA, South West Garo Hills, Meghalaya, 794001<br>
                              GSTIN/UIN: 17ABEFA6546N1Z1 <br>
                              State Name: Meghalaya, Code 17
                          </td>
                          <td colspan="2" class="header-cell-padding">Delivery Note No. <br> <strong> {{dcNo}}</strong></td>
                          <td colspan="2" class="header-cell-padding">Dated <br> <strong>{{dcDate}}</strong></td>
                      </tr>
                      <tr>
                          <td colspan="2" class="header-cell-padding">Ref No. <br></td>
                          <td colspan="2" class="header-cell-padding" style="padding-bottom:1%" >Dated<br> </td>
                      </tr>
                      <tr>
                          <td colspan="2" class="header-cell-padding wrap-long-po">Buyer's Order No. <br><strong>{{deptPoNo}}</strong></td>
                          <td colspan="2" class="header-cell-padding">Dated <strong><br> {{deptPoDate}}</strong> </td>
                      </tr>
                      <tr>
                          <td style="padding-left:1%; padding-bottom:3%;" colspan="2"> Buyer (Bill to) <br> <strong> The {{buyerDesignation}}</strong>
                              <br>
                              {{buyerDept}} <br>
                              State Name: {{buyerState}}, Code: {{buyerStateCode}}
                          </td>
                          <td colspan="4" class="header-cell-padding" style="padding-bottom:3%">
                              Place of Delivery <br>
                              <strong>{{deliveryLocation}}</strong>
                          </td>
                      </tr>
  
                      <tr>
                          <th class="sl-no-width" >Sl No.</th>
                          <th colspan="3" class="item-descrption-width" >Description of Goods</th>  
                          <th class="hsn-width">HSN/SAC</th>
                          <th class="qty-width">Quantity</th>
                      </tr>
                      
              </thead>
  
                      <tbody >                    
                                      <tr v-for="(item, index) in items" :key="index" style="padding-top:0%;">
                                          <td align="center" class="border-hidden" style="padding-bottom: 1%;">
                                                  {{item.slNo}}                                                  
                                          </td>


                                          <td class="border-hidden" style="padding-left:2%; padding-bottom:1%; padding-top: 1%;"  colspan="3" > 
                                                  <strong> {{item.name}}</strong>
                                                  <br>
                                                  
                                                    <!-- <div  v-if="item.category=='Medicine' || item.category=='MEDICINE' || item.category=='Consumables' || item.category=='CONSUMABLES' || item.category=='Consumable' || item.category=='CONSUMABLE' || item.category=='Surgical' || item.category=='Test Kits' "> -->
                                                        <div  v-if="item.category!='Equipment' || item.category!='EQUIPMENT'">
                                                    <label > 
                                                        <em>
                                                            <div v-if="item.batch_no">
                                                                &nbsp;&nbsp; Batch : <strong>{{item.batch_no}}</strong><br>
                                                            </div>
                                                            <div v-if="item.mfgDate">
                                                                &nbsp;&nbsp; Mfg Date: <strong>{{item.mfgDate}}</strong><br>
                                                            </div>
                                                            <div v-if="item.expDate">
                                                                &nbsp;&nbsp; Exp Date: <strong>{{item.expDate}}</strong><br>
                                                            </div>
                                                        </em>
                                                    </label>
                                                  </div>
                                                  <div v-if="item.category=='Equipment' || item.category=='EQUIPMENT'">
                                                  <!-- <div v-if="item.modelNo!=null || item.partNo!=null "> -->
                                                    <label > <em>
                                                    &nbsp;&nbsp; Model : <strong>{{item.modelNo}}</strong><br>
                                                    &nbsp;&nbsp; Serial: <strong>{{item.partNo}}</strong><br> 
                                                    </em></label>
                                                  </div>
                                          </td>
                                          

                                            <!-- <td  class="border-hidden" style="padding-left:2%; padding-bottom:1%; padding-top: 1%;"  colspan="3" > 
                                                  <strong v-if="item.poItemId!=items[index-1].poItemId"> {{item.name}}</strong>
                                                  <strong v-if="item.poItemId==items[index-1].poItemId"> </strong>
                                                  <br>
                                                  
                                                    <div  v-if="item.category=='Medicine' || item.category=='MEDICINE' || item.category=='Consumables' || item.category=='CONSUMABLES' || item.category=='Consumable' || item.category=='CONSUMABLE' || item.category=='Surgical' || item.category=='Test Kits' ">
                                                    <label > 
                                                        <em>
                                                            &nbsp;&nbsp; Batch : <strong>{{item.batch_no}}</strong><br>
                                                            <div v-if="item.mfgDate">
                                                                &nbsp;&nbsp; Mfg Date: <strong>{{item.mfgDate}}</strong><br>
                                                            </div>
                                                            &nbsp;&nbsp; Exp Date: <strong>{{item.expDate}}</strong><br>
                                                        </em>
                                                    </label>
                                                  </div>
                                                  <div v-if="item.category=='Equipment' || item.category=='EQUIPMENT'">
                                                    <label > <em>
                                                    &nbsp;&nbsp; Model : <strong>{{item.modelNo}}</strong><br>
                                                    &nbsp;&nbsp; Serial: <strong>{{item.partNo}}</strong><br> 
                                                    </em></label>
                                                  </div>
                                          </td> -->

                                          <td class="border-hidden" align="center"  >
                                                  {{item.hsn}}
                                          </td>
                                          <td class="border-hidden" align="center"  >
                                                  <!-- <strong> {{item.qty}} {{item.packing}}</strong> -->
                                                  <strong> {{formatCurrency(item.qty)}} {{item.packing}}</strong>
                                          </td>
                                      </tr>
                                        <tr>
                                          <td ><div id="additional-padding" ></div></td>
                                          <td colspan="3" ><div id="additional-padding"></div></td>
                                          <td ><div id="additional-padding"></div></td>
                                          <td ><div id="additional-padding"></div></td>
                                      
                                        </tr>
                                        <!-- <tr class="dc_table">
                                            <td></td>
                                            <td colspan="3"></td>
                                            <td></td>
                                            <td></td>
                                        </tr> -->
  
                      </tbody>
                      
                      <tfoot>
                              <tr>
                                  <td colspan="4" align="right" style="padding-right:1%;">Total</td>
                                  <td ></td>
                                  <td style="padding-left:1%; padding-right:1%;" ><strong> {{formatCurrency(totalItemQty)}}</strong></td>
                                  <!-- <td style="padding-left:2%; " ></td> -->
                              </tr>
                              <tr>
                                  <td colspan="6" align="right" style="padding-bottom:6%; padding-right:1%">
                                          E. & O.E
                                  </td>
                              </tr>
                              <tr >
                                  <td colspan="3" style="padding-bottom:12%; padding-left:1%; width:350px;">Recd in Good Condition</td>
                                  <td colspan="3" align="right" style="padding-right:1%; padding-top:1%; width:250px;"><strong> For Arengh Medical Supplier </strong><br> <br><br><br><br>
                                      Authorised Signatory
                                  </td>
                              </tr>
  
                          <tr>
                              <td colspan="6" class="footer-caption">This is a computer generated document</td>
                          </tr>
  
                      </tfoot>                    
  
                  </table>
  
  </div>
  </template>
  
<script>

import { useDcStore } from '@/stores/dcStore';
  
  export default {
  
      data(){
          return{
              dcNo:null,
              dcDate:null,
              dept:null,
              deptPo:null,
              items:[],
              deptPoNo:null,
              deptPoDate:null,
              buyerDesignation: null,
              buyerDept:null,
              buyerState: null,
              buyerStateCode: null,
              deliveryLocation:null,
              totalItemQty:0,
              count:2,
              slNo:0,
          }
      },
  
  methods:{
      getDcData(){
              const store = useDcStore();
              
              this.dcNo = store.data.dcNo;
              this.dcDate = store.data.dcDate;
              this.deptPoNo = store.data.deptPo;
              this.deptPoDate = store.data.deptPoDate;
              this.buyerDesignation = store.data.dept.head;
              this.buyerDept = store.data.dept.name;
              this.buyerState = store.data.dept.state;
              this.buyerStateCode = store.data.dept.code;
              this.deliveryLocation = store.data.location.name;
  
            store.data.dcItems.forEach((e, key) => {

                if(key>0){
                    if(e.poItemId != store.data.dcItems[key-1].poItemId){                        
                        e.slNo = ++this.slNo;
                        this.items.push(e);
                    }
                    else{
                        e.slNo = null;
                        e.name = null;
                        this.items.push(e);
                    }

                }
                if(key==0){
                    e.slNo = ++this.slNo;
                    this.items.push(e);    
                }
            });

              this.items.forEach(e => {
                  this.totalItemQty += e.qty;
              });
          },
        formatCurrency(value) {
            return value.toLocaleString('en-IN');
        },
        getSlNo(item, index){
            
            if(item.poItemId != this.items[index-1].poItemId){
                if(this.count==index){
                    this.count = index;
                    return index;
                }
                else{
                    index = this.count;
                    return ++index;
                }

            }
        }
      },
  
      mounted(){
          this.getDcData();
      },
  
      updated(){
          setTimeout(() => {
              window.print();
          }, 800);
      }
  
  }
</script>

  
  <style scoped>
  
  
  table, th, td {
    border: 0.5px solid black;
    border-collapse: collapse;
    border-color: rgb(170, 170, 170);
  }
  
  .dc_table {
    height: 90vh;;
  }
  
  .wrap-long-po {
      word-wrap: break-word;
  }
  
  
  .sl-no-width{
      width: 28px;
  }
  
  .item-descrption-width {
      width:559px;
  }
  
  .hsn-width {
      width:65px;
  }
  
  .qty-width {
      width:62px;
  }
  
  .header-caption {
      border-top-style: hidden;
      border-right-style: hidden;
      border-left-style: hidden;
      /* border-style: hidden; */
      text-align: center;
  }
    
  .border-hidden {
    border-bottom-style: hidden;
  }
  
  
  #additional-padding {
    grid-template-columns: 100%;
    z-index: 1;
    padding-bottom: 10%;
    /* max-height: 50vh;
    min-height: 50vh; */
    height: 40vh;
  }
  
  
  .header-cell-padding{
      padding-left: 1%;
      padding-right: 1%;
  }
  
  .footer-caption {
      padding-top: 1%;
      border: none;
      border-bottom-style: hidden;
      border-right-style: hidden;
      border-left-style: hidden;
      /* border-style: hidden; */
      text-align: center;
  }
  
  
  </style>