<template>
  <div class="card flex flex-wrap p-fluid">
                    <div class="col-3">
                      <div class="flex-auto">
                            <label for="itemName" class="font-bold mb-2"> Item Name </label>
                            <InputText v-model="itemName" @change="bindData"/>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="flex-auto">
                          <label for="itemRate" class="font-bold mb-2"> Rate </label>
                          <InputNumber v-model="itemRate" inputId="itemRate" @change="bindData" :minFractionDigits="2" :maxFractionDigits="3"/>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="flex-auto">
                          <label for="itemPacking" class="font-bold mb-2"> Packing </label>
                          <InputText v-model="itemPacking"  @change="bindData"/>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="flex-auto">
                        <label for="itemCategory" class="font-bold mb-2">Category </label>
                         <!-- <Dropdown size="small" v-model="selectedCategory" editable :options="categories" optionValue="id" optionLabel="label"  placeholder="Select Category" @change="bindData"> -->
                          <TreeSelect v-model="selectedCategory" :options="categories" placeholder="Select Category" class="w-full" @change="bindData"/>
                         <!-- </Dropdown> -->
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="flex-auto">
                        <label for="manufacturer" class="font-bold mb-2">Manufacturer</label>
                        <Dropdown size="small" v-model="itemManufacturer" editable :options="manufacturers" optionValue="id" optionLabel="name" placeholder="Select Manufacturer" @change="bindData"/>
                      </div>
                    </div>
                    <div class="col-1">
                      <div class="flex-auto" >
                        <label for="action" class="font-bold mb-2">Action</label>
                        <Button icon="pi pi-times" size="small" severity="danger" text raised @click="$emit('delete')" />
                      </div>
                    </div>
                  </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button'
import TreeSelect from 'primevue/treeselect'

export default {
  name:'RocItem',
    components:{ 
      InputText, 
      InputNumber, 
      Dropdown, 
      Button, 
      TreeSelect
      },

      props:{
        itemId:Number,
      categories: Array,
      manufacturers:Array
      },

    emits: ['delete', 'bind-item'],

    data(){
      return{
          itemName:null,
          itemRate:null,
          itemPacking:null,
          itemManufacturer:null,
          selectedCategory:null,
      }
    },

    methods:{
      bindData(){
      
       const itemdata = {
              itemName:this.itemName,
              itemRate:this.itemRate,
              itemPacking:this.itemPacking,
              itemManufacturer:this.itemManufacturer,
              selectedCategory:this.selectedCategory
        }
        this.$emit('bind-item', this.itemId, itemdata);
      }
    },

    mounted(){
      
    },
  
}
</script>

<style>

</style>