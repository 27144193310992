<template>
    <div class="overflow-hidden">
    <div class="flex">
        <InputText size="small" inputStyle="width:2em;" v-model="remarks"  placeholder="Remarks" @change="bindData"/>
        <Calendar class="mx-2" size="small" v-model="remarkDate" dateFormat="dd/mm/yy" showIcon inputId="buttondisplay" inputStyle="width:em;" @date-select="bindData"/>
        <!-- <Button size="small" class="mx-1" icon="pi pi-save" severity="success" @click="handleSave"/> -->
    </div>
    </div>
    
</template>
    
<script>
import InputText from 'primevue/inputtext'
import Calendar from 'primevue/calendar'
// import Button from 'primevue/button'

export default {
    components:{
        InputText,
        Calendar,
        // Button
    },

    props:{
        oemPo:Object,
        oemPoId:String,
    },

    data(){
        return{
            remarks:null,
            remarkDate:null
        }
    },
    methods:{
        bindData(){
             const itemdata = {
                poId: this.oemPoId,
                remarks:this.remarks,
                date:this.remarkDate!=null? this.remarkDate.getDate()+'-'+(this.remarkDate.getMonth()+1)+'-'+this.remarkDate.getFullYear():null,
            };
           
            this.$emit('bind-item', itemdata)
        },
    },
    mounted(){
        
    }

}
</script>

<style>

</style>