
<template>
<Navbar/>
<SideBar/>
  
<div class="card main-content">
    <div class="surface-ground">
        <Card>
            <template #content>
            <DataTable :value="termsConditions" tableStyle="min-width: 50rem" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]"
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}" dataKey="id" @rowExpand="onRowExpand">
                <template #header >
                    <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                        <span class="text-xl text-900 font-bold">Terms & Conditions</span>
                        <Button class="p-button-sm" size="small" label="New Template" icon="pi pi-plus" severity="info" raised @click="showDialog"/>
                    </div>
                </template>
                <Column field="content" header="Content" style="width:80%"></Column>
                <Column field="category" header="Category"></Column>
                <Column header="Delete">
                    <template #body="slotProps">
                        <Button icon="pi pi-trash" severity="danger" size="small" @click="showDeleteDialog(slotProps.data.id)"/>
                    </template>
                </Column>

                <!-- confirm delete dialog -->
                <Dialog :visible="deleteDialogVisibility" modal :closable="false" header="Delete template" :style="{ width: '30rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                        
                        <p>Are you sure you want to delete the template?</p>
                        <template #footer>
                            <div class="flex flex-row-reverse flex-wrap">
                                <div class="flex align-items-center justify-content-center ">
                                    <Button class="flex align-items-center justify-content-center " size="small" label="Cancel" severity="danger" @click="hideDeleteDialog"></Button>
                                </div>
                                <div class="flex align-items-center justify-content-center">
                                    <Button class="flex align-items-center justify-content-center " size="small" label="Yes" severity="success" @click="deleteItem"/>
                                </div>
                            </div>
                        </template>
                </Dialog>
                <!-- confirm delete dialog end -->
            </DataTable>
            </template>
        </Card>
    </div>
</div>


 <Dialog :visible="dialogVisibility" modal :closable="false" header="Terms and Conditions" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <div class="formgrid grid mt-2">
            <div class="field col">
                <label class="font-bold block" for="paymentTerms">Category:</label>   
                <TreeSelect class="w-full" v-model="selectedCategory" :options="categories" placeholder="Select category"/>
            </div>
        </div>
        <div class="formgrid grid mt-2">
            <div class="field col">
                <label class="font-bold block" for="paymentTerms">Content:</label>                             
                <Textarea class="surface-overlay w-full" v-model="content" rows="5"/>
            </div>
        </div>

        <template #footer>
            <div class="flex flex-row-reverse flex-wrap">
                <div class="flex align-items-center justify-content-center ">
                    <Button class="flex align-items-center justify-content-center " size="small" label="Close" severity="danger" @click="hideDialog"></Button>
                </div>
                <div class="flex align-items-center justify-content-center">
                    <Button class="flex align-items-center justify-content-center " size="small" label="Save" icon="pi pi-save" severity="success" @click="handleSave"/>
                </div>
            </div>
        </template>
 </Dialog>


</template>

<script>
import Dialog from 'primevue/dialog'
import Navbar from '../header/headerNavbar.vue'
import Card from 'primevue/card'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { getTermsAndConditions, getCategories, createTermsAndConditions, deleteTermAndCondition } from '@/services/apiService'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea'
// import Dropdown from 'primevue/dropdown'
import TreeSelect from 'primevue/treeselect'
import SideBar from '../sidebar/sideBarTwo.vue'


export default {
    components:{ 
        Navbar,
        SideBar,
        Dialog,
        Card,
        DataTable,
        Column,
        Button,
        Textarea,
        // Dropdown,
        TreeSelect
    },
    
    data(){
        return{
            dialogVisibility:false,
            termsConditions:null,
            categories:null,
            selectedCategory:null,
            content:null,
            deleteDialogVisibility:false,
            templateId:null,
            parent:null,
            child:null,
            flag:0,
            label:null,
        }
    },

    methods:{
        getData(){
            this.getCategoriesList();
            getTermsAndConditions([]).then((res)=>{
                res.data.every(e => {
                    this.categories.every(p => {
                        this.flag = 0;
                        if(p.key==e.category){
                            this.label = p.label;
                            return false;
                        }
                        p.children.every(ch => {
                            if(ch.key==e.category){
                                this.label = ch.label;
                                this.flag = 1;
                                return false;
                            }
                            return true;
                        });
                        if(this.flag==1)
                            return false;
                        return true;
                    });

                    e.category = this.label;
                    return true;
                });


                this.termsConditions = res.data;
            }).catch((error)=>this.showErrorToast(error.response.data));
        },
        getCategoriesList(){
            getCategories().then((res) => {
                this.categories = res.data;
            }
            ).catch((error)=>this.showErrorToast(error.response.data));
        },
        showDialog(){
            this.dialogVisibility = true;
        },
        hideDialog(){
            this.dialogVisibility = false;
            this.selectedCategory = null;
            this.content = null;
        },
        showDeleteDialog(id){
            this.deleteDialogVisibility = true;
            this.templateId = id;
        },
        hideDeleteDialog(){
            this.deleteDialogVisibility = false;
        },
        handleSave(){
            if(this.content==null || this.content=='' || this.selectedCategory==null){
                return this.showErrorToast({'message': 'Content/Category cannot be empty!'})
            }
            const data = {
                'content':this.content,
                'category':this.selectedCategory,
            }


            createTermsAndConditions(data).then((res)=>{
                this.showSuccessToast(res.data);
                this.hideDialog();
                this.getData();
            }).catch((error)=>this.showErrorToast(error.response.data))
        },
        deleteItem(){
            deleteTermAndCondition(this.templateId).then((res)=>{
                this.showSuccessToast(res.data);
                this.hideDeleteDialog();
                this.getData();
            }).catch((error)=>this.showErrorToast(error.response.data));
        },  
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 3000 });
        },   
        showErrorToast(error){
               this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 5000 });
        },
    },

    mounted(){
        this.getData();
        this.getCategoriesList();
    }
}
</script>

<style>

</style>