<template>
<Toast/>
<Navbar/>
<SideBar/>
 
<div class="card main-content">
    <div class="progress-spinner" v-if="loadingStatus">
        <ProgressSpinner></ProgressSpinner>
    </div>
    <div class="surface-ground ">
<div class="grid">
        
            <div class="col-7">
                <Card style="height:100%;">
                     <template #header >
                        <div class="flex flex-wrap align-items-center justify-content-between">
                            <span class="text-base text-600 font-bold px-4 pt-4">Department PO Details</span><span class="px-4 pt-4"><Button class="p-button-sm" size="small" :label="btnText" icon="pi pi-save" severity="success" @click="handleSave"/></span>
                            <Divider/>
                        </div>
                       
                              
                            <div class="formgrid grid">
                                <div class="field col px-4">
                                    <label class="font-bold block" for="poNo">PO No.:</label>
                                    <InputText v-model="poNo" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full" :readonly="readonlyStatus"/>
                                </div>
                                <div class="field col">
                                    <label class="font-bold block" for="poDate">PO Date:</label>
                                    <Calendar showIcon dateFormat="dd/mm/yy" v-model="poDate" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full pr-3" :readonly="readonlyStatus"/>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <!-- <div class="field col px-4">
                                    <label class="font-bold block" for="poValue">PO Value:</label>
                                    <InputNumber class="surface-overlay w-full" v-model="poValue" mode="currency" currency="INR" currencyDisplay="code" locale="en-IN"/>
                                </div> -->
                                <div class="field col-6 px-4">
                                    <label class="font-bold block" for="poValue">Delivery Period (<small> <b> Days </b></small>):</label>
                                    <InputNumber class="surface-overlay w-full" v-model="deliveryPeriod" :readonly="readonlyStatus"/>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col px-4">   
                                    <label class="font-bold block" for="paymentTerms">Payment Terms:</label>                             
                                    <Textarea class="surface-overlay w-full" v-model="paymentTerms" rows="5" :readonly="readonlyStatus"/>
                                </div>
                            </div>

                            <div class="formgrid grid">
                                <div class="field col px-4">   
                                    <label class="font-bold block" for="paymentTerms">Other Clause:</label>                             
                                    <Textarea class=" w-full" v-model="otherclause" rows="5" :readonly="readonlyStatus"/>
                                </div>
                            </div>

                            <label class="font-bold block mx-3 my-2" v-if="poItems.length>0" for="">ROC Items:</label>

                            <div class="grid">
                                <div class="px-3">
                                <PoItem v-for="(item, index) in poItems" :key="item.id" :index="index" :itemId="item.id" :name="item.name" :rate="item.rate" :packing="item.packing" @bind-item="bindData"/>
                                </div>
                            </div>

                            <hr v-if="poItems.length>0">
                            

                            <label v-if="manualPoItems.length>0" class="font-bold block mx-3 mb-2 mt-4" for="">Manually Added Items:</label>

                            <div class="grid">
                                <div class="px-3">
                                <ManualPoItem v-for="(item, index) in manualPoItems" :key="item.id" :index="index" :itemId="index" :name="item.name" :rate="item.rate" :packing="item.packing" :category="item.category" :refNo="item.refNo" :nonRocItemId="item.nonRocItemId" :categories="categories" @bind-manual-item="bindManualData"/>
                                </div>
                            </div>

                            <div class="flex flex-wrap align-items-center justify-content-between pt-3 px-4">
                                <div class="formgrid grid">
                                    <div class="field" >
                                            <Button v-if="storeStatus" class="px-3 py-2" severity="success" icon="pi pi-window-maximize" @click="showPoItemsDialog"/>
                                            <Button v-if="manualPoItems.length>0" class="ml-2 px-3 py-2" v-tooltip.top="'Delete last item from the list'" icon="pi pi-trash" size="small" severity="danger" @click="deleteManualItem" />          
                                    </div>
                                </div>
                                <div class="formgrid grid">
                                     <div class="field ml-2">
                                            <Button label="Non-ROC Item" icon="pi pi-plus" size="small" severity="info" @click="addManualItem()" />          
                                            <Dropdown class="ml-2" size="small" v-model="selectedNonRocItem" filter :options="nonRocItems" optionValue="id" optionLabel="name" placeholder="Select Item" @change="addNonRocItem()"/>
                                    </div>
                                </div>
                            </div>

                            <div class="flex justify-content-end flex-wrap pt-2 px-4">
                                <div class="formgrid grid">
                                    <div class="field">
                                            <label class="font-bold block" for="totalPoValue">Total Value: </label>
                                            <InputNumber v-model="poValue" mode="currency" currency="INR" currencyDisplay="code" locale="en-IN" readonly/>          
                                    </div>
                                </div>
                            </div>

                            <!-- store po items -->
                            <Dialog :visible="poItemsDialog" modal :closable="false" header="PO Items" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                                <DataTable :value="storePoItems" tableStyle="min-width: 30rem">
                                    <Column field="name" header="Item"></Column>
                                    <Column field="packing" header="Packing"></Column>
                                    <Column field="quantity" header="Quantity"></Column>
                                    <Column field="rate" header="Rate"></Column>
                                </DataTable>

                                <div class="flex justify-content-end flex-wrap">
                                    <Button class="flex align-items-center justify-content-center my-1" size="small" label="Close" severity="danger" rounded @click="hidePoItemsDialog"></Button>
                                </div>
                            </Dialog>
                            <!-- end store po items -->

                     </template>
                </Card>
            </div>
            <div class="col-5">
                <Card style="height:100%;">
                    <template #content >
                        <div v-if="itemType==1" class="flex flex-wrap align-content-center justify-content-between">
                            <span class="text-base text-600 font-bold px-4 pt-4">ROC</span>
                             <Dropdown class="" size="small" v-model="selectedDept" :options="departments" optionValue="id" optionLabel="name" placeholder="Select Department" @change="getRocs"/>
                            <Divider/>
                        </div>
                        <div v-if="itemType==1">
                            <!-- Roc list -->

                            <DataTable v-model:filters="parentFilter" :value="rocs" scrollable scrollHeight="400px" size="small" tableStyle="min-width: 80%" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]"
                                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id" @rowExpand="onRowExpand" @rowCollapse="onRowCollapse" >
                                    
                                <template #header>
                                    <div class="flex justify-content-end">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-search" />
                                            <InputText v-model="parentFilter['global'].value" placeholder="Keyword Search" />
                                        </span>
                                    </div>
                                </template>

                                <Column expander style="width: 5rem" />
                                <Column field="tender_name" header="Tender Name."></Column>
                                <Column field="department.shortCode" header="Department"></Column>
                                <Column field="contract_no" header="ROC No."></Column>
                                <template #expansion="slotProps">
                                    <div class="p-3">
                                        <h3>{{slotProps.data.tender_name}}: Tender items </h3>
                                        <DataTable size="small" v-model:filters="filters" :value="slotProps.data.roc_details" v-model:selection="selectedItems" @row-select="addItem" @row-unselect="deleteItem" @row-select-all="addAllItem" @row-unselect-all="deleteAllItem">
                                            <template #header>
                                                <div class="flex justify-content-end">
                                                    <span class="p-input-icon-left">
                                                        <i class="pi pi-search" />
                                                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                                                    </span>
                                                </div>
                                            </template>
                                             <Column selectionMode="multiple" headerStyle="width: 3rem" ></Column>
                                            <Column field="item_name" header="Item"></Column>
                                            <Column field="item_rate" header="Rate">
                                                <template #body="slotProps">
                                                    {{ formatCurrency(slotProps.data.item_rate) }}
                                                </template>
                                            </Column>
                                            <Column field="category.label.name" header="Category"></Column>
                                            <Column field="packing" header="Packing"></Column>
                                            <Column field="manufacturer.name" header="Manufacturer"></Column>
                                        </DataTable>
                                    </div>
                                </template>
                                <template #footer> In total there are {{ rocs ? rocs.length : 0 }} ROCs. </template>
                            </DataTable>
                            
                        </div>

                        <div v-if="itemType==0">
                                <h3>
                                Note:Select ROC item option to enable this section.    
                                </h3>
                        </div>
                     </template>
                </Card>
            </div>
       
        </div>
    </div>
</div>

</template>

<script>
import Toast from 'primevue/toast'
import Navbar from '../header/headerNavbar.vue'
import Card from 'primevue/card'
import { getRocList, getDepartments, createDeptPO, getCategories, getNonRocItems } from '../../services/apiService'
import Divider from 'primevue/divider'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dropdown from 'primevue/dropdown'
import InputText from 'primevue/inputtext'
import Calendar from 'primevue/calendar'
import InputNumber from 'primevue/inputnumber'
import Textarea from 'primevue/textarea'
import PoItem from './deptPoItem.vue'
import Button from 'primevue/button'
import { FilterMatchMode } from 'primevue/api'
import ManualPoItem from './deptManualPoItem.vue'
import { useDeptPoStore } from '../../stores/deptPoStore'
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
// import TreeSelect from 'primevue/treeselect'
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
    components:{ 
        Toast, 
        Navbar, 
        SideBar,
        Card, 
        Divider, 
        DataTable, 
        Column, 
        Dropdown, 
        InputText, 
        Calendar, 
        InputNumber, 
        Textarea, 
        PoItem, 
        Button,
        ManualPoItem,
        Dialog,
        ProgressSpinner
        // TreeSelect
        },

    data(){
        return{
            rocs:null,
            btnText:'Save PO',
            expandedRows: [],
            selectedItems:'',
            selectedDept:'',
            departments:null,
            deptPoId:null,
            poNo:null,
            poDate:null,
            rocRef:null,
            poValue:null,
            deliveryPeriod:null,
            paymentTerms:null,
            otherclause:'',
            itemType:1,
            flag:false,
            deptStatus:true,
            poItems:[],
            manualPoItems:[],
            itemId: '',
            id:'',
            name: '',
            rate: '',
            selectedCategory: '',
            packing: '',
            quantity:null,
            subTotal:null,
            refNo:'',
            totalValue: 0,
            nonRocItemId:null,
            manItemName:null,
            manItemRate:0,
            manItemPacking:null,
            manItemCategory:null,
            selectedNonRocItem:null,
            nonRocItems:[],
            categories:null,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                item_name: { value: null, matchMode: FilterMatchMode.IN },
                tender_name: { value: null, matchMode: FilterMatchMode.IN },
                contract_no: { value: null, matchMode: FilterMatchMode.IN },
            },
            parentFilter:{
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                tender_name: { value: null, matchMode: FilterMatchMode.IN },
                contract_no: { value: null, matchMode: FilterMatchMode.IN },
            },
            deptPoStore:null,
            storeStatus:false,
            storePoItems:[],
            poItemsDialog:false,
            disableStatus:false,
            readonlyStatus:false,
            loadingStatus:false,
        }
    },

    methods:{
        setPoData(po){
            this.storeStatus = true;
            this.deptPoId = po.id;
            
            this.poNo = po.po_no;
            let splitDate = po.po_date.split(/-/);
            let newDate = splitDate[2]+'-'+(splitDate[1])+'-'+splitDate[0]+'T00:00:10';
            this.poDate = new Date(newDate);

            this.deliveryPeriod = po.delivery_period;
            this.paymentTerms = po.payment_terms;
            this.otherclause = po.other_clause;
            this.rocRef = po.roc_refs;
            this.storePoItems = po.po_items;
            this.readonlyStatus = true;
        },
        clearStore(){
            const store = useDeptPoStore();
            this.storeStatus = false;
            store.$reset();

        },
        getDepts(){
            getDepartments().then((res)=> 
            {
                this.departments = res.data
            }
            ).catch((error)=>this.showErrorToast(error.response.data));
        },
        getRocs(){
            const query = '?departmentId[eq]='+this.selectedDept
            getRocList(query).then((res)=> 
            {
                this.rocs = res.data
            }
            ).catch((error)=>this.showErrorToast(error.response.data));
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
        },
        itemTypeStatus(){
            this.poItems = [];
            this.poValue = 0;
        },
        addItem(data){
            
            const dataItem = data.data;

            this.poItems.push({
                    id: dataItem.id,
                    name: dataItem.item_name,
                    rate: dataItem.item_rate,
                    packing: dataItem.packing,
                });
        
        },
        getCategoriesList(){
            getCategories().then((res)=>{
                this.categories = res.data;
            }).catch((error)=>this.showErrorToast(error.response.data));
        },
        getNonRocItemsList(){
            getNonRocItems().then((res)=>{
                this.nonRocItems = res.data;
            }).catch((error)=>this.showErrorToast(error.response.data));
        },
        addManualItem(){
            this.manualPoItems.push({
                    nonRocItemId:null,
                    manItemName: this.manItemName,
                    manItemRate: this.manItemRate,
                    manItemPacking: this.manItemPacking,
                });
            this.getCategoriesList();
        },
        addNonRocItem(){
            const item = this.nonRocItems.find((e)=> e.id==this.selectedNonRocItem);
            this.manualPoItems.push({
                nonRocItemId:item.id,
                name:item.name,
                rate:item.rate,
                packing:item.packing,
                category:item.category,
                refNo:item.refNo,
            });
            this.getCategoriesList();
        },
        addAllItem(data){
            data.data.forEach(element => {
                this.poItems.push({
                    id: element.id,
                    name: element.item_name,
                    rate: element.item_rate,
                    packing: element.packing
                });
            });
        },
        deleteAllItem(){
            this.poItems = [];
            this.poValue = 0;
        },
        bindData(itemId, itemData){
            const item = this.poItems.find((e) => e.id == itemId);
            item.name = itemData.itemName;
            item.rate = itemData.itemRate;
            item.packing = itemData.itemPacking;
            item.quantity = itemData.itemQuantity,
            item.subTotal = itemData.subTotal;
            this.getTotalValue();
        },
        bindManualData(itemId, itemData){
            const item = this.manualPoItems.at(itemId);      
            // item.nonRocItemId = itemData.id,      
            item.manItemName = itemData.name;
            item.manItemRate = itemData.rate;
            item.manItemPacking = itemData.packing;
            item.manItemCategory = itemData.itemCategory;
            item.quantity = itemData.quantity;
            item.subTotal = itemData.subTotal;
            item.refNo = itemData.refNo;
            item.non_roc_id = itemData.nonRocItemId;
            item.manufacturer_id = null;
            this.getTotalValue();

        },
        getTotalValue(){
            this.poValue = 0;
            this.poItems.forEach(element=>{
                this.poValue += element.subTotal;
            });
            this.manualPoItems.forEach(element=>{
                this.poValue += element.subTotal;
            });
        },
        deleteItem(data){
            const itemIndex = this.poItems.findIndex((e)=>e.id ==data.data.id);
            this.poValue -= this.poItems[itemIndex].subTotal;
            this.poItems.splice(itemIndex, 1);
            return this.poItems;
        },
        deleteManualItem(){
            this.manualPoItems.pop();
            this.getTotalValue();
        },
        showPoItemsDialog(){
            this.poItemsDialog = true;
        },
        hidePoItemsDialog(){
            this.poItemsDialog = false;
        },
        handleSave(){

            if(this.poDate==null || this.poNo==null || this.deliveryPeriod==null || this.paymentTerms==null || this.otherclause==null){
                return this.showErrorToast({'message':'Date/PO No./Delivery-Period/Payment-Terms/Other-clause fields are required!'});
            }            
                
            if(this.poItems.length==0 && this.manualPoItems==0){
                return this.showErrorToast({'message':'Please add atleast one(1) item to PO'});
            }
            const data = {
                'deptPoId':this.deptPoId,
                'prevRocRef':this.rocRef,
                'poNo':this.poNo,
                'poDate':this.poDate.getDate()+'-'+(this.poDate.getMonth()+1)+'-'+this.poDate.getFullYear(),
                'poValue':this.poValue,
                'deliveryPeriod':this.deliveryPeriod,
                'paymentTerms':this.paymentTerms,
                'otherclause':this.otherclause,
                'poItems':this.poItems,
                'manualPoItems':this.manualPoItems
            }

            this.loadingStatus = true;
            createDeptPO(data).then((res)=> 
                {
                    if(this.deptPoId){
                        this.showSuccessToast({'message':'PO Updated successfully'});
                        this.clearStore();
                    }
                    else{
                        this.showSuccessToast(res.data);
                    }
                    this.loadingStatus = false;
                    this.$router.push('dept-po-list');
                }
            ).catch((error)=>{
                this.showErrorToast(error.response.data);
                this.loadingStatus = false;
            });
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 5000 });
        },   
        showErrorToast(error){
               this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 5000 });
        },
    },
    mounted(){
        this.getDepts();
        this.getNonRocItemsList();

        const store = useDeptPoStore();
        if(store.data){
            this.btnText = 'Update PO';
            this.setPoData(store.data);
        }
    },
    beforeUnmount(){
        const store = useDeptPoStore();
        store.$reset();
    }
}
</script>

<style scoped>
.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}

</style>