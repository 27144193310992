<template>
  <Navbar/>
  <SideBar/>
  <div class="card main-content">
    <!-- <div class="card"> -->
        <div class="progress-spinner" v-if="spinnerLoadingStatus">
            <ProgressSpinner></ProgressSpinner>
        </div>
        <div class="surface-ground ">
            <Card>
              <template #content>
                <DataTable :value="invoices" :size="small" :loading="loadingStatus" v-model:filters="filters" tableStyle="min-width: 50rem" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id" @rowExpand="onRowExpand" @rowCollapse="onRowCollapse">

                  <template #header >
                      <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                          <span class="text-xl text-900 font-bold">Invoices</span>
                          <div class="flex justify-content-end">
                              <InputText v-model="filters['global'].value" placeholder="Keyword Search"  />
                              <!-- <Button class="p-button-sm" size="small" label="New Invoice" icon="pi pi-plus" severity="info" raised @click="showDialog"/> -->

                              <router-link to="/new-invoice" rel="noopener">
                                  <Button class="p-button-sm ml-2" size="small" label="New Invoice" icon="pi pi-plus" severity="info" raised />
                              </router-link>
                          </div>
                      </div>
                  </template>

                  <Column expander style="width: 5rem"/>
                  <Column field="invoiceNo" header="Invoice No."/>
                  <Column field="from" header="OEM"/>
                  <Column field="invoiceDate" header="Date"/>
                  <Column field="basicAmt" header="Basic">
                    <template #body="slotProps">
                        {{formatCurrency(slotProps.data.basicAmt)}}
                    </template>
                  </Column>
                  <Column field="igst" header="IGST">
                    <template #body="slotProps">
                        {{formatCurrency(slotProps.data.igst)}}
                    </template>
                  </Column>
                  <Column field="discAmt" header="Disc. Amt">
                    <template #body="slotProps">
                        {{formatCurrency(slotProps.data.discAmt)}}
                    </template>
                  </Column>
                  <Column field="totalValue" header="Total">
                    <template #body="slotProps">
                        {{formatCurrency(slotProps.data.totalValue)}}
                    </template>
                  </Column>             

                  <template #expansion="slotProps">
                    <DataTable :value="slotProps.data.invoiceItems" :size="small">
                      <Column field="name" header="Item"/>
                      <Column field="hsn" header="HSN"/>
                      <Column field="quantity" header="Quantity"/>
                      <Column field="batchNo" header="Batch"/>
                      <Column header="Mfg/Expiry">
                        <template #body="slotProps">
                          <label class="text-green-500">{{slotProps.data.mfgDate }}</label>/<label class="text-red-500">{{ slotProps.data.expDate}}</label> 
                        </template>
                      </Column>
                      <!-- <Column field="expDate" header="Expiry"/> -->
                      <Column header="Part/Model">
                        <template #body="slotProps">
                          {{slotProps.data.partNo}}/{{slotProps.data.modelNo}}
                        </template>
                      </Column>
                      <Column field="rate" header="Rate"/>
                      <Column field="oemRate" header="PO Rate"/>
                      <Column field="gstRate" header="GST(%)"/>
                      <Column field="gst" header="GST"/>
                      <Column field="discAmt" header="Discount"/>
                      <Column field="subTotal" header="Total Incl-GST"/>
                    </DataTable>
                  </template>

                </DataTable>
              </template>
            </Card>
        </div>
    <!-- </div> -->
  </div>
</template>

<script>
import Card from 'primevue/card'
import { getInvoices } from '@/services/apiService'
// import Card from 'primevue/card'
import Navbar from '../header/headerNavbar.vue'
import ProgressSpinner from 'primevue/progressspinner'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { FilterMatchMode } from 'primevue/api'
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
  
  components:{
    Navbar,
    SideBar,
      Card,
      ProgressSpinner,
      DataTable,
      Column,
      Button,
    InputText,
    },

    data(){
      return{
        spinnerLoadingStatus:false,
        loadingStatus:false,
        expandedRows:[],
        invoices:[],
        filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                invoiceNo: { value: null, matchMode: FilterMatchMode.IN },
                from: { value: null, matchMode: FilterMatchMode.IN },
            },
      }
    },

    methods:{
      getInvoiceList(){
        this.spinnerLoadingStatus = true;
        getInvoices().then((res)=>{
          this.invoices = res.data;
          this.spinnerLoadingStatus = false;
        }).catch((error)=>{
          this.showErrorToast(error.response.data);
          this.spinnerLoadingStatus = false;
        })
      },
      formatCurrency(value) {
          return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
      },
      showErrorToast(error){
          this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
      },
      showSuccessToast(data){
          this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
      },
    },

    mounted(){
      this.getInvoiceList();
    }
}
</script>

<style scoped>

.progress-spinner {
    position: fixed;
    z-index: 999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

/* Transparent Overlay */
.progress-spinner:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0.53);
}

</style>