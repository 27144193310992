<template>
  <div class="card flex flex-wrap p-fluid mb-3">
    <div class="grid">
        <div class="col">
            <strong><label>HSN</label></strong>
            <InputText type="text" v-model="hsn" variant="filled" readonly></InputText>
        </div>
        <div class="col">
            <strong><label>UOM</label></strong>
            <InputText type="text" v-model="packing" variant="filled" @change="bindData"/>
        </div>
        <div class="col">
            <strong><label>Batch</label></strong>
            <InputText type="text" v-model="batchNo" variant="filled" @change="bindData"/>
        </div>
        <div class="col">
            <strong><label>Mfg/Exp</label></strong>
            <InputText type="text" v-model="mfgExp" variant="filled" readonly/>
            </div>
        <div class="col">
            <strong><label>Part</label></strong>
            <InputText type="text" v-model="partNo" variant="filled" @change="bindData"/>
        </div>
        <div class="col">
            <strong><label>Model</label></strong>
            <InputText type="text" v-model="modelNo" variant="filled" @change="bindData"/>
        </div>
        <div class="col">
            <strong><label>DC Qty</label></strong>
            <InputNumber v-model="qty" variant="filled" @input="updateAvailable($event), bindData()"/>
        </div>
        <div class="col">
            <strong><label>Available</label></strong>
            <InputNumber v-model="avalQty" variant="filled" readonly/>
        </div>
        <!-- <div class="col">
                <label>Del</label><br>
            <Button icon="pi pi-trash" size="small" severity="danger" @click="delete"/>
        </div> -->
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
// import Button from 'primevue/button'

export default {
    components:{
        InputText,  
        InputNumber,
        //Button,
    },
    
    props:{
        item:Object
    },

     emits: ['bind-item'],

    data(){
        return{
            hsn: null,
            packing: null,
            batchNo: null,
            mfgExp:  null,
            partNo: null,
            modelNo: null,
            totalQty:0,
            qty: 0,
            avalQty: 0,
        }
    },

    methods:{
        updateAvailable(event){
            this.qty = event.value;
            this.avalQty = this.totalQty - event.value;
        },
        bindData(){
                const itemdata = {
                    itemUom:this.packing,
                    itemBatch:this.batchNo,
                    itemPart:this.partNo,
                    itemModel:this.modelNo,
                    itemQuantity:this.qty,
                    itemAvailQty:this.avalQty,
            }

            this.$emit('bind-item', this.item.id, itemdata);
        }
    },

    mounted(){
        
        this.hsn = this.item.hsn;
        this.packing = this.item.packing
        this.batchNo = this.item.batchNo
        this.mfgExp = this.item.expDate!=null ? this.item.mfgDate+'/'+this.item.expDate : null,
        this.partNo = this.item.partNo
        this.modelNo = this.item.modelNo
        this.qty = 0;
        this.totalQty = this.item.availableQty
        this.avalQty = this.item.availableQty;
        this.bindData();

    }

}
</script>

<style>

</style>