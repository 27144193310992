<template>
  <!-- <table class="doc-width"> -->
    <thead >
            <tr >
                <td style="margin-bottom:10px;">
                    <div class="header text-teal-700"> <p class="berlin-font"> <label style="font-size:35px; font-weight: 500" > ARENGH MEDICAL SUPPLIER</label><br>
                        <label style="font-weight: 500">Lower Chandmari, Tura, District - West Garo Hills,<br>
                        Meghalaya – 794001<br>
                        DL No: WGH/WL-1321, 1322, GST: 17ABEFA6546N1Z1<br>
                        E-Mail: arenghmedicalsupplier@gmail.com<br>
                        </label>
                        </p>
                    </div>

                    <div style="float:right; padding:3px;">
                    <Image src="/assets/images/logo.png" style="" alt="AMS-Logo" width="90"/>
                    </div>
                </td>
            </tr>
            <tr >
                <td colspan="2" class="pt-4">
                    <!-- <div style="padding-top:10px;" > -->
                        <div >
                        <!-- <Divider style="color:black"/> -->
                        <hr style="size:5px;">
                    </div>
                </td>
            </tr>
    </thead>
</template>

<script>
import Image from 'primevue/image'
// import Divider from 'primevue/divider'

export default {
components:{ 
    Image,
    // Divider,
},
}
</script>

<style scoped>

@import url('https://fonts.cdnfonts.com/css/berlin-sans-fb-demi');

.header {
    position: fixed;
        /* top: 0; */
        /* margin-top: 53px; */
        height:20%;
        display: block;
        margin-bottom: 10%;
    }

@media print{
    .header {
        position: fixed;
        top: 0;
        height:20%;
        display: block;
    }


.berlin-font{
    font-family: 'Berlin Sans FB Demi', sans-serif;
}

}

</style>