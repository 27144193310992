<template>
  <div class="card flex flex-wrap p-fluid px-2">

                    <div class="formgrid grid">
                      <div class="field col">
                        <label for="firstname2">Name</label>
                        <InputText v-model="itemName" @change="bindData"/>
                      </div>
                      <div class="field col">
                        <label for="firstname2">Packing</label>
                        <InputText v-model="itemPacking"  @change="bindData"/>
                      </div>
                      <div class="field col">
                        <label for="firstname2">Category</label>
                        <TreeSelect v-model="itemCategory" :options="categories" placeholder="Select Category" class="w-full" @change="bindData"/>
                      </div>
                      <div class="field col">
                        <label for="firstname2">Rate</label>
                        <InputNumber v-model="itemRate" inputId="itemRate" @input="getRate($event),bindData()" :minFractionDigits="2" :maxFractionDigits="3"/>
                      </div>
                      <div class="field col">
                        <label for="firstname2">Qty</label>
                        <InputNumber v-model="itemQuantity" @input="subtotal($event); bindData()" />
                      </div>
                      <div class="field col">
                        <label for="firstname2">Sub Total</label>
                        <InputNumber v-model="subTotal" @change="bindData" readonly/>
                      </div>
                      <div class="field col">
                        <label for="firstname2">Ref No.</label>
                        <InputText v-model="itemRefNo"  @change="bindData"/>
                      </div>

                    </div>

                  </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import TreeSelect from 'primevue/treeselect'
// import Dropdown from 'primevue/dropdown'
// import Button from 'primevue/button'

export default {
  name:'RocItem',
    components:{ 
      InputText, 
      InputNumber, 
      TreeSelect,
      },
 
      props:{
        itemId:Number,
        categories:Array,
        name:String,
        rate:Number,
        packing:String,
        // manufacturers:Array
        category:Object,
        refNo:null,
        nonRocItemId:[
          String, Number
        ]
      },

    emits: ['bind-manual-item', 'delete'],

    data(){
      return{
          itemName:this.name,
          itemRate:this.rate,
          itemPacking:this.packing,
          subTotal:0,
          itemQuantity:0,
          itemRefNo:this.refNo,
          // itemCategory:!this.category.length ? null : this.category.key,
          itemCategory:null
      }
    },

    methods:{

      bindData(){
       const itemdata = {
              name:this.itemName,
              rate:this.itemRate,
              packing:this.itemPacking,
              quantity:this.itemQuantity,
              subTotal: this.subTotal, 
              itemCategory:this.itemCategory,
              refNo:this.itemRefNo,
              nonRocItemId:this.nonRocItemId,
        }
        this.$emit('bind-manual-item', this.itemId, itemdata);
      },
      getRate(event){
        this.itemRate = event.value;
      },
      subtotal(event){
        this.itemQuantity = event.value;
        this.subTotal = this.itemRate * event.value
      }
    },

    mounted(){
        if(this.category){
          this.itemCategory = {[this.category.key]:true}
        }
    },
  
}
</script>

<style>

</style>