<template>
  <div class="card flex flex-wrap p-fluid my-3">
    <div class="grid">
        <div class="col">
            <strong><label>Item</label></strong>
            <InputText type="text" v-model="itemName" variant="filled" readonly></InputText>
        </div>
        <div class="col">
            <strong><label>HSN</label></strong>
            <InputText type="text" v-model="hsn" variant="filled" readonly></InputText>
        </div>
        <div class="col">
            <strong><label>GST(%)</label></strong>
            <InputText type="text" v-model="gstRate" variant="filled" @change="bindData"/>
        </div>
        <div class="col">
            <strong><label>Sr./Bch</label></strong>
            <InputText type="text" v-model="sr_batch" variant="filled" @change="bindData"/>
        </div>
        <div class="col">
            <strong><label>Mfg/Mdl</label></strong>
            <InputText type="text" v-model="mfg_model" variant="filled" readonly/>
            </div>
        <div class="col">
            <strong><label>Exp</label></strong>
            <InputText type="text" v-model="exp" variant="filled" @change="bindData"/>
        </div>
        <div class="col">
            <strong><label>Pkg</label></strong>
            <InputText type="text" v-model="packing" variant="filled" @change="bindData"/>
        </div>
        <div class="col">
            <strong><label>Qty</label></strong>
            <InputNumber v-model="quantity" variant="filled" @input="updateAvailable($event), bindData()"/>
        </div>
        <div class="col">
            <strong><label>Rate(x-gst)</label></strong>
            <InputNumber v-model="rate" variant="filled" readonly minFractionDigits="4" maxFractionDigits="9"/>
        </div>
        <div class="col">
            <strong><label>Amt</label></strong>
            <InputNumber v-model="subTotal" variant="filled" minFractionDigits="4" maxFractionDigits="9" readonly/> 
        </div>
        <!-- <div class="col">
                <label>Del</label><br>
            <Button icon="pi pi-trash" size="small" severity="danger" @click="delete"/>
        </div> -->
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';

export default {
    
    components:{
        InputText,
        InputNumber,
    },


    props:{
        item:Object,
    },

    data(){
        return{
            itemName:null,
            hsn: null,
            gstRate: 0,
            gstAmt:0,
            sr_batch: null,
            mfg_model: null,
            exp: null,
            packing: null,
            quantity: 0,
            rate: 0,
            subTotal: 0,
        }
    },

    methods:{
        setItemData(){
            this.itemName = this.item.name,
            this.hsn = this.item.hsn;
            this.gstRate = this.item.gstRate;
            if(this.item.batch_no){
                this.sr_batch = this.item.batch_no;
            }
            else{
                this.sr_batch = this.item.partNo;
            }
            if(this.item.expDate!=null || this.item.mfgDate!=null){
                this.mfg_model = this.item.mfgDate;
                this.exp = this.item.expDate;
            }
            else{
                this.mfg_model = this.item.modelNo;
            }
            this.packing = this.item.deptPacking;
            this.quantity = this.item.qty;
            this.rate = this.item.deptRate;
            this.subTotal = this.quantity * this.rate;
            this.calculateGstAmt();

            this.bindData();
        },
        bindData(){
                const itemdata = {
                    // name:this.itemName,
                    // hsn:this.hsn,
                    gstRate:this.gstRate,
                    qty:this.quantity,
                    deptRate:this.item.deptRate,
                    subTotal:this.subTotal,                   
                    gstAmt:this.gstAmt,
            }

            this.$emit('bind-item', this.item.id, itemdata);
        },
        updateAvailable(event){
            this.quantity = event.value;
            this.subTotal = event.value * this.rate;
            this.calculateGstAmt();
        },
        calculateGstAmt(){
            this.gstAmt = (this.subTotal / 100) * this.gstRate;
        },
    },

    mounted(){
        this.setItemData();        
    }

}
</script>

<style>

</style>