<template>
  <tr class="yaxis-padding">
    <td class="item-center">{{slNo}}</td>
    <td class="item-center">{{item}}</td>
    <!-- <td class="item-center">{{}}</td> -->
    <td class="item-center">{{location}}</td>
    <td class="item-center">{{quantity}}</td>
    
  </tr>
</template>

<script>
export default {

    props:{ 
        deliveryLocation:Array, 
        slNo:Number,
    },

    data(){
        return{
            item:this.deliveryLocation.item_name,
            quantity:this.deliveryLocation.location[0].quantity,
            location:this.deliveryLocation.location[0].location,
        }
    },

}
</script>

<style scoped>
th, td {
  border: 0.5px solid #000000;
  border-collapse: collapse;
}

.yaxis-padding{
  height: 2em;
  vertical-align: middle;
}

.item-center {
    text-align: center;
}
</style>