<template>
  <div class="card flex flex-wrap p-fluid">
                    <div class="formgrid grid">
                      <!-- <div class="field col" style="widht:5%">
                        <label for=""></label>
                        <div><label style="width:5%;">{{slNo}}</label></div>
                      </div> -->
                      <div class="field col">
                           <label for="itemName">{{slNo}}.&nbsp;&nbsp; Name </label> <br>
                              <InputText v-model="itemName" @change="bindData"/>
                      </div>
                    
                      <div v-if="newColumnStatus" class="field col">
                           <label for="itemName">{{columnHead}}</label>
                            <InputText v-model="newColumnData" @change="bindData"/>
                      </div>
                    
                      <div class="field col">
                          <label for="itemPacking"> UOM </label>
                          <InputText v-model="itemPacking"  @change="bindData"/>
                      </div>

                      <div class="field col">
                        <label> NOPs</label>
                        <InputNumber v-model="nop" @input="updateQty(), updateNop($event), bindData()"/>
                      </div>

                      <div class="field col">
                        <label> Pkg Size</label>
                        <InputNumber v-model="pckQty" @input="updatePckSize($event), updateQty(), bindData()"/>
                      </div>
                    
                      <div class="field col">
                          <label for="itemRate" > Quantity </label>
                          <!-- @input="subTotal(), bindData()" -->
                          <InputNumber v-model="itemQuantity"  @input="bindData()" readonly/>
                      </div>
                    
                      <div class="field col">
                          <label for="itemRate" > Rate (Ex-GST) </label>
                          <InputNumber v-model="itemRate" inputId="itemRate" @input="updateRate($event), bindData()" :minFractionDigits="2" :maxFractionDigits="3"/>
                      </div>
                    
                      <div class="field col">
                          <label for="itemGst" class="mb-2"> GST(%) </label>
                          <InputNumber v-model="itemGst" @input="getGst($event),bindData()"/>
                      </div>
                    
                      <div class="field col">
                        <label for="itemGst" class="mb-2"> Prev Rates </label>
                          <Button severity="success" icon="pi pi-window-maximize" @click="showDialog"/>
                          
                      </div>
                    
                      <div class="field col">
                          <label for="itemSubTotal" class="mb-2"> Sub Total </label>
                          <InputNumber v-model="itemSubTotal" readonly/>
                      </div>
                    </div>
                    
                  </div>
                  <Dialog :visible="dialogVisibility" modal :closable="false" :header="itemName" :style="{ width: '60rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }" :dismissableMask="true">
                    <DataTable :value="rateList" tableStyle="min-width: 50rem" paginator :rows="5" :rowsPerPageOptions="[5, 10, 20, 50]"
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}" dataKey="id">
                        <Column field="po_no" header="PO No."></Column>
                        <Column field="rate" header="Previous Rates"></Column>
                        <Column field="created_at" header="PO Date"></Column>
                    </DataTable>
                    <template #footer>
                            <div class="flex flex-row-reverse flex-wrap">
                                <div class="flex align-items-center justify-content-center ">
                                    <Button class="flex align-items-center justify-content-center " size="small" label="Close" raised text severity="danger" @click="hideDialog"></Button>
                                </div>
                            </div>
                        </template>
                  </Dialog>
</template>

<script>
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Dialog from 'primevue/dialog'

export default {
  name:'RocItem',
    components:{ 
        InputText, 
        InputNumber, 
        Button,
        DataTable,
        Column,
        Dialog
        },
 
      props:{
        index:Number,
        name:String,
        rate:Number,
        packing:String,
        item:Object,
        rates:Array,
        newColumnStatus:Boolean,
        columnHead:String,
      },

    emits: ['bind-item'],

    data(){
      return{
          slNo:this.index+1,
          itemId:this.item.id,
          itemName:this.name,
          itemRate:this.item.rate,
          itemPacking:this.packing,
          nop:this.item.quantity,
          pckQty:1,
          itemQuantity:this.item.quantity,
          itemGst:this.item.gst,
          itemSubTotal:0,
          rateList:this.rates,
          dialogVisibility:false,
          newColumnData:null,
      }
    },

    methods:{

      bindData(){
       const itemdata = {
              itemId:this.itemId,
              itemName:this.itemName,
              itemNewColData: this.newColumnStatus==true ? this.columnHead+'-'+this.newColumnData : null,
              itemRate:this.itemRate,
              itemPacking:this.itemPacking,            
              itemNop:this.nop,
              itemPckQty:this.pckQty,
              itemQuantity:this.itemQuantity,
              itemGst:this.itemGst,
              itemSubtotal:this.itemSubTotal,
        }
        
        this.$emit('bind-item', itemdata);
      },
      updateNop(event){
        this.nop = event.value;
        this.subTotal();
      },
      updatePckSize(event){
        this.pckQty = event.value;
      },
      updateQty(){
        this.itemQuantity = this.nop * this.pckQty;
      },
      updateRate(event){
        this.itemRate = event.value;
        this.subTotal();
      },
      getGst(event){
        this.itemGst = event.value;
      },
      subTotal(){       
        this.itemSubTotal = this.itemRate * this.nop;
      },
      showDialog(){
            this.dialogVisibility = true;
        },
        hideDialog(){
            this.dialogVisibility = false;
        },
    },
    mounted(){
      this.subTotal();
      this.bindData();
    }
  
}
</script>

<style>

</style>