export function numberToWord(n) {
    if (n < 0)
        return false;
    
    // Arrays to hold words for single-digit, double-digit, and below-hundred numbers
    const single_digit = ['', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine']
    const double_digit = ['Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen']
    const below_hundred = ['Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety']
    
    if (n === 0) return 'Zero';
    
    // Recursive function to translate the number into words
    function translate(n) {
        let word = "";
        if (n < 10) {
            word = single_digit[Math.trunc(n)] + ' ';
        } else if (n < 20) {
            word = double_digit[Math.trunc(n) - 10] + ' ';
        } else if (n < 100) {
            let rem = translate(n % 10);
            word = below_hundred[(n - n % 10) / 10 - 2] + ' ' + rem;
        } else if (n < 1000) {
            word = single_digit[Math.trunc(n / 100)] + ' Hundred ' + translate(n % 100);
        } else if (n < 100000) {
            word = translate(parseInt(n / 1000)).trim() + ' Thousand ' + translate(n % 1000);
        } else if (n < 10000000) {
            word = translate(parseInt(n / 100000)).trim() + ' Lakh ' + translate(n % 100000);
        } else {
            word = translate(parseInt(n / 10000000)).trim() + ' Crore ' + translate(n % 10000000);
        }
        return word;
    }
    
    // Get the result by translating the given number
    
    let num = Math.abs(n);
    
    let num2 = num - Math.floor(num)
    num2 = +(Math.round(num2 + "e+"+2)  + "e-"+2);

    let decimal = null;
    decimal = translate(num2 * 100);

    let whole = translate(num);

    let result = whole;
    
    if(decimal.length > 1){
        result = whole +' and '+decimal+'paise';
    }

    return result;
}
