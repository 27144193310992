<template>
  
    <div class="sm:col-12 md:col-12 lg:col-12">
        <div class="surface-card shadow-2 p-3 border-round-lg mt-2 ml-2">
            <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl ml-2">
                    Filter Options
                </div>
            </div>
            <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center w-full">
                  <div class="col">
                    <label class="mt-2">Order status:</label>
                    <Dropdown class="w-full mt-2" v-model="filterAttrib1" placeholder="Select an option" :options="orderStatus" optionLabel="label" optionValue="id" showClear/>
                  </div>
                  <div class="col">
                    <label class="mt-2">Billing status:</label>
                    <Dropdown class="w-full mt-2" v-model="filterAttrib2" placeholder="Select an option" :options="orderBillingStatus" optionLabel="label" optionValue="id" showClear/>
                  </div>
                  <div class="col">
                    <label class="mt-2">Payment status:</label>
                    <Dropdown class="w-full mt-2" v-model="filterAttrib3" placeholder="Select an option" :options="orderPaymentStatus" optionLabel="label" optionValue="id" showClear/>
                  </div>
                  <div class="col">
                    <label class="mt-2">Start date:</label>
                    <Calendar class="w-full mt-2" v-model="startDate" placeholder="Select start date" />
                  </div>
                  <div class="col">
                    <label class="mt-2">End date:</label>
                    <Calendar class="w-full mt-2" v-model="endDate" placeholder="Select end date" />
                  </div>
                  <div class="col">
                    <Button label="Apply Filter" raised text severity="success" class="w-full mt-4" size="small"/>
                  </div>
                </div>
            </div>
        </div>
    </div>

    <div class="sm:col-12 md:col-12 lg:col-12">
        <div class="surface-card shadow-2 p-3 border-round-lg mt-2 ml-2">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl ml-2">
                    Orders details
                </div>
                <div class="flex align-items-center justify-content-center text-xl mr-2 mb-2">
                    <Button label="Download report" raised  size="small" style="background-color:#B46076; border-color:#B46076" />
                </div>
            </div>

            <div class="flex flex-wrap">
                <div class="flex align-items-center justify-content-center w-full">
                    <div class="col">
                        <DataTable :value="deptItemsArr" size="small" showGridlines :loading="tableLoading" v-model:filters="filters" tableStyle="min-width: 50rem" dataKey="id" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" scrollable scrollHeight="500px">
                            <Column header="Order No."></Column>
                            <Column header="Order date"></Column>
                            <Column header="Dept"></Column>
                            <Column header="PO Value"></Column>
                            <Column header="Order Status"></Column>
                            <Column header="Billing Status"></Column>
                            <Column header="Payment Status"></Column>
                        </DataTable>
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
// import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'

export default {
    components:{
        // InputText,
        Button,
        Dropdown,
        Calendar,
        DataTable,
        Column,
    },
    data(){
        return{
            filterAttrib1:null,
            orderStatus:[
                {label:'Not Inititated', id:1},
                {label:'Partially Completed', id:2},
                {label:'Completed', id:3},
            ],
            filterAttrib2:null,
            orderBillingStatus:[
                {label:'Not Billed', id:1},
                {label:'Partially Billed', id:2},
                {label:'100% Billed', id:3},
            ],
            filterAttrib3:null,
            orderPaymentStatus:[
                {label:'Not Received', id:1},
                {label:'Partially Received', id:2},
                {label:'Received 100%', id:3},
            ],
            startDate:null,
            endDate:null,
        }
    }
}
</script>

<style>

</style>