<template>
  <div class="card flex flex-wrap p-fluid">
                    <div class="col-4">
                      <div class="flex-auto">
                            <label for="itemName" class="font-bold mb-2"> Item Name </label>
                            <InputText v-model="itemName" @change="bindData"/>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="flex-auto">
                          <label for="itemPacking" class="font-bold mb-2"> Packing </label>
                          <InputText v-model="itemPacking"  @change="bindData"/>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="flex-auto">
                          <label for="itemRate" class="font-bold mb-2"> Rate </label>
                          <InputNumber v-model="itemRate" inputId="itemRate" @input="bindData" :minFractionDigits="2" :maxFractionDigits="3"/>
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="flex-auto">
                          <label for="itemRate" class="font-bold mb-2"> Quantity </label>
                          <InputNumber v-model="itemQuantity" @input="subtotal($event); bindData()" />
                      </div>
                    </div>
                    <div class="col-2">
                      <div class="flex-auto">
                          <label for="itemRate" class="font-bold mb-2"> Sub Total </label>
                          <InputNumber v-model="subTotal" inputId="subTotal" @change="bindData" readonly/>
                      </div>
                    </div>
                  
                  </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import InputNumber from 'primevue/inputnumber'
// import Dropdown from 'primevue/dropdown'
// import Button from 'primevue/button'

export default {
  name:'RocItem',
    components:{ 
      InputText, 
      InputNumber, 
      // Button 
      },
 
      props:{
        itemId:Number,
        name:String,
        rate:Number,
        packing:String,
        // manufacturers:Array
      },

    emits: ['bind-item', 'delete-item'],

    data(){
      return{
          itemName:this.name,
          itemRate:this.rate,
          itemPacking:this.packing,
          // itemManufacturer:null,
          selectedCategory:null,
          subTotal:0,
          itemQuantity:0,
      }
    },

    methods:{

      bindData(){
       const itemdata = {
              itemName:this.itemName,
              itemRate:this.itemRate,
              itemPacking:this.itemPacking,
              itemQuantity:this.itemQuantity,
              subTotal: this.subTotal, 
        }
        this.$emit('bind-item', this.itemId, itemdata);
      },
      subtotal(event){
        this.itemQuantity = event.value;
        this.subTotal = this.itemRate * event.value
      }
    },

    mounted(){
   
    },
  
}
</script>

<style>

</style>