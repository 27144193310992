
<template>
<Navbar/>
<SideBar/>

<div class="card main-content">
        <div class="surface-ground">
        <Card>
            
            <template #content>
            <DataTable :value="filteredRoc" :filters="parentFilter" :loading="loading" tableStyle="min-width: 50rem" paginator :rows="10" :rowsPerPageOptions="[5, 10, 20, 50]"
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
                    currentPageReportTemplate="{first} to {last} of {totalRecords}" v-model:expandedRows="expandedRows" dataKey="id"
                    @rowExpand="onRowExpand" @rowCollapse="onRowCollapse">
                    <template #header >
                        <div class="flex flex-wrap align-items-center justify-content-between gap-2">
                            <span class="text-xl text-900 font-bold">Roc List</span>
                            <span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <!-- <InputText v-model="parentFilter['global'].value" placeholder="Keyword Search" /> -->
                                <InputText v-model="filterQuery" placeholder="Keyword Search" size="small"/>
                                <Button class="ml-2" icon="pi pi-filter" severity="success" size="small" @click="globalFilter" v-tooltip.top="'Filter'"/>
                                <Button class="ml-2" icon="pi pi-filter-slash" size="small" outlined severity="success" @click="clearFilter" v-tooltip.top="'Clear Filter'"/>
                            </span>
                            <!-- <Button icon="pi pi-refresh" rounded raised /> -->
                        </div>
                    </template>
                <Column expander style="width: 5rem" />
                <Column field="tender_name" header="Tender Name."></Column>
                <Column field="tender_date" header="Tender Date."></Column>
                <Column field="department.name" header="Department"></Column>
                <Column field="contract_no" header="ROC No."></Column>
                <Column field="contract_date" header="ROC Date"></Column>
                <Column field="period" header="Validity (Years)"></Column>
                <Column header="Status">
                    <template #body="slotProps">
                        <Tag :value="getStatus(slotProps.data)" :severity="getSeverity(slotProps.data)" />
                    </template>
                </Column>
                <template #expansion="slotProps">
                    <div class="p-3">
                        <div class="flex flex-wrap align-items-center justify-content-between">
                            <span class="text-base text-600 font-bold px-4 "><h3>{{slotProps.data.tender_name}}: Tender items </h3></span>
                            <span class="px-4 "><Button class="p-button-sm" size="small" label="Add Item" raised @click="showDialog(slotProps.data)" first/></span>
                        </div>
                        <DataTable v-model:filters="filters" :value="slotProps.data.roc_details" :loading="loading">
                            <template #header>
                                <div class="flex justify-content-end">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search" />
                                        <InputText v-model="filters['global'].value" placeholder="Keyword Search" />
                                    </span>
                                </div>
                            </template>
                            <Column header="Sl No.">
                                <template #body="slotProps">
                                    {{ ++slotProps.index }}
                                </template>
                            </Column>
                            <Column field="item_name" header="Item"></Column>
                            <Column field="item_rate" header="Rate">
                                <template #body="slotProps">
                                    {{ formatCurrency(slotProps.data.item_rate) }}
                                </template>
                            </Column>
                            <Column field="category.label.name" header="Category"></Column>
                            <Column field="packing" header="Packing Unit"></Column>
                            <Column field="manufacturer.name" header="Manufacturer"></Column>
                            <Column header="Action">
                                <template #body="slotProps">
                                    <Button size="small" class="py-2" label="Update" raised @click="showItemDialog(slotProps.data)"/>
                                </template>
                            </Column>
                            <Column header="CMC">
                                <template #body="slotProps">
                                    <span v-if="slotProps.data.category.label.name =='Equipment' || slotProps.data.category.label.name =='EQUIPMENT'">
                                        <Button size="small" class="py-2 px-3 mr-1" icon="pi pi-plus" raised @click="showCMCDialog(slotProps.data)"/>
                                        <Button size="small" class="py-2" icon="pi pi-window-maximize" severity="success" raised @click="showCMC(slotProps.data)"/>

                                    </span> 

                                </template>
                            </Column>
                            
                        </DataTable>

                        <!-- dialog for adding cmc -->
                                        <Dialog :visible="cmcDialogVisibility" modal :closable="false" :header="cmcItem" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                                            
                                            <div class="formgrid grid mt-2">
                                                    <div class="field col-4">
                                                        <label for="itemName">Year</label>
                                                        <InputNumber v-model="cmcYear" class=" w-full"/>
                                                    </div>
                                                    <div class="field col-4">
                                                        <label for="itemRate">Rate</label>
                                                        <InputNumber v-model="cmcRate" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full" :minFractionDigits="2" :maxFractionDigits="2"/>
                                                    </div>
                                                    <div class="field col-4">
                                                        <label for="itemPacking">GST(%)</label>
                                                        <InputNumber v-model="cmcGst" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full" :minFractionDigits="2" :maxFractionDigits="2"/>
                                                    </div>
                                            </div>
                                            <template #footer>
                                                <div class="flex flex-row-reverse flex-wrap">
                                                    <div class="flex align-items-center justify-content-center ">
                                                        <Button class="flex align-items-center justify-content-center " size="small" label="Close" severity="danger" raised @click="hideCMCDialog"></Button>
                                                    </div>
                                                    <div class="flex align-items-center justify-content-center">
                                                        <Button class="flex align-items-center justify-content-center " size="small" label="Save" severity="success" icon="pi pi-save" raised @click="handleSaveCMC(rocItemData)"/>
                                                    </div>
                                                </div>
                                            </template> 
                                        </Dialog>
                                        <!-- cmc dialog end -->

                                        <!-- show cmc dialog start -->
                                        <Dialog :visible="itemCmcDialogVisibility" modal :closable="false" :header="cmcItem" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
                                            <DataTable :value="cmcArr">
                                                <Column field="year" header="Year"></Column>
                                                <Column field="rate" header="Rate"></Column>
                                                <Column field="gst" header="GST(%)"></Column>
                                            </DataTable>

                                            <template #footer>
                                                <div class="flex flex-row-reverse flex-wrap">
                                                    <div class="flex align-items-center justify-content-center ">
                                                        <Button class="flex align-items-center justify-content-center " size="small" label="Close" severity="danger" raised @click="hideItemCmc"></Button>
                                                    </div>
                                                </div>
                                            </template> 
                                        </Dialog>
                                        <!-- show cmc dialog end -->
                    </div>
                </template>
                <template #footer> In total there are {{ rocs ? rocs.length : 0 }} ROCs. </template>
    <Dialog :visible="dialogVisibility" modal showHeader="false" :closable="false" :style="{ width: '50rem' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
        <!-- <template #header> -->
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex align-items-center justify-content-center"><h3>Item Details</h3></div>
                    <div class="flex align-items-center justify-content-center"><ProgressSpinner v-if="loadingStatus" style="width: 50px; height: 50px" strokeWidth="5"/></div>
                </div>
        <!-- </template> -->
        <div class="formgrid grid mt-2">
            <div class="field col">
                <label for="itemName">Name</label>
                <InputText v-model="itemName" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
            <div class="field col">
                <label for="itemRate">Rate</label>
                <InputNumber v-model="itemRate" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full" :minFractionDigits="2" :maxFractionDigits="3"/>
            </div>
            <div class="field col">
                <label for="itemPacking">Packing</label>
                <InputText v-model="itemPacking" class="surface-overlay border-round appearance-none outline-none focus:border-primary w-full"/>
            </div>
    </div>
    <!-- v-if="!itemUpdateStatus" -->
    <div class="formgrid grid mt-2" >
             <div class="field col">
                <label for="itemcategory">Category</label>
                <TreeSelect v-model="itemcategory" :options="categories" placeholder="Select Item" class="w-full" />
             </div>
            <div class="field col">
                <label for="itemManufacturer">Manufacturer</label>
                <Dropdown size="small" class="w-full" v-model="itemManufacturer" editable filter :options="manufacturers" optionValue="id" optionLabel="name" placeholder="Select Manufacturer"/>
            </div>
    </div>

    <template #footer>
        <div class="flex flex-row-reverse flex-wrap">
            <div class="flex align-items-center justify-content-center ">
                <Button class="flex align-items-center justify-content-center " size="small" label="Close" severity="danger" raised @click="hideDialog"></Button>
            </div>
            <div v-if="!itemUpdateStatus" class="flex align-items-center justify-content-center">
                <Button class="flex align-items-center justify-content-center " size="small" label="Save" severity="success" icon="pi pi-save" raised @click="handleSave"/>
            </div>
            <div v-if="itemUpdateStatus" class="flex align-items-center justify-content-center">
                <Button class="flex align-items-center justify-content-center " size="small" label="Update" severity="success" icon="pi pi-save" raised @click="updateItem"/>
            </div>
        </div>
    </template> 
    </Dialog>
            </DataTable>
            </template>
    </Card>
        
        </div>
    </div>

    <!-- <div class="card flex justify-content-center"> -->


    <!-- </div> -->

<!-- </div> -->

</template>


<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Card from 'primevue/card';
import { getRocList, getCategories, getManufacturers, rocNewItem, updateRocItem, addItemCMC, fetchCMC } from '../../services/apiService'
import Tag from 'primevue/tag';
import Navbar from '../header/headerNavbar.vue'
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dialog from 'primevue/dialog';
import { FilterMatchMode } from 'primevue/api'
// import CascadeSelect from 'primevue/cascadeselect';
import TreeSelect from 'primevue/treeselect';
import ProgressSpinner from 'primevue/progressspinner';
import SideBar from '../sidebar/sideBarTwo.vue'

export default {
    components:{
        Navbar, 
        SideBar,
        DataTable, 
        Column, 
        Card, 
        Tag, 
        Button ,
        Dropdown,
        InputText,
        InputNumber,
        Dialog,
        // CascadeSelect,
        TreeSelect,
       ProgressSpinner
    },
    data() {
        return {
            loading:false,
            rocs: [],
            filteredRoc:[],
            filterQuery:null,
            slNo:0,
            expandedRows: [],
            dialogVisibility:false,
            categories:[],
            manufacturers:[],
            rocId:null,
            itemcategory:null,
            itemManufacturer:null,
            itemName:null,
            itemRate:null,
            itemPacking:null,
            itemUpdateStatus:false,
            itemId:null,
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                item_name: { value: null, matchMode: FilterMatchMode.IN },
                "manufacturer.name":{ value: null, matchMode: FilterMatchMode.IN },
            },
            parentFilter: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                tender_name: { value: null, matchMode: FilterMatchMode.IN },
                "department.name":{ value: null, matchMode: FilterMatchMode.IN },
                contract_no:{ value: null, matchMode: FilterMatchMode.IN },
            },
            cmcDialogVisibility:false,
            cmcItem:null,
            cmcYear:0,
            cmcRate:0,
            cmcGst:0,
            itemCmcDialogVisibility:false,
            cmcArr:[],
            selectedCascadeItemCategory:null,
            keyId:null,
            loadingStatus:false,
            rocItemData:null,
        };
    },

    methods:{
        getRocs(){
            this.loadingStatus = true;
            this.loading = true;
            getRocList([]).then((res)=> 
            {
                this.rocs = res.data
                this.filteredRoc = res.data;
                this.loading = false;
                this.loadingStatus = false;

                
            }
            ).catch((error)=>this.showErrorToast(error.response.data));
        },
        getCategory(){
            getCategories().then((res)=> 
                {
                    this.categories = res.data
                }
                ).catch((error)=>this.showErrorToast(error.response.data));
        },
        getManufacturer(){
            getManufacturers().then((res)=> 
                {
                    this.manufacturers = res.data;
                }
                ).catch((error)=>this.showErrorToast(error.response.data));
        },
        getStatus(product){
            switch (product.status) {
                case 1:
                    return 'Active'
                case -1:
                    return 'Expired';
                default:
                    break;
            }
        },
        getSeverity(product){
            switch (product.status) {
                case 1:
                    return 'success'
                case -1:
                    return 'danger';
                default:
                    break;
            }
        },
        formatCurrency(value) {
            return value.toLocaleString('en-US', { style: 'currency', currency: 'INR' });
        },
        showDialog(data){
            this.getCategory();
            this.getManufacturer();
            this.dialogVisibility = true;
            this.rocId = data.id;
        },
        hideDialog(){
            this.dialogVisibility = false;
            this.itemUpdateStatus = false;
            this.clearData();
        },
        showItemDialog(data){

            if(data.manufacturer){
                this.itemManufacturer = data.manufacturer.id;
            }
        
            this.itemUpdateStatus = true;
            this.itemId = data.id;
            this.itemName = data.item_name;
            this.itemRate = data.item_rate;
            this.itemPacking = data.packing;
            this.getManufacturer();
            this.getCategory();
            this.itemcategory = {[data.category.key]:true};
            this.dialogVisibility = true;      
        },
        showCMCDialog(data){
            this.cmcItem = data.item_name+': CMC Charges';
            this.rocItemData = data;
            this.cmcDialogVisibility = true;
        },
        showCMC(data){
            console.log('cmc ');
            console.log(data);
            
            

            fetchCMC(data.id).then((res)=>{
                this.cmcArr = res.data;
                this.cmcItem = data.item_name+':CMC Charges';
                this.itemCmcDialogVisibility = true;
            }).catch((error)=>this.showErrorToast(error.response.data));
        },
        hideItemCmc(){
            this.itemCmcDialogVisibility = false;
        },
        hideCMCDialog(){
            this.cmcDialogVisibility = false;
        },
        handleSave(){

            if(this.rocId==null || this.itemName==null || this.itemRate==null || this.itemPacking==null || this.itemcategory==null){
                return this.showErrorToast({'message':'All fields are required!'});
            }
            const data ={
                'rocId':this.rocId,
                'name':this.itemName,
                'rate':this.itemRate,
                'packing':this.itemPacking,
                'category':this.itemcategory,
                'manufacturer':this.itemManufacturer
            }

            this.loadingStatus = true;
            rocNewItem(data).then((res)=>{
                this.showSuccessToast(res.data);
                this.hideDialog();
                this.getRocs();
                this.loadingStatus = false;
                this.rocId = null
                this.itemName = null
                this.itemRate = null
                this.itemPacking = null
                this.itemcategory = null
                this.itemManufacturer = null
            }).catch((error)=>this.showErrorToast(error.response.data));
        },
        handleSaveCMC(data){         
            if(this.cmcYear==0 || this.cmcYear=='' || this.cmcRate==0 || this.cmcRate=='' || this.cmcGst==0 || this.cmcGst==''){
                return this.showErrorToast({'message':'None of the fields can be ZERO(0)'});
            }
            const cmcData = {
                'itemId':data.id,
                'year':this.cmcYear,
                'rate':this.cmcRate,
                'gst':this.cmcGst,
            };

            addItemCMC(cmcData).then((res)=>{
                this.showSuccessToast(res.data);
                this.hideCMCDialog();
                this.cmcYear = 0;
                this.cmcRate = 0;
                this.cmcGst = 0;
            }).catch((error)=>this.showErrorToast(error.response.data));
        },
        updateItem(){

            if(this.itemRate==0){
                return this.showErrorToast({'message':'Rate cannot be ZERO (0)'});
            }
            const data ={
                'itemId':this.itemId,
                'name':this.itemName,
                'rate':this.itemRate,
                'packing':this.itemPacking,
                'category':this.itemcategory,
                'manufacturer':this.itemManufacturer,
            }

            this.loadingStatus = true;
            updateRocItem(data).then((res)=>{  
                this.showSuccessToast(res.data);
                this.hideDialog();
                this.clearData();
                this.getRocs();
                this.itemUpdateStatus = false;
                this.loadingStatus = false;
            }).catch((error)=>this.showErrorToast(error.response.data));

        },
        clearData(){
            this.itemId = null;
            this.itemName = null;
            this.itemRate = null;
            this.itemPacking = null;
            this.itemcategory = null;
            this.itemManufacturer = null;
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 3000 });
        },
        showSuccessToast(data){
            this.$toast.add({ severity: 'success', summary: 'Success', detail:data.message, life: 4000 });
        },
        globalFilter(){
            this.filteredRoc = [];
            if(this.filterQuery!=null){

                this.rocs.forEach(e => {
                    if (e.tender_name.includes(this.filterQuery) || e.contract_no.includes(this.filterQuery)) { 
                        this.filteredRoc.push(e); 
                    }
                    else{
                        e.roc_details.every(el => {
                            if(el.item_name.includes(this.filterQuery)){
                                this.filteredRoc.push(e);
                                return false;
                            }
                            return true;
                        });
                    }
                });
            }
        },
        clearFilter(){
            this.filteredRoc = this.rocs;
            this.filterQuery = null;
        } 
    },
    mounted() {
        this.getRocs()
        
    }
};
</script>

