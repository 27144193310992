import { defineStore } from "pinia";

export const useOemPoStore = defineStore('oemPo', {
    // state:()=>({
    //     data: null,
    // }),
    state: ()=>{
        return{
            data:null,
        }
    }
    // actions:{
    //     reset(){
    //         window.localStorage.removeItem("oemPo");
    //     },
    //     setLocalStorage(data){
    //         window.localStorage.setItem("oemPo", JSON.stringify(data));
    //     }
    // }
})