<template>
  
  <tr class="yaxis-padding" v-for="(location, index) in locations" :key="location" :index="index">

                <td v-if="index==0" class="item-center" :rowspan="locationCount">{{item}}</td>
                <!-- <td v-if="index==0" class="item-center" :rowspan="locationCount"></td> -->
                <td v-if="index==0" class="item-center">{{location.location}}</td>
                <td v-if="index==0" class="item-center">{{location.quantity}}</td>  

                <!-- for showing td after merged rows -->
                <td v-if="index>0" class="item-center">{{location.location}}</td>
                <td v-if="index>0" class="item-center">{{location.quantity}}</td>  

  </tr>
</template>

<script>
export default {
    props:{ deliveryLocation:Array },

    data(){
        return{
            item:this.deliveryLocation.item_name,
            locationCount:this.deliveryLocation.location.length,
            locations:this.deliveryLocation.location,
        }
    },

}
</script>

<style scoped>

th, td {
  border: 0.5px solid #000000;
  border-collapse: collapse;
}

.yaxis-padding{
  height: 1.8em;
  vertical-align: middle;
}

.item-center {
    text-align: center;
}
</style>