<template>

    <LocationList :itemDetails="itemDetails" v-for="(location, index) in locations" :dbLocations="dbLocations" :quantity="location.quantity" :key="index" :index="index" @delete="locations.splice(index, 1)" @bind-item="bindData"/> 

    <Button class="ml-1 my-2 mx-1" text raised style="width:1.5em; height:1em" icon="pi pi-plus" @click="addMore(itemDetails)"/>
</template>

<script>
import Button from 'primevue/button'
import LocationList from './locationList.vue'

export default {

    props:{    
            itemDetails:Object,
            dbLocations:Array,
        },

    emits:['bind-location'],
    components:{            
                Button,
                LocationList
            },
    data(){
        return{
            locations:[],
        }
    },
    methods:{
        addMore(data){
        
            let pendingQty = 0;
            
            (this.locations.length>0) ? this.locations.forEach(element => { pendingQty += element.quantity }): pendingQty;
        
            this.locations.push({
                itemId:data.id,
                quantity:(data.quantity - pendingQty),
            })
        },
        bindData(index, itemData){
            
                this.locations[index].quantity = itemData.locationQuantity;
                this.locations[index].location = itemData.deliveryLocation;
                this.$emit('bind-location', this.locations);
            },
        
    },
    mounted(){
        
        this.addMore(this.itemDetails);    
    }
}
</script>

<style>

</style>