<template>
  
    <div class="sm:col-12 md:col-12 lg:col-12">
        <div class="surface-card shadow-2 p-3 border-round-lg mt-2 ml-2">
            <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl ml-2">
                    Filter Options
                </div>
                
            </div>
            <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center w-full">
                  <div class="col">
                    <label class="mt-2">PI status:</label>
                    <Dropdown class="w-full mt-2" v-model="piStatus" placeholder="Select an option" :options="itemPiStatusOptions" optionLabel="label" optionValue="id" showClear/>
                  </div>
                  <div class="col">
                    <div v-if="piStatus==1">
                        <label >Payment status:</label>
                        <Dropdown class="w-full mt-2" v-model="paymentStatus" placeholder="Select an option" :options="itemPaymentStatusOptions" optionLabel="label" optionValue="id" showClear/>
                    </div>
                  </div>
                  <div class="col">
                    <div v-if="piStatus==1">
                        <label >Item status:</label>
                        <Dropdown class="w-full mt-2" v-model="itemStatus" placeholder="Select an option" :options="itemStatusOptions" optionLabel="label" optionValue="id" showClear/>
                    </div>
                  </div>
                  <div class="col">
                    <div v-if="itemStatus!=0 && piStatus==1">
                        <label >Materials Receive status:</label>
                        <Dropdown class="w-full mt-2" v-model="arrivalStatus" placeholder="Select an option" :options="arrivalStatusOptions" optionLabel="label" optionValue="id" showClear/>
                    </div>
                  </div>

                    <div class="col">
                        <label >Dept PO Date:</label>
                        <Calendar v-model="poDateRange" selectionMode="range" :manualInput="true" dateFormat="dd/mm/yy" placeholder="Start date - End date" class="w-full mr-2 mt-2"></Calendar>
                    </div>

                   <div class="col">
                    <div>
                        <label >Category:</label>
                        <Dropdown class="w-full mt-2" v-model="selectedCategory" placeholder="Select an option" :options="categories" optionLabel="label" optionValue="label" showClear/>
                    </div>
                  </div>
                  <div class="col">

                    <div class="flex">
                        <div class="flex-initial flex align-items-center justify-content-center w-full">
                            <Button label="Filter" size="small" raised text severity="success" class="w-full mt-4" @click="filterItems"/>
                        </div>
                        <div class="flex-initial flex align-items-center justify-content-center w-full">
                            <Button label="Clear Filter" raised severity="warning" class="w-full mt-4 ml-2" size="small" @click="clearFilter"/>
                        </div>
                    </div>                    
                  </div>
                  
                </div>
            </div>
        </div>
    </div>

    <div class="sm:col-12 md:col-12 lg:col-12">
        <div class="surface-card shadow-2 p-3 border-round-lg mt-2 ml-2">
            <div class="flex justify-content-between flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl ml-2">
                    Ordered Items Report
                </div>
                <div class="flex align-items-center justify-content-center text-xl mr-2 mb-2">

                    <InputText v-model="filters['global'].value" placeholder="Keyword Search" class="mr-2"/>

                    <Dropdown size="small" class="w-full mr-2" v-model="selectedManufacturer" :options="manufacturers" optionLabel="name" optionValue="id" :disabled="piStatus==null ? true : false" placeholder="Select Manufacturer" showClear @change="filterItems"/>

                    <Button label="Download report" raised class="w-full" size="small" style="background-color:#B46076; border-color:#B46076" @click="exportCSV" v-tooltip.top="'Excel format'"/>
                </div>
            </div>

             <div class="flex flex-wrap">
                <div class="flex align-items-center justify-content-center w-full">
                    <div class="col">
                        <DataTable ref="oemItems" :value="filteredItems" stripedRows size="small" showGridlines :loading="tableLoading" v-model:filters="filters" tableStyle="min-width: 50rem; max-width:100%" dataKey="id" paginator :rows="100" :rowsPerPageOptions="[10, 20, 50, 100, 200]" paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}" scrollable scrollHeight="400px">

                            <template #empty>
                                <div class="flex align-items-center justify-content-center my-2">
                                    <label class="text-xl font-medium">
                                        No data
                                    </label>
                                </div>
                            </template>
                            <Column header="Sl No.">
                                <template #body="slotProps">{{1 + slotProps.index}}</template>
                            </Column>
                            <Column field="item_name" header="Item" style="width:20%"></Column>
                            <Column field="category" header="Category"></Column>
                            <Column field="deptPoNo" header="Dept-PO"></Column>
                            <Column field="deptPoDate" header="Dept-PO Date" style="min-width:7rem;"></Column>
                            <Column field="oem_po.name" header="Manufacturer"></Column>
                            <Column field="quantity" header="Quantity">
                                <template #body="slotProps">
                                    {{formatNumber(slotProps.data.quantity)}}
                                </template>
                            </Column>
                            <Column field="nop" header="NOP(s)">
                                <template #body="slotProps">
                                    {{formatNumber(slotProps.data.nop)}}
                                </template>
                            </Column>
                            <Column field="rate" header="Rate (Ex-GST)">                                
                                <template #body="slotProps">
                                    {{formatCurrency(slotProps.data.rate)}}
                                </template>
                            </Column>
                            <Column field="gst" header="GST(%)"></Column>
                            <Column field="sub_total" header="Sub-Total (X-GST)">
                                <template #body="slotProps">
                                    {{formatCurrency(slotProps.data.sub_total)}}
                                </template>
                            </Column> 
                              
                            <Column field="deptRate" header="Dept Rate (In-GST)">
                                <template #body="slotProps">
                                    {{formatCurrency(slotProps.data.deptRate)}}
                                </template>
                            </Column>   
                            <Column field="deptSubTotalXgst" header="Dept Sb-Total (X-GST)">
                                <template #body="slotProps">
                                    {{formatCurrency(slotProps.data.deptSubTotalXgst)}}
                                </template>
                            </Column>                        
                            <Column field="pi_status" header="PI Status" :exportable="false">
                                <template #body="slotProps">
                                    <Tag :value="getPiValue(slotProps.data.pi_status)" :severity="getPiSeverity(slotProps.data.pi_status)" ></Tag>
                                </template>
                            </Column>
                            <Column field="payment_status" header="Payment" >
                                <template #body="slotProps">
                                    <Tag :value="getPaymentValue(slotProps.data.payment_status)" :severity="getPaymentSeverity(slotProps.data.payment_status)" ></Tag>
                                </template>
                            </Column>
                            <Column header="Item Status">
                                <template #body="slotProps">
                                    <Button v-if="slotProps.data.status.length > 0" class="px-3 py-1" label="Status" icon="pi pi-window-maximize" size="small" severity="success" @click="toggle($event, slotProps.data.status)" />

                                    <Tag v-if="slotProps.data.status.length==0" value="Not Dispatched" severity="danger" ></Tag>

                                </template>
                            </Column>
                            <Column header="Material Received">
                                <template #body="slotProps">
                                    <Tag :value="getRecdValue(slotProps.data.arrival_status)" :severity="getRecdSeverity(slotProps.data.arrival_status)" ></Tag>
                                </template>
                            </Column>
                        </DataTable>

                        <OverlayPanel ref="opPrimary">
                            <DataTable :value="opData" >
                                <Column v-for=" (col, index) in opTableColumns" :key="index" :field="col.field" :header="col.header">
                                </Column>
                                <template #footer> Total quantity {{ formatNumber(getTotalQty()) }} </template>
                            </DataTable>
                        </OverlayPanel>

                    </div>
                </div>
             </div>
        </div>
    </div>

</template>

<script>
import Button from 'primevue/button'
import Dropdown from 'primevue/dropdown'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { getCategories, getManufacturers, oemOrderedItemsList } from '@/services/apiService'
import Tag from 'primevue/tag'
import OverlayPanel from 'primevue/overlaypanel'
import Calendar from 'primevue/calendar'
import { FilterMatchMode } from 'primevue/api';
import InputText from 'primevue/inputtext'

export default {
    components:{
        Button,
        Dropdown,
        DataTable,
        Column,
        Tag,
        OverlayPanel,
        Calendar,
        InputText,
    },
    data(){
        return{
            piStatus:null,
            tableLoading:false,
            itemPiStatusOptions:[
                {label:'Pending', id:0},
                {label:'Received', id:1},
            ],
            paymentStatus:null,
            itemPaymentStatusOptions:[
                {label:'Not Paid', id:0},
                {label:'Paid', id:1},
            ],
            itemStatus:null,
            itemStatusOptions:[
                {label:'Not Dispatched', id:0},
                {label:'Dispatched', id:1},
                {label:'In-Transit', id:2},
            ],
            arrivalStatus:null,
            arrivalStatusOptions:[
                {label:'Not Received', id:0},
                {label:'Received', id:1},
            ],
            deptItemsArr:[],
            itemsArr:[],
            filteredItems:[],
            opData:[],
            opTableColumns:[],
            manufacturers:[],
            selectedManufacturer:null,
            selectedCategory:null,
            categories:[],
            poDateRange:[],
            filters: {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
                item_name: { value: null, matchMode: FilterMatchMode.IN },
            },
        }
    },
    methods:{
        getItemsList(){
            this.tableLoading = true;
            oemOrderedItemsList().then((res)=>{
                this.itemsArr = res.data;
                this.filteredItems = res.data;                
                this.calculateTotalValue(this.filteredItems);
                this.tableLoading = false;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
            })
        },
        calculateTotalValue(items){
            let sumExGst = 0;
            // let sumGst = 0;
            let saleSumXgst = 0;
            let saleInclGst = 0;
            let costInclGst = 0;

            items.forEach(e => {
                // sumExGst += e.rate * e.quantity;
                // sumGst += (((e.rate * e.quantity)/100) * e.gst);
                sumExGst += e.sub_total;
                // sumGst += ((e.sub_total/100) * e.gst);     
                saleSumXgst += e.deptSubTotalXgst;
                
                saleInclGst += e.deptSubTotalXgst + ((e.deptSubTotalXgst/100)*e.gst);
                costInclGst += e.sub_total + ((e.sub_total/100)*e.gst);
            });              
            
            this.$emit('total-value', sumExGst, saleSumXgst, saleInclGst, costInclGst);            
        },
        getPiValue(option){
            switch (option) {
                case '0':
                    return 'Pending'
                case '1':
                    return 'Received';
                default:
                    break;
            }
        },
        getPiSeverity(option){
            switch (option) {
                case '0':
                    return 'danger'
                case '1':
                    return 'success';
                default:
                    break;
            }
        },
        getPaymentValue(option){
            switch (option) {
                case null:
                    return 'Not Paid'
                case 'Full':
                    return 'Fully Paid';
                case 'Partial':
                    return 'Partial Paid';
                default:
                    break;
            }
        },
        getPaymentSeverity(option){
            switch (option) {
                case null:
                    return 'danger'
                case 'Full':
                    return 'success';
                case 'Partial':
                    return 'warning';
                default:
                    break;
            }
        },
        getRecdValue(option){
            switch (option) {
                case '0':
                    return 'Not Received'
                case '1':
                    return 'Received Full';
                case '-1':
                    return 'Received Partially';
                default:
                    break;
            }
        },
        getRecdSeverity(option){
            switch (option) {
                case '0':
                    return 'danger'
                case '1':
                    return 'success';
                case '-1':
                    return 'warning';
                default:
                    break;
            }
        },
        filterItems(){
            
            var startDate = null;
            var endDate = null;

            if(this.poDateRange[0]!=null && this.poDateRange[1]!=null){
                startDate = this.poDateRange[0];
                endDate = this.poDateRange[1];
            }

            if(this.piStatus==null && this.paymentStatus==null && this.itemStatus==null && this.arrivalStatus==null && (startDate==null || endDate==null)){
                return this.filteredItems=this.itemsArr;
            }          
            
            this.tableLoading = true;
            let tmp = [];

            this.itemsArr.forEach(e => {

                var deptDate2 = e.deptPoDate.split("-");
                
                var deptPoDate = new Date(deptDate2[2], (deptDate2[1]-1), deptDate2[0]);

                if(  ( this.piStatus!=null ? ((this.piStatus==0 && e.pi_status==0) || (this.piStatus==1 && e.pi_status==1)) : true) && ( this.paymentStatus!=null ?  ( this.paymentStatus==0 && e.payment_status==null ) || (this.paymentStatus==1 && e.payment_status!=null) : true ) && ( this.arrivalStatus!=null ?  (this.arrivalStatus==0 && e.arrival_status==0) || (this.arrivalStatus==1 && e.arrival_status!=0) : true) && (this.itemStatus!=null ? this.filterItemStatus(e.status) : true) && (this.selectedCategory!=null ? (e.category==this.selectedCategory) : true) && (this.selectedManufacturer!=null ? this.selectedManufacturer==e.oem_po.uuid : true) && ((startDate!=null && endDate!=null) ? (deptPoDate >= startDate && deptPoDate <= endDate) : true) ){                   
                    tmp.push(e);
                }
            });

            this.filteredItems = [];
            this.filteredItems = tmp;
            this.calculateTotalValue(this.filteredItems);
            this.tableLoading = false;
        },
        filterItemStatus(e){            
            if(this.itemStatus==0 && e.length==0){
                return true;
            }

            let flag = 0;

            e.forEach(el => {
                if(this.itemStatus==1 && el.status==1){
                    flag = 1;
                }
                if(this.itemStatus==2 && el.status==2){
                    flag = 1;
                }
            });

            if(flag==1)
                return true;

            return false;
        },
        toggle(event, data) {
            this.opData = data;        
                this.opTableColumns = [
                    {header:'Status', field:'statusLabel'},
                    {header:'Quantity', field:'quantity'},
                    {header:'Date', field:'date'},
                ];            
            this.$refs.opPrimary.toggle(event);
        },
        clearFilter(){
            this.tableLoading=true;
            this.piStatus=null;
            this.paymentStatus=null;
            this.itemStatus=null;
            this.arrivalStatus=null;
            this.selectedCategory=null;
            this.selectedManufacturer=null;
            this.poDateRange = [];
            this.filteredItems=this.itemsArr;
            this.calculateTotalValue(this.filteredItems);
            this.tableLoading=false;
        },
        getTotalQty(){
            return this.opData.reduce((accumulator, current) => accumulator + current.quantity, 0);
        },
        exportCSV() {
            this.$refs.oemItems.exportCSV();
        },
        fetchManufacturers(){
            getManufacturers().then((res)=>{
                this.manufacturers = res.data;
            }).catch((error)=>{
                this.showErrorToast(error.response.data);
            });
        },
        fetchCategories(){
            getCategories().then((res)=>{
                this.categories = res.data;            
            }).catch((error)=>{
                this.showErrorToast(error.response.data)
            });
        },
        formatNumber(value) {
            return value.toLocaleString('en-IN');
        },
        formatCurrency(value) {
            return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR', maximumFractionDigits:2 });
        },
        showErrorToast(error){
            this.$toast.add({ severity: 'error', summary: 'Error', detail:error.message, life: 4000 });
        },
    },
    mounted(){
        this.getItemsList();
        this.fetchManufacturers();
        this.fetchCategories();
    }
}
</script>

<style>


</style>