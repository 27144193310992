<template>
<Navbar />
<SideBar/> 

<div class=" main-content"> 

  <div class="grid nested-grid">
    <div class="col-9">
        <div class="grid">
            <div class="col-12">
                <div class="flex justify-content-start flex-wrap">
                  <div class="flex align-items-center justify-content-center text-xl mt-3 ml-3">
                      Reports
                  </div>
              </div>
            </div>
            <div class="col-12">
                <div class="flex surface-card shadow-2 border-round-lg  mt-2 ml-3">
                  <div class="flex align-items-center justify-content-center text-lg">
                      <div 
                      v-for="report in reports" 
                      :key="report" 
                      class="tab" 
                      :class="{ active: currentTab === report.value }"
                      @click="selectTab(report)"
                      >
                      {{ report.label }}
                      </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-3">
      <div class="grid">
        <div class="col-6">
          <div class="surface-card shadow-2 border-round-lg  mt-3 mx-2 h-8rem">
            <div class="flex justify-content-between">
                <div class="flex font-medium text-lg ml-3 mt-3">                                        
                  <label class=" text-500">{{firstCardTitle}}</label>         
                </div>
                <div class="flex mr-3 mt-3 ">
                  <i class="pi pi-money-bill" style="color: #02CE38; font-size: 2rem"></i>    
                </div>
            </div>
            <div class="flex">
              <label class="ml-3 mt-1 text-xl">{{ firstValue==0 ? 'NA' : formatCurrency(firstValue)}}</label>
            </div>
            <div class="flex">
              <label class="ml-3 mt-1 text-sm">{{ firstCardSubTitle }} </label>
            </div>
            <div class="flex">
              <label class="ml-3 my-1 text-base font-medium">{{ firstCardSubValue==0 ? 'NA' :  formatCurrency(firstCardSubValue)}} </label>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="surface-card shadow-2 border-round-lg  mt-3 mx-2 h-8rem ">

            <div class="flex justify-content-between">
                <div class="flex font-medium text-lg ml-3 mt-3">                                        
                  <label class=" text-500">{{secondCardTitle}}</label>         
                </div>
                <div class="flex mr-3 mt-3 ">
                  <i class="pi pi-money-bill" style="color: #02CE38; font-size: 2rem"></i>    
                </div>
            </div>
            <div class="flex">
              <label class="ml-3 mt-1 text-xl">{{formatCurrency(totalValue)}}</label>
            </div>

            <div class="flex">
              <label class="ml-3 mt-1 text-sm">{{ secondCardSubTitle }} </label>
            </div>
            <div class="flex">
              <label class="ml-3 my-1 text-base font-medium">{{ secondCardSubValue==0 ? 'NA' :  formatCurrency(secondCardSubValue)}} </label>
            </div>

          </div>
        </div>

      </div>
    </div>
  </div>

     <!-- <div class="sm:col-12 md:col-12 lg:col-12">
      <div class="flex justify-content-start flex-wrap">
          <div class="flex align-items-center justify-content-center text-xl mt-2 ml-3">
              Reports
          </div>
      </div>
     </div> -->


    <!-- <div class="sm:col-12 md:col-12 lg:col-12">
        <div class="flex surface-card shadow-2 border-round-lg  mt-1 ml-3">
            <div class="flex align-items-center justify-content-center">
                <div 
                v-for="report in reports" 
                :key="report" 
                class="tab" 
                :class="{ active: currentTab === report.value }"
                @click="selectTab(report)"
                >
                {{ report.label }}
                </div>
            </div>
        </div>
    </div> -->

  <div v-if="!beforeMountState" class="mt-2 mx-2 w-full">
    <component :is="currentTabComponent" @total-value="getTotalValue"></component>
  </div>

  <div v-if="beforeMountState" class="mt-2 mx-2 w-full mx-2 ">
    <div class="sm:col-12 md:col-12 lg:col-12 ">
        <div class="surface-card shadow-2 p-3 border-round-lg mt-2 mx-2 h-5rem">
            <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl ml-2">
                    Filter Options <span class="text-400 ml-3"> (Please select a module)</span>
                </div>
                <div class="flex align-items-center justify-content-center w-full mt-2">
                  <!-- <Skeleton height="5rem" class="mb-2"></Skeleton> -->
                </div>
            </div>
        </div>
    </div>
     <div class="sm:col-12 md:col-12 lg:col-12">
        <div class="surface-card shadow-2 p-3 border-round-lg mt-2 mx-2 h-5rem">
            <div class="flex justify-content-start flex-wrap">
                <div class="flex align-items-center justify-content-center text-xl ml-2">
                    Module details <span class="text-400 ml-3"> (Please select a module)</span>
                </div>
                <div class="flex align-items-center justify-content-center w-full mt-2">
                  <!-- <Skeleton height="22rem" class="mb-2"></Skeleton> -->
                </div>
            </div>
        </div>
     </div>
  </div>

</div>
</template>

<script>
import InputText from 'primevue/inputtext';
import DeptOrderComponent from './deptOrdersComponent.vue'
import ItemsComponent from './itemsDetailedComponent.vue'
import EquipmentComponent from './equipmentsComponent.vue'
import DeliveryChallanComponent from './deliveyrChallanComponent.vue'
import BillComponent from './billReportComponent.vue'
import OemOrderedItemsComponent from './oemOrderedItemsComponent.vue'
import Navbar from '../header/headerNavbar.vue'  
import SideBar from '../sidebar/sideBarTwo.vue'
import Button from 'primevue/button';
// import Skeleton from 'primevue/skeleton';

export default {
  components:{
    Navbar,
    SideBar,
    InputText,
        Button,
        DeptOrderComponent,
        ItemsComponent,
        EquipmentComponent,
        DeliveryChallanComponent,
        BillComponent,
        OemOrderedItemsComponent,
      // Skeleton,
},
  data() {
    return {
      reports: [
        
        { label:'Department Order Items', value:1},
        { label:'Oem Ordered Items', value:3},
        // { label:'Department Orders', value:2},
        // { label:'Equipments', value:4},
        // { label:'Delivery Challans', value:5},
        // { label:'Bills Report', value:6},
      ],
      currentTab: null, 
      beforeMountState:true,
      totalValue:0.0,
      firstValue:0.0,
      firstCardTitle:'Value 1',
      firstCardSubTitle:'Value 1 Sub Title',
      firstCardSubValue:0.0,
      secondCardTitle:'Value 2',
      secondCardSubTitle:'Value 2 Sub Title',
      secondCardSubValue:0.0,
    };
  },
  computed: {
    currentTabComponent() {
      const components = {
        1:ItemsComponent,
        2:DeptOrderComponent,
        3:OemOrderedItemsComponent,
        4:EquipmentComponent,
        5:DeliveryChallanComponent,
        6:BillComponent,
      };
      return components[this.currentTab];
    }
  },
  methods: {
    selectTab(report) {
      this.beforeMountState = false;
      this.currentTab = report.value;
      console.log('cuurent tab');
      console.log(this.currentTab);
      
      
      if(this.currentTab==1){
        this.firstCardTitle="Cost"
        this.secondCardTitle = "Total Incl-GST"
      }
      if(this.currentTab==3){
        this.firstCardTitle="Sales Ex-GST"
        this.secondCardTitle = "Cost Ex-GST"
        this.firstCardSubTitle = "Sales (Incl-GST)"
        this.secondCardSubTitle = "Cost (Incl-GST)"
      }
    },
    getTotalValue(value2, value1, subValue1, subValue2){
      this.firstValue = value1;
      this.totalValue = value2;
      this.firstCardSubValue = subValue1;
      this.secondCardSubValue = subValue2;
    },
    formatCurrency(value) {
      return value.toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
    },
  },
  mounted(){
    // this.currentTab = this.reports[0].value;
  }
};
</script>

<style scoped>

.tab {
    border-radius: 5%;
  padding: 17px 20px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s;
}

.tab.active {
  background-color: #60A0B4;
  color: white;
}

</style>
