<template>
  <tfoot class="doc-width">
    <tr>
        <td >
            <div class="footer">

            </div>
        </td>
    </tr>
  </tfoot>
</template>

<script>
export default {

}
</script>

<style>
@media print{
    .footer {
        position: fixed;
        bottom: 0;
    }
}

</style>